import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  FormText,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import {
  FaRegListAlt,
  FaFileExport,
  FaMinusSquare,
} from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class Landoffer extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      data: [],
      dataHelp: [],
      dataHelpdet: [],
      landdata: [],
      date: new Date().toLocaleString("en-US"),
      datesale: "",
      gethelpid: "",
      gethelpOperateid: "",
      userid: "",
      landsaleid: "",
      landid: "",
      firstname: "",
      lastname: "",
      filename: "",
      attachfile: null,
      filesarr: [
        {
          filename: "",
          attachfile: null,
        },
      ],
      fileOtherid: "",
      fileOtherattach: "",
      fileOthername: "",
      fileOther: [
        {
          fileOtherid: "",
          fileOtherattach: "",
          fileOthername: "",
        },
      ],
      process_status: "",
      saveSuccess: false,
      saveFail: false,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const datesalenew = moment()
        .utcOffset("+05:30")
        .format("YYYY-MM-DD");

        this.setState({
          data: response.data,
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          datesale: datesalenew,
        });
      })
      .catch((error) => {
        console.log(error);
      });

      const getlandUrl =
      "../frontendapi/landall/" + this.state.userid;
    await axios
      .get(getlandUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({
            landdata: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const gethelpoperateUrl =
      "../frontendapi/gethelp_operate_by_id/" + this.props.match.params.id;
    await axios
      .get(gethelpoperateUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({
            gethelpid: response.data[0].gethelpid,
            gethelpOperateid: response.data[0].id,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const gethelpUrl = "../frontendapi/gethelp_by_id/" + this.state.gethelpid;
    await axios
      .get(gethelpUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({
            dataHelp: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getlandsaleUrl = "../frontendapi/getlandsale/" + this.state.gethelpid;
    await axios
    .get(getlandsaleUrl, this.state)
    .then((response) => {
        if (response.data) {

            //console.log("response.data" + JSON.stringify(response.data));

            const datersale = moment(
                new Date(moment.unix(response.data[0].date).utc().format())
                ).format("YYYY-MM-DD");

            this.setState({
                landsaleid : response.data[0].id,
                landid: response.data[0].landid,
                datesale: datersale,
                process_status: "edit",
            });

            //////////// get attactfile
                const getfileUrl =
                "../frontendapi/getfile/tbl_land_sale/" +
                this.state.landsaleid;
            axios
                .get(getfileUrl, this.state)
                .then((response) => {
               //console.log("response : " + JSON.stringify(response.data));
                if (response.data != "") {
                    for (let i = 0; i < response.data.length; i++) {
                    this.setState({
                        fileOther: [
                        ...this.state.fileOther,
                        {
                            fileOtherid: response.data[i].id,
                            fileOthername: response.data[i].filename,
                            fileOtherattach: response.data[i].attachfile,
                        },
                        ],
                    });
                    }
                }
                })
                .catch((error) => {
                console.log(error);
                });

        }
    })
    .catch((error) => {
        console.log(error);
    });

    
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const getURL = "../frontendapi/mgtlandsale/" + this.state.gethelpid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        console.log("response save : " + response.data);
        if (response.data != "" || response.data != "0") {

            const formData = new FormData();
            for (let i = 0; i < this.state.filesarr.length; i++) {
            formData.append(`filename[${i}]`, this.state.filesarr[i].filename);
            formData.append(`attachfile[${i}]`, this.state.filesarr[i].attachfile);
            }
            const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
            };
            const getuploadUrl =
            "../frontendapi/uploadmulti/tbl_land_sale/" + response.data;
            axios
            .post(getuploadUrl, formData, config)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });

            
            this.setState({ process_status: "edit", saveSuccess: true });
            this.showSuccess();

        
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          this.props.history.push("/landoffer/" + this.state.gethelpOperateid);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  //////////////// Add Files /////////

  handleChange = (idx) => (evt) => {
    const newFilesarr = this.state.filesarr.map((nameadd, sidx) => {
      if (idx !== sidx) return nameadd;
      return { ...nameadd, [evt.target.name]: evt.target.value };
    });

    this.setState({ filesarr: newFilesarr });
  };

  handleFileChange = (idx) => (evt) => {
    const newFilesarr = this.state.filesarr.map((fileadd, sidx) => {
      if (idx !== sidx) return fileadd;
      return { ...fileadd, [evt.target.name]: evt.target.files[0] };
    });

    this.setState({ filesarr: newFilesarr });
  };

  handleAddFile = () => {
    this.setState({
      filesarr: this.state.filesarr.concat([
        {
          filename: "",
          attachfile: null,
        },
      ]),
    });
  };

  handleRemoveFile = (idx) => () => {
    this.setState({
      filesarr: this.state.filesarr.filter((s, sidx) => idx !== sidx),
    });
  };

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  ///////// DeleteUploadFile

  handleDeletefile = (fileid) => {
    //e.preventDefault();

    const getURL = "../frontendapi/removefiles/" + fileid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response) {
          window.location.reload(false);
        } else {
          this.props.history.push("/gethelandofferlpedit/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitremove = (fileid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Alert color="warning">
              <h3>ยืนยันการลบข้อมูล</h3>
              <p>คุณต้องการลบไฟล์นี้หรือไม่?</p>
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  this.handleDeletefile(fileid);
                  onClose();
                }}
              >
                {" "}
                Yes
              </Button>{" "}
              <Button color="warning" size="sm" onClick={onClose}>
                No
              </Button>
            </Alert>
          </div>
        );
      },
    });
  };

  render() {
    const { firstname, lastname, landid, saveSuccess, datesale } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                {this.state.dataHelp.map((gethelp, i) => (
                  <OrderArea key={i}>
                    <Row>
                      <Col xs="6">
                        <h5>
                          <FaRegListAlt /> การเสนอขายที่ดิน คำขอความช่วยเหลือ
                          หมายเลข # {gethelp.gethelpid}
                        </h5>
                      </Col>
                      <Col xs="6" className="text-right">
                      {gethelp.countQuestionnaire==0?
                        <Button
                          onClick={() => this.onEvaluation(gethelp.gethelpid)}
                          color="primary"
                          style={{ marginBottom: "1rem" }}
                        >
                          แบบประเมินความพึงพอใจ
                        </Button>:<Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                        <MenuDetails getHelpID={gethelp.gethelpid} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup>
                          <ListGroupItem>
                            <h5>ข้อมูลการเสนอขายที่ดิน : </h5>
                            <hr />
                            <FormGroup row>
                            <Label
                              className="text-right"
                              for="datesale"
                              sm={3}
                            >
                              วันที่เสนอขาย
                            </Label>
                            <Col sm={3}>
                              <Input
                                type="date"
                                name="datesale"
                                value={datesale}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="landinfo" sm={3}>
                              เลือกข้อมูลที่ดิน
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                onChange={this.changeHandler}
                                name="landid"
                              >
                                <option value="">- เลือกที่ดิน -</option>
                                {this.state.landdata.map((land,i)=>(
                                <option key={i}
                                  value={land.landid}
                                  selected={landid == land.landid ? true : false}
                                >
                                  {land.deedno}{" "}{land.landno}
                                </option>
                                ))}
                              </Input>
                            </Col>
                          </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem>
                            <h5>เอกสารการเสนอขายที่ดิน : </h5>
                            <hr />
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="OtherFile"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {this.state.fileOther.map((other, i) =>
                                  other.fileOthername != "" ? (
                                    <p key={i}>
                                      <FaFileExport
                                        style={{
                                          color: "#17a2b8",
                                        }}
                                      />{" "}
                                      <a
                                        href={
                                          "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                          other.fileOtherattach
                                        }
                                        target="_blank"
                                      >
                                        {other.fileOtherattach}
                                      </a>{" "}
                                      <FaMinusSquare
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.submitremove(other.fileOtherid)
                                        }
                                      />
                                      <FormText color="muted">
                                        {other.fileOthername}
                                      </FormText>
                                    </p>
                                  ) : (
                                    ""
                                  )
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" sm={3}></Label>
                              <Col sm={9}>
                                <Button
                                  color="info"
                                  onClick={this.handleAddFile}
                                  size="sm"
                                >
                                  Add
                                </Button>
                              </Col>
                            </FormGroup>
                            {this.state.filesarr.map((fileadd, idx) => (
                              <FormGroup row key={idx}>
                                <Label className="text-right" for="File" sm={3}>
                                  {`File #${idx + 1} `}
                                </Label>
                                <Col sm={9}>
                                  <Row form>
                                    <Col sm={4}>
                                      <Input
                                        type="text"
                                        name="filename"
                                        value={fileadd.filename}
                                        onChange={this.handleChange(idx)}
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <Input
                                          type="file"
                                          name="attachfile"
                                          onChange={this.handleFileChange(idx)}
                                        />
                                        <FormText color="muted">
                                          เอกสารเพิ่มเติม
                                        </FormText>
                                      </FormGroup>
                                    </Col>
                                    <Col md={1}>
                                      <FormGroup>
                                        <Button
                                          color="danger"
                                          onClick={this.handleRemoveFile(idx)}
                                          size="sm"
                                        >
                                          -
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </FormGroup>
                            ))}
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col><br/></Col>
                    </Row>
                    {saveSuccess && (
                      <Row className="text-center">
                        <Col>
                          <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                          <br />
                        </Col>
                      </Row>
                    )}
                    <Row className="text-center">
                      <Col>
                        <Button type="submit" color="success">
                          บันทึก
                        </Button>{" "}
                        <Button
                          type="button"
                          color="warning"
                          onClick={this.cancelItem_onClick}
                        >
                          ยกเลิก
                        </Button>
                      </Col>
                    </Row>
                  </OrderArea>
                ))}
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Landoffer);
