import React, { Component, Redirect } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  UncontrolledCarousel,
  Alert,
} from "reactstrap";
import banner from "../assets/img/titlelabai.jpg";
import styled from "styled-components";
import { validateFields } from "../Validation";
import classnames from "classnames";
import ClientCaptcha from "react-client-captcha";

const Wrapper = styled.section`
  padding: 2em;
  background: #eeeeee;
`;

const Boxdiv = styled(Col)`
  padding: 2em;
`;

const initialState = {
  date: new Date().toLocaleString("en-US"),
  firstname: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  lastname: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  username: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  newpassword: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  captcha: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  databanner: [],
  saveSuccess: false,
  saveFail: false,
  submitCalled: false,
  allFieldsValidated: false,
  captchaCode: '',
  captchaFail: false,
};

class Apply extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

  }

  async componentDidMount() {
    const getUrl = "frontendapi/getBanners";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ databanner: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));

    //console.log(evt.target.name+' '+evt.target.value);
  }

  onChange(validationFunc, value) {
    const field = "captcha";
    const fieldVal = value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  setCaptcha(code) {
    this.setState({ captchaCode: code});
  }

  handleSubmit(evt) {
    evt.preventDefault();
    // validate all fields
    const { firstname, lastname, username, newpassword, captcha } = this.state;
    const firstnameError = validateFields.validateFirstname(firstname.value);
    const lastnameError = validateFields.validateLastname(lastname.value);
    const usernameError = validateFields.validateUsername(username.value);
    const newpasswordError = validateFields.validateNewpassword(
      newpassword.value
    );
    const captchaError = validateFields.validateCaptcha(captcha.value);
    if (
      [
        firstnameError,
        lastnameError,
        usernameError,
        newpasswordError,
        captchaError,
      ].every((e) => e === false)
    ) {

      if (this.state.captchaCode === this.state.captcha.value){
        
      const getNameUrl = "frontendapi/signup";
      axios
        .post(getNameUrl, this.state)
        .then((response) => {
            if (response.data != '1' && response.data != null) {
            localStorage.setItem("token", "a;dkjf0eifjawekfklsadf");
            localStorage.setItem("userlogin", response.data.username);

            this.setState({ saveSuccess: true });
            this.showAllFieldsValidated();
            this.showSuccess();
            setTimeout(() => {
              window.location.reload(false);
            }, 1500);
            
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("userlogin");
            this.setState({ saveFail: true });
            this.showDuplicate();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      }else {

        this.setState({ captchaFail: true });
        this.showCaptchaFail();
      } 

    } else {
      // update the state with errors
      this.setState((state) => ({
        firstname: {
          ...state.firstname,
          validateOnChange: true,
          error: firstnameError,
        },
        lastname: {
          ...state.lastname,
          validateOnChange: true,
          error: lastnameError,
        },
        username: {
          ...state.username,
          validateOnChange: true,
          error: usernameError,
        },
        newpassword: {
          ...state.newpassword,
          validateOnChange: true,
          error: newpasswordError,
        },
        captcha: {
          ...state.captcha,
          validateOnChange: true,
          error: captchaError,
        },
      }));
    }
  }

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false });
    }, 1000);
  }

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  showCaptchaFail() {
    setTimeout(() => {
      this.setState({ captchaFail: false });
    }, 1500);
  }

  showDuplicate() {
    setTimeout(() => {
      this.setState({ saveFail: false });
    }, 1500);
  }

  getImageFromURL = (img) => {
    const getImage = "/labai-backend/assets/images/banners/" + img;
    return getImage;
  };

  render() {
    const {
      date,
      firstname,
      lastname,
      username,
      newpassword,
      captcha,
      saveSuccess,
      saveFail,
      captchaFail,
      allFieldsValidated,
      databanner,
    } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row xs="1" sm="1" md="2">
              <Col>
                <img src={banner} alt="Apply" style={{ width: "100%" }} />
                <p>เข้าถึงง่าย สะดวก และรวดเร็ว</p>
                <UncontrolledCarousel items={databanner} />
              </Col>
              <Col>
                <div className="card-body">
                {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">ลงทะเบียนสำเร็จ รอสักครู่... ท่านกำลังเข้าสู่ระบบ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}
                  {saveFail && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="danger">มีผู้ใช้งานอยู่แล้ว กรุณาลองใหม่</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}
                  <Form onSubmit={(evt) => this.handleSubmit(evt)}>
                    <input type="hidden" name="date" value={date} />
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Input
                            type="text"
                            name="firstname"
                            placeholder="ชื่อ"
                            value={firstname.value}
                            className={classnames(
                              "form-control",
                              { "is-valid": firstname.error === false },
                              { "is-invalid": firstname.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateFirstname,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(
                                validateFields.validateFirstname,
                                evt
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            {firstname.error}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Input
                            type="text"
                            name="lastname"
                            placeholder="นามสกุล"
                            value={lastname.value}
                            className={classnames(
                              "form-control",
                              { "is-valid": lastname.error === false },
                              { "is-invalid": lastname.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateLastname,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(
                                validateFields.validateLastname,
                                evt
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            {lastname.error}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Input
                        type="text"
                        name="username"
                        placeholder="หมายเลขโทรศัพท์มือถือหรืออีเมล์"
                        value={username.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": username.error === false },
                          { "is-invalid": username.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateUsername,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(validateFields.validateUsername, evt)
                        }
                      />
                      <div className="invalid-feedback">{username.error}</div>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="newpassword"
                        placeholder="รหัสผ่าน"
                        value={newpassword.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": newpassword.error === false },
                          { "is-invalid": newpassword.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateNewpassword,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateNewpassword,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">
                        {newpassword.error}
                      </div>
                    </FormGroup>
                    <p>
                      เมื่อคลิก สมัคร แสดงว่าคุณยินยอมตาม <Link to="/agreement" target="_blank">ข้อตกลงและเงื่อนไขการใช้บริการ</Link> และ<Link to="/policy" target="_blank">นโยบายข้อมูล</Link> ของเราแล้ว
                    </p>
                    {captchaFail && (
                          <Row className="text-center">
                            <Col>
                              <Alert color="danger">ยืนยันรูปภาพไม่ตรง กรุณาลองใหม่</Alert>
                              <br />
                            </Col>
                          </Row>
                        )}
                    <Row form>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "1rem",
                        }}
                      ><ClientCaptcha backgroundColor="#ffc107" captchaCode={code => this.setCaptcha(code)} />
                      </Col>
                      </Row>
                      <Row form>
                      <Col sm="12" md={{ size: 10, offset: 1 }}>
                      <FormGroup>
                        <Input
                        type="text"
                        name="captcha"
                        placeholder="พิมพ์อักษรตามภาพเพื่อยืนยันตัวตน"
                        value={captcha.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": captcha.error === false },
                          { "is-invalid": captcha.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        />
                        <div className="invalid-feedback">{captcha.error}</div>
                        
                      </FormGroup>
                      </Col></Row>
                    <FormGroup className="text-center">
                      <Button type="submit" className="w-100" color="success">
                        สมัคร
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </div>
    );
  }
}
export default withRouter(Apply);
