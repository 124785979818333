import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

export default class Service_apply extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    const getUrl = "frontendapi/services/apply";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>การสมัครใช้บริการ</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col>
                <Breadcrumb className="float-right">
                  <BreadcrumbItem>
                    <a href="/">หน้าหลัก</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>บริการของเรา</BreadcrumbItem>
                  <BreadcrumbItem active>การสมัครใช้บริการ</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              {this.state.data.map((service, i) => (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: service.detail,
                  }}
                />
              ))}
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
