import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import clmlogo from "../assets/img/clm_logo.jpg";
import {
  Container,
  Col,
  Row,
} from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 4em;
  background: #ffffff;
`;

const TitleH3 = styled.h3`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
`;

class Links extends Component {

  render() {
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <TitleH3>ลิงค์ที่เกี่ยวข้อง</TitleH3>
              </Col>
            </Row>
            <Row xs="1" sm="2" md="4">
                <Col>
                    <a target="_blank" href="http://clm.labai.or.th/"><img src={clmlogo} alt="CLM Logo" className="img-thumbnail" /></a>
                </Col>
            </Row>
          </Container>
        </Wrapper>
      </div>
    );
  }
}
export default withRouter(Links);
