import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Badge,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import { FaUser, FaEye, FaEllipsisH, FaTrashAlt } from "react-icons/fa";
import NumberFormat from "react-number-format";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const GrayLink = styled(Link)`
  color: #6c757d;
  border-radius: 50%;

  &:hover {
    background: #ffffff;
    padding: 10px 12px 10px 12px;
    color: #6c757d;
  }
`;

const OrderList = (props) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>เลขที่โฉนด</th>
          <th>เลขที่ดิน</th>
          <th>ประเภท</th>
          <th>ขนาด/ไร่</th>
          <th>ขนาด/งาน</th>
          <th>ขนาด/ตร.วา</th>
          <th>ราคา</th>
          <th>ตำบล</th>
          <th>อำเภอ</th>
          <th>จังหวัด</th>
          <th>รายละเอียด</th>
          <th>ลบ</th>
        </tr>
      </thead>
      <tbody>
        {props.landdata.map((land, i) => (
          <tr key={i}>
            <th scope="row">{land.deedno}</th>
            <td>{land.landno}</td>
            <td>{land.landtypename}</td>
            <td>{land.farmarea}</td>
            <td>{land.workarea}</td>
            <td>{land.squarearea}</td>
            <td>
              <NumberFormat
                value={land.total}
                displayType={"text"}
                thousandSeparator={true}
              />
            </td>
            <td>{land.landdistrict}</td>
            <td>{land.landamphur}</td>
            <td>{land.landprovince}</td>
            <td className="text-center">
              <GrayLink to={"/landedit/" + land.landid}>
                <FaEllipsisH />
              </GrayLink>
            </td>
            <td className="text-center css.icon">
              <FaTrashAlt
                onClick={() => {
                  if (window.confirm("Delete the item?")) {
                    props.onClickDelete(land.landid);
                  }
                }}
                style={{ cursor: "pointer" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

class Land extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }
  }

  state = {
    data: [],
    userid: "",
    firstname: "",
    lastname: "",
    isSuccess: false,
    isFail: false,
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getNameUrl = "frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const getUrl = "frontendapi/landall/" + this.state.userid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onClickDelete = (id) => {
    const getUrl = "frontendapi/deleteland/" + id;
    axios
      .post(getUrl, this.state)
      .then((response) => {
        console.log(response);
        if (response.data) {
          this.setState({ isSuccess: true });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          this.setState({ isFail: true });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickform = () => {
    this.props.history.push("/landform");
  };

  render() {
    const { userid, firstname, lastname } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Row xs="2">
                  <Col>
                    <h5>
                      <FaUser />
                      ข้อมูลที่ดิน
                    </h5>
                  </Col>
                  <Col className="text-right">
                    <Button onClick={this.onClickform} color="success">
                      เพิ่มที่ดิน
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row className="text-center">
                  <Col>
                    {this.state.isSuccess ? (
                      <Alert color="success">การดำเนินการเสร็จสมบูรณ์</Alert>
                    ) : null}
                    {this.state.isFail ? (
                      <Alert color="danger">ไม่สามารถดำเนินการได้</Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <OrderList
                          landdata={this.state.data}
                          onClickDelete={this.onClickDelete}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Land);
