import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Nav,
  NavItem,
  Button,
  Form,
  FormGroup,
  ButtonDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
  Badge,
  FormText,
} from "reactstrap";
import styled from "styled-components";
import { FaInfoCircle, FaRegListAlt } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";

const Wrapper = styled.div`
  background: #343a40;
  color: #fff;
`;

const InfoAlert = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret size="sm" color="info">
        <FaInfoCircle style={{ marginBottom: "0.2rem" }} />{" "}
        <Badge color="warning">{props.alertnum1 + props.alertnum2}</Badge>
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: "auto",
                  maxHeight: "300px",
                },
              };
            },
          },
        }}
      >
        <DropdownItem header style={{ color: "#069370" }}>
          <FaInfoCircle /> แจ้งเตือนสำหรับคุณ{" "}
          <Badge color="warning">{props.alertnum1}</Badge>
        </DropdownItem>
        {props.dataHelpAlert.map((helpalert, i) => (
          <DropdownItem key={i} href={"/gethelpdetail/" + helpalert.gethelpid}>
            คำขอความช่วยเหลือหมายเลข #{helpalert.gethelpid} {helpalert.name}
            <FormText color="muted">
              {moment
                .unix(helpalert.datetime)
                .utc()
                .add(543, "year")
                .local()
                .format("DD/MM/YYYY")}
            </FormText>
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <DropdownItem header style={{ color: "#069370" }}>
          <FaInfoCircle /> งานที่ขอให้คุณดำเนินการ{" "}
          <Badge color="warning">{props.alertnum2}</Badge>
        </DropdownItem>
        {props.dataOperateAlert.map((operatealert, ii) =>
          operatealert.requestcategoryid == "1" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
                {moment
                .unix(operatealert.dategethelp)
                .utc()
                .add(543, "year")
                .local()
                .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "2" ? (
            <DropdownItem
              key={ii}
              href={"/landrequest/" + operatealert.gethelpID}
            >
              <FaRegListAlt key={ii} /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "3" ? (
            <DropdownItem
              key={ii}
              href={"/accountinfo/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "4" ? (
            <DropdownItem
              key={ii}
              href={"/debstructure/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "5" ? (
            <DropdownItem
              key={ii}
              href={"/adjustrequest/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "6" ? (
            <DropdownItem
              key={ii}
              href={"/budgetrequest/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "7" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "8" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "9" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "10" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "11" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpaddfiles/" + operatealert.gethelpOperateID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "12" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "13" ? (
            <DropdownItem
              key={ii}
              href={"/gethelpdetail/" + operatealert.gethelpID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : operatealert.requestcategoryid == "14" ? (
            <DropdownItem
              key={ii}
              href={"/landoffer/" + operatealert.gethelpOperateID}
            >
              <FaRegListAlt /> {operatealert.name}
              <FormText color="muted">
              {moment
                  .unix(operatealert.dategethelp)
                  .utc()
                  .add(543, "year")
                  .local()
                  .format("DD/MM/YYYY")}
                {" : "}
                คำขอความช่วยเหลือหมายเลข #{operatealert.gethelpID}
              </FormText>
            </DropdownItem>
          ) : (
            ""
          )
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const AccountSetting = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="success" caret size="sm">
        บัญชี
        </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={props.AccountMgt}>ตั้งค่าโปรไฟล์</DropdownItem>
        <DropdownItem onClick={props.ChangePassword}>เปลี่ยนรหัสผ่าน</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={props.Logout}>ออกจากระบบ</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

class Nav_labai extends Component {
  constructor(props) {
    super(props);

    let isLoggedIn = true;

    const token = localStorage.getItem("token");

    if (token == null) {
      isLoggedIn = false;
    }

    this.state = {
      username: "",
      password: "",
      userlogin: "",
      userid: "",
      firstname: "",
      lastname: "",
      date: "",
      dataHelp: [],
      dataOperate: [],
      alert1: "",
      alert2: "",
      isLoggedIn,
    };
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    if (userlogin) {
      this.setState({ userlogin: userlogin });
      this.setState({ isLoggedIn: true });
    
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        if (response.data) {
          const daterequestnew = moment()
            .utcOffset("+05:30")
            .format("YYYY-MM-DD");

          this.setState({
            userid: response.data[0].id,
            firstname: response.data[0].firstname,
            lastname: response.data[0].lastname,
            date: daterequestnew,
          });

          const getalertUrl =
            "../frontendapi/gethelp_alert/" + this.state.userid;
          axios
            .get(getalertUrl, this.state)
            .then((response) => {
              // const datenew = moment()
              //   .utcOffset("+05:30")
              //   .format("YYYY-MM-DD");
              this.setState({
                dataHelp: response.data,
                alert1: response.data.length,
              });
            })
            .catch((error) => {
              console.log(error);
            });

          const getoperateUrl =
            "../frontendapi/gethelp_operate/" + this.state.userid;
          axios
            .get(getoperateUrl, this.state)
            .then((response) => {
              // const datenew = moment()
              //   .utcOffset("+05:30")
              //   .format("YYYY-MM-DD");
              this.setState({
                dataOperate: response.data,
                alert2: response.data.length,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    }
  }

  onClickLogout = (e) => {
    const token = localStorage.removeItem("token");
    localStorage.removeItem("userlogin");

    if (token == null) {
      this.setState({ isLoggedIn: false });
    }

    this.setState({ username: "", password: "" });
    this.props.history.push("/");
  };

  onClickaccount = () => {
    this.props.history.push("/account");
  };

  onChangePassword = () => {
    this.props.history.push("/changepassword");
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const getNameUrl = "frontendapi/signin";
    axios
      .post(getNameUrl, this.state)
      .then((response) => {
        if (response.data) {
          localStorage.setItem("token", "a;dkjf0eifjawekfklsadf");
          localStorage.setItem("userlogin", response.data.username);
          this.setState({
            isLoggedIn: true, 
            username: response.data.username,
            userid: response.data.id,
            firstname: response.data.firstname,
            lastname: response.data.lastname
          });
          this.props.onLoggedIn({ isLoggedIn: true });
        } else {
          this.setState({ isLoggedIn: false });
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleLogin = () => {
    this.props.history.push("/login");
  };

  handleForgotPassword = () => {
    this.props.history.push("/recovery");
  };

  render() {
    const {
      isLoggedIn,
      firstname,
      lastname,
      dataHelp,
      dataOperate,
      alert1,
      alert2,
      username,
      password,
      userlogin,
    } = this.state;

    return (
      <div>
        <Wrapper className="clearfix" style={{ padding: "0.5rem" }}>
          <Container className="themed-container">
            {isLoggedIn ? (
              <Nav className="float-right">
                <NavItem style={{ marginRight: "1rem" }}>
                  <InfoAlert
                    dataHelpAlert={dataHelp}
                    dataOperateAlert={dataOperate}
                    alertnum1={alert1}
                    alertnum2={alert2}
                  />
                </NavItem>
                <NavItem>
                  สวัสดีคุณ {firstname}{" "}{lastname}
                  <AccountSetting Logout={this.onClickLogout} AccountMgt={this.onClickaccount} ChangePassword={this.onChangePassword}  />
                </NavItem>
              </Nav>
            ) : (
              <Nav className="float-right">
                <NavItem>
                  <Form inline id="login_form">
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Button outline  color="success" size="sm" onClick={this.handleLogin}>
                        เข้าสู่ระบบ
                      </Button>
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Button outline  color="info" size="sm" onClick={this.handleForgotPassword}>
                        ลืมรหัสผ่าน
                      </Button>
                    </FormGroup>
                  </Form>
                </NavItem>
              </Nav>
            )}
          </Container>
        </Wrapper>
      </div>
    );
  }
}
export default withRouter(Nav_labai);
