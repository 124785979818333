import React, { Component } from "react";
import axios from "axios";
import { withRouter, NavLink as RRNavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import { validateFields } from "../Validation";
import classnames from "classnames";
import ClientCaptcha from "react-client-captcha";

const Wrapper = styled.section`
  background: #069370;
`;

const TitleH3 = styled.h3`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const WorkArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const initialState = {
  date: new Date().toLocaleString("en-US"),
  name: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  email: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  phone: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  detail: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  captcha: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  submitCalled: false,
  allFieldsValidated: false,
  saveSuccess: false,
  captchaCode: '',
  captchaFail: false,
};

class Consult_form extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    const token = localStorage.getItem("token");

    if (token) {
      this.props.history.push("/dashboard");
    }
  }

  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  onChange(validationFunc, value) {
    const field = "captcha";
    const fieldVal = value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  setCaptcha(code) {
    this.setState({ captchaCode: code});
  }

  handleSubmit(evt) {
    evt.preventDefault();
    // validate all fields
    const { name, email, phone, detail, captcha } = this.state;
    const nameError = validateFields.validateName(name.value);
    const emailError = validateFields.validateEmail(email.value);
    const phoneError = validateFields.validatePhone(phone.value);
    const detailError = validateFields.validateDetail(detail.value);
    const captchaError = validateFields.validateCaptcha(captcha.value);
    if (
      [nameError, emailError, phoneError, detailError, captchaError].every(
        (e) => e === false
      )
    ) {
      
      if (this.state.captchaCode === this.state.captcha.value){

      const getHelpUrl = "frontendapi/consulthome";
      axios
        .post(getHelpUrl, this.state)
        .then((response) => {
          console.log(response);
          if (response.data) {
            // clear state and show all fields are validated
            this.setState({ ...initialState, allFieldsValidated: true });
            this.showAllFieldsValidated();
            this.setState({ saveSuccess: true });
            this.showSuccess();
            setTimeout(() => {
              this.props.history.push("/");
            }, 1500);
          } else {
            this.props.history.push("/consulthome");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      }else {

        this.setState({ captchaFail: true });
        this.showCaptchaFail();
      } 

    } else {
      // update the state with errors
      this.setState((state) => ({
        name: {
          ...state.name,
          validateOnChange: true,
          error: nameError,
        },
        email: {
          ...state.email,
          validateOnChange: true,
          error: emailError,
        },
        phone: {
          ...state.phone,
          validateOnChange: true,
          error: phoneError,
        },
        detail: {
          ...state.detail,
          validateOnChange: true,
          error: detailError,
        },
        captcha: {
          ...state.captcha,
          validateOnChange: true,
          error: captchaError,
        },
      }));
    }
  }

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false });
    }, 1000);
  }

  showCaptchaFail() {
    setTimeout(() => {
      this.setState({ captchaFail: false });
    }, 1500);
  }

  render() {
    const {
      date,
      name,
      email,
      phone,
      detail,
      allFieldsValidated,
      saveSuccess,
      captcha,
      captchaFail,
    } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>สอบถามข้อมูล</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              <Col>
                <WorkArea>
                  <Container>
                    <Row>
                      <Col>
                        <TitleH3>เราให้ความช่วยเหลือคุณด้านใดได้บ้าง</TitleH3>
                        <p>
                          ประเภทบริการ สอบถามข้อมูลการใช้บริการ
                          ขอเอกสารแบบฟอร์มการใช้บริการ
                          สอบถามข้อมูลติดต่อหน่วยงาน แจ้งเรื่องร้องเรียน
                          ขอคำแนะนำ
                        </p>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="text-center"
                        sm="12"
                        md={{ size: 6, offset: 3 }}
                      >
                        <TitleH3>แบบฟอร์มการสอบถามข้อมูล</TitleH3>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <div className="card-body">
                          <Form onSubmit={(evt) => this.handleSubmit(evt)}>
                            <input type="hidden" name="date" value={date} />
                            <FormGroup>
                              <Label for="name">ชื่อ - นามสกุล</Label>
                              <Input
                                type="text"
                                name="name"
                                value={name.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": name.error === false },
                                  { "is-invalid": name.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validateName,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validateName,
                                    evt
                                  )
                                }
                              />
                              <div className="invalid-feedback">
                                {name.error}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label for="email">อีเมล์</Label>
                              <Input
                                type="text"
                                name="email"
                                value={email.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": email.error === false },
                                  { "is-invalid": email.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validateEmail,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validateEmail,
                                    evt
                                  )
                                }
                              />
                              <div className="invalid-feedback">
                                {email.error}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label for="phone">เบอร์โทร</Label>
                              <Input
                                type="text"
                                name="phone"
                                value={phone.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": phone.error === false },
                                  { "is-invalid": phone.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validatePhone,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validatePhone,
                                    evt
                                  )
                                }
                              />
                              <div className="invalid-feedback">
                                {phone.error}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label for="detail">
                                รายละเอียดที่ต้องการสอบถาม
                              </Label>
                              <Input
                                type="textarea"
                                rows="5"
                                name="detail"
                                id="detail"
                                value={detail.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": detail.error === false },
                                  { "is-invalid": detail.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validateDetail,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validateDetail,
                                    evt
                                  )
                                }
                              />
                              <div className="invalid-feedback">
                                {detail.error}
                              </div>
                            </FormGroup>
                            {captchaFail && (
                          <Row className="text-center">
                            <Col>
                              <Alert color="danger">ยืนยันรูปภาพไม่ตรง กรุณาลองใหม่</Alert>
                              <br />
                            </Col>
                          </Row>
                        )}
                    <Row form>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "1rem",
                        }}
                      ><ClientCaptcha backgroundColor="#ffc107" captchaCode={code => this.setCaptcha(code)} />
                      </Col>
                      </Row>
                      <Row form>
                      <Col sm="12" md={{ size: 10, offset: 1 }}>
                      <FormGroup>
                        <Input
                        type="text"
                        name="captcha"
                        placeholder="พิมพ์อักษรตามภาพเพื่อยืนยันตัวตน"
                        value={captcha.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": captcha.error === false },
                          { "is-invalid": captcha.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        />
                        <div className="invalid-feedback">{captcha.error}</div>
                        
                      </FormGroup>
                      </Col></Row>
                      {saveSuccess && (
                              <Row className="text-center">
                                <Col>
                                  <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                                  <br />
                                </Col>
                              </Row>
                            )}
                            <Row className="text-center">
                              <Col>
                                <Button
                                  type="submit"
                                  color="info"
                                  onMouseDown={() =>
                                    this.setState({ submitCalled: true })
                                  }
                                >
                                  ส่งข้อมูล
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </WorkArea>
              </Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
export default withRouter(Consult_form);
