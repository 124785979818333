import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import InputAddress from "react-thailand-address-autocomplete";
import { validateFields } from "../Validation";
import classnames from "classnames";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

class Userdata extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      userdata: [],
      dataRequesttype: [],
      dataRequestreason: [],
      username_sign: "guest001",
      password_sign: "a123456",
      idnumber: "",
      title: "",
      firstname: "",
      lastname: "",
      birthdate: "",
      email_line: "",
      tel: "",
      fax: "",
      gethelp_career: "",
      gethelp_agriculturist: "",
      district: "",
      amphur: "",
      province: "",
      zipcode: "",
      requesttypeid: "",
      requestreasonid: "",
    };
    

    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getRequesttypeUrl = "frontendapi/requesttype";
      await axios
        .get(getRequesttypeUrl, this.state)
        .then((response) => {
          //console.log(response.data);
          this.setState({ dataRequesttype: response.data });
        })
        .catch((error) => {
          console.log(error);
        });

      const getRequestreasonUrl = "frontendapi/requestreason";
      await axios
        .get(getRequestreasonUrl, this.state)
        .then((response) => {
          //console.log(response.data);
          this.setState({ dataRequestreason: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("Input data : " + e.target.value);
  };



  handleSubmit = (evt) => {
    evt.preventDefault();

    //const userlogin = localStorage.getItem("userlogin");

    const accountUrl = "frontendapi/userdata";
    axios
      .post(accountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ userdata: response.data });

          // this.showSuccess();
          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 1500);
        } 
      })
      .catch((error) => {
        console.log(error);
      });

  };

  cancelItem_onClick = () => {
    window.history.back();
  };

  render() {
    const {
      idnumber,
      title,
      firstname,
      lastname,
      birthdate,
      gethelp_career,
      gethelp_agriculturist,
      tel,
      email_line,
      district,
      amphur,
      province,
      zipcode,
      requesttypeid,
      requestreasonid,
    } = this.state;

    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>แก้ไขโปรไฟล์</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                  {/* {this.state.data.map((user, i) => (
                    <h3 key={i}>
                      {user.id} {user.firstname} {user.lastname}
                    </h3>
                  ))} */}
                </Col>
              </Row>
              <hr />
              <OrderArea>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <h5>ข้อมูลผู้ใช้งาน</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="idnumber" sm={3}>
                      เลขที่บัตรประชาชน
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="idnumber"
                        placeholder="เลขที่บัตรประชาชน"
                        value={idnumber}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="firstname" sm={3}>
                      ชื่อ-นามสกุล
                    </Label>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder="ชื่อ"
                        value={firstname}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="นามสกุล"
                        value={lastname}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="tel" sm={3}>
                      เบอร์โทร
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="tel"
                        placeholder="เบอร์โทร"
                        value={tel}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="email_line" sm={3}>
                      อีเมล์
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="email"
                        name="email_line"
                        placeholder="อีเมล์"
                        value={email_line}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>ที่อยู่ปัจจุบัน</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="districtid" sm={3}>
                      ตำบล
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="district"
                        placeholder="ตำบล"
                        value={district}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="amphurid" sm={1}>
                      อำเภอ
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="amphur"
                        placeholder="อำเภอ"
                        value={amphur}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="provinceid" sm={3}>
                      จังหวัด
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="province"
                        placeholder="จังหวัด"
                        value={province}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="zipcodeid" sm={1}>
                      รหัสไปรษณีย์
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="zipcode"
                        placeholder="รหัสไปรษณีย์"
                        value={zipcode}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>คำขอความช่วยเหลือ</h5>
                      <hr />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="requesttypeid" sm={3}>
                      ประเภทคำขอ
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        {this.state.dataRequesttype.map((requesttype, i) =>
                          requesttype.id != "0" ? (
                            <CustomInput
                              key={i}
                              type="radio"
                              id={"requesttypeid" + requesttype.id}
                              name="requesttypeid"
                              value={requesttype.id}
                              label={requesttype.name}
                              onChange={this.changeHandler}
                              checked={
                                requesttypeid == requesttype.id ? true : false
                              }
                              inline
                            />
                          ) : (
                            ""
                          )
                        )}
                        <CustomInput
                          type="radio"
                          id="requesttypeid0"
                          name="requesttypeid"
                          value="0"
                          label="อื่นๆ"
                          onChange={this.changeHandler}
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="zipcodeid" sm={3}>
                      เหตุผลการยื่นคำขอ
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        {this.state.dataRequestreason.map((requestreason, i) =>
                          requestreason.id != "0" ? (
                            <CustomInput
                              key={i}
                              type="radio"
                              id={"requestreasonid" + requestreason.id}
                              name="requestreasonid"
                              value={requestreason.id}
                              label={requestreason.name}
                              onChange={this.changeHandler}
                              checked={
                                requestreasonid == requestreason.id
                                  ? true
                                  : false
                              }
                              inline
                            />
                          ) : (
                            ""
                          )
                        )}
                        <CustomInput
                          type="radio"
                          id="requestreasonid0"
                          name="requestreasonid"
                          value="0"
                          label="อื่นๆ"
                          onChange={this.changeHandler}
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <Row className="text-center">
                    <Col>
                      <Button type="submit" color="success">
                        ค้นหา
                      </Button>{" "}
                      <Button color="warning" onClick={this.cancelItem_onClick}>ยกเลิก</Button>
                    </Col>
                  </Row>
                </Form>
              </OrderArea>
            </Container>
          </Wrapper2>
          <Wrapper2>
          <Container className="themed-container">
            <OrderArea>
              <Row>
                  <Col>
                    <h5>User Data List</h5>
                    <hr color="primary" />
                  </Col>
                </Row>
                  <Row>
                  <Col>
                    {JSON.stringify(this.state.userdata)}
                  </Col>
                </Row>
              </OrderArea>
          </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Userdata);
