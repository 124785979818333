import React, { Component } from "react";
//import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Navbar_labai from "./nav/Navbar_labai";
import Nav_labai from "./nav/Nav_labai";
import Footer from "./nav/Footer";
import Main from "./home/Main";
import Agreement from "./agreement/Agreement";
import Policy from "./agreement/Policy";
import Service from "./service/Service";
import Service_apply from "./service/Service_apply";
import Faq from "./faq/Faq";
import Km from "./km/Km";
import Kmdetail from "./km/Kmdetail";
import Contact from "./contact/Contact";
import Login from "./home/Login";
import ConsultformHome from "./home/Consult_form";
import styled from "styled-components";
import Dashboard from "./user/Dashboard";
import Consult from "./user/Consult";
import Consultform from "./user/Consult_form";
import Account from "./user/Account";
import Recovery from "./home/Recovery";
import Gethelp from "./user/Gethelp";
import GethelpEdit from "./user/GethelpEdit";
import GethelpDetail from "./user/Gethelpdetail";
import GethelpAddFiles from "./user/GethelpAddFiles";
import Consultdet from "./user/Consultdet";
import Evaluation from "./user/Evaluation";
import AccountInfo from "./user/AccountInfo";
import Landrequest from "./user/Landrequest";
import DebtStructure from "./user/DebtStructure";
import AdjustRequest from "./user/AdjustRequest";
import BudgetRequest from "./user/BudgetRequest";
import Changepassword from "./user/Changepassword";
import Land from "./user/Land";
import Landform from "./user/Land_form";
import Landedit from "./user/Land_edit";
import Landoffer from "./user/Landoffer";
import Upload from "./upload/Uploadfile";
import Multiinput from "./test/IncorporationForm";
import Addremove from "./test/Addremove";
import ContactForm from "./home/Contact_form";
import ReportUser from "./reports/Userdata";

const Divclear = styled.div`
  clear: both;
`;

class App extends Component {
  constructor(props) {
    super(props);
    

    let isLoggedIn = true;

    const token = localStorage.getItem("token");
    const userlogin = localStorage.getItem("userlogin");
    let dataisAuth = sessionStorage.getItem('isAuth');

    // console.log("dataisAuth : " + dataisAuth);

    if (dataisAuth == null) {
      window.localStorage.clear();
      isLoggedIn = false;
    }

    this.state = {
      newname: "React",
      data: [],
      userId: "",
      datelogin: "",
      isHomepage: true,
      isLoggedIn,
    };
  }

  onLoggedIn = (isLoggedIn) => {
    this.setState({ isLoggedIn: isLoggedIn });
    this.props.history.push("/dashboard");
  };

  onHomepage = (isHomepage) => {
    this.setState({ isHomepage: isHomepage });
  };

  render() {
    const { isLoggedIn, isHomepage, newname } = this.state;
    return (
      <div>
        <Nav_labai onLoggedIn={this.onLoggedIn} />
        <Divclear></Divclear>
        <Navbar_labai onHomepage={this.onHomepage} />
        <Switch>
          {/* <Route exact path="/">
            {isLoggedIn ? <Redirect to="/dashboard" /> : <Main />}
          </Route> */}
          <Route exact path="/" component={Main} />
          <Route path="/agreement" component={Agreement} />
          <Route path="/policy" component={Policy} />
          <Route path="/service" component={Service} />
          <Route path="/service_apply" component={Service_apply} />
          <Route path="/faq" component={Faq} />
          <Route path="/km" component={Km} />
          <Route path="/kmdetail/:id" component={Kmdetail} />
          <Route path="/contact" component={Contact} />
          <Route path="/login" component={Login} />
          <Route path="/consulthome" component={ConsultformHome} />
          <Route path="/recovery" component={Recovery} />

          <Route path="/dashboard" component={Dashboard} />
          <Route path="/consult" component={Consult} />
          <Route path="/consultform" component={Consultform} />
          <Route path="/consultdet/:id" component={Consultdet} />
          <Route path="/account" component={Account} />
          <Route path="/changepassword" component={Changepassword} />
          <Route path="/land" component={Land} />
          <Route path="/landform" component={Landform} />
          <Route path="/landedit/:id" component={Landedit} />
          <Route path="/landoffer/:id" component={Landoffer} />

          <Route path="/gethelp" component={Gethelp} />
          <Route path="/gethelpedit/:id" component={GethelpEdit} />
          <Route path="/gethelpdetail/:id" component={GethelpDetail} />
          <Route path="/gethelpaddfiles/:id" component={GethelpAddFiles} />
          <Route path="/evaluation/:gethelpid" component={Evaluation} />
          <Route path="/accountinfo/:gethelpid" component={AccountInfo} />
          <Route path="/landrequest/:gethelpid" component={Landrequest} />
          <Route path="/debstructure/:gethelpid" component={DebtStructure} />
          <Route path="/adjustrequest/:gethelpid" component={AdjustRequest} />
          <Route path="/budgetrequest/:gethelpid" component={BudgetRequest} />

          <Route path="/multiinput" component={Multiinput} />
          <Route path="/addremove" component={Addremove} />
          <Route path="/contactform" component={ContactForm} />

          <Route path="/reportuser" component={ReportUser} />

          <Route path="/upload" component={Upload} />
        </Switch>

        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
