import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import InputAddress from "react-thailand-address-autocomplete";
import { validateFields } from "../Validation";
import classnames from "classnames";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const TitleH4 = styled.h4`
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const Newinput = styled.input`
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgb(206, 212, 218);
  padding-left: 10px;
  font-size: 15px;
`;


class Land_edit extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      dataLandtype: [],
      checked3: false,
      landid: this.props.match.params.id,
      userid: "",
      firstname: "",
      lastname: "",
      deedno: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      landno: "",
      position: "",
      book: "",
      page: "",
      landtype: 1,
      landtypeother: "",
      subdistrict: "",
      district: "",
      province: "",
      latitude: "",
      longitude: "",
      farmarea: "",
      workarea: "",
      squarearea: "",
      farmonce: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      total: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      saveSuccess: false,
      saveFail: false,
      submitCalled: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChange3 = this.handleChange3.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

      const getLandUrl = "../frontendapi/getlandbyid/" + this.state.landid;
      await axios
        .get(getLandUrl, this.state)
        .then((response) => {
        if(response.data){
            this.setState({
                deedno: {value: response.data[0].deedno,},
                landno: response.data[0].landno,
                position: response.data[0].position,
                book: response.data[0].book,
                page: response.data[0].page,
                landtype: response.data[0].landtypeid,
                landtypeother: response.data[0].landtypeother,
                subdistrict: response.data[0].district,
                district: response.data[0].amphur,
                province: response.data[0].province,
                latitude: response.data[0].latitude,
                longitude: response.data[0].longitude,
                farmarea: response.data[0].farmarea,
                workarea: response.data[0].workarea,
                squarearea: response.data[0].squarearea,
                farmonce: {value: response.data[0].farmonce,},
                total: {value: response.data[0].total,},
              });

              if (response.data[0].landtypeid == 0) {
                this.setState({ checked3: true });
              } else {
                this.setState({ checked3: false, landtypeother: "" });
              }
        }
          
        })
        .catch((error) => {
          console.log(error);
        });

      const getLandtypeUrl = "../frontendapi/landtype";
      await axios
        .get(getLandtypeUrl, this.state)
        .then((response) => {
          //console.log(response.data);
          this.setState({ dataLandtype: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelect = (fullAddress) => {
    const { subdistrict, district, province } = fullAddress;

    this.setState({
      subdistrict,
      district,
      province,
    });
  };

  /*
   * validates the field onBlur if sumbit button is not clicked
   * set the validateOnChange to true for that field
   * check for error
   */
  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    const { deedno, farmonce, total } = this.state;
    const deednoError = validateFields.validateDeedno(deedno.value);
    const farmonceError = validateFields.validateNumeric(farmonce.value);
    const totalError = validateFields.validateNumeric(total.value);
    if ([deednoError,farmonceError ,totalError].every((e) => e === false)) {

    const accountUrl = "../frontendapi/landupdate/" + this.state.landid;
    axios
      .post(accountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ saveSuccess: true });
          this.showSuccess();
          setTimeout(() => {
            this.props.history.push("/land");
          }, 1500);
        } else {
          this.setState({ saveFail: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    } else {
      // update the state with errors
      this.setState((state) => ({
        deedno: {
          ...state.deedno,
          validateOnChange: true,
          error: deednoError,
        },
        farmonce: {
          ...state.farmonce,
          validateOnChange: true,
          error: farmonceError,
        },
        total: {
          ...state.total,
          validateOnChange: true,
          error: totalError,
        },
      }));
    }
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  cancelItem_onClick = () => {
    window.history.back();
  };

  handleChange3(e) {
    if (e.target.value == 0) {
      this.setState({ checked3: true, landtype: e.target.value });
    } else {
      this.setState({ checked3: false, landtype: e.target.value });
    }
  }

  render() {
    const hidden_chk3 = this.state.checked3 ? "text" : "hidden";
    const {
      userid,
      firstname,
      lastname,
      deedno,
      landno,
      position,
      book,
      page,
      landtype,
      landtypeother,
      subdistrict,
      district,
      province,
      latitude,
      longitude,
      farmarea,
      workarea,
      squarearea,
      farmonce,
      total,
      saveSuccess,
    } = this.state;

    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ตั้งค่าโปรไฟล์</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
            <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <h5>ข้อมูลที่ดิน</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="deedno" sm={3}>
                      เลขที่โฉนดที่ดิน
                    </Label>
                    <Col sm={4}>
                      <Input
                            type="text"
                            name="deedno"
                            value={deedno.value}
                            className={classnames(
                              "form-control",
                              { "is-valid": deedno.error === false },
                              { "is-invalid": deedno.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateDeedno,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(validateFields.validateDeedno, evt)
                            }
                          />
                          <div className="invalid-feedback">{deedno.error}</div>
                    </Col>
                    <Label className="text-right" for="landno" sm={1}>
                      เลขที่ดิน
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="landno"
                        value={landno}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="position" sm={3}>
                      ระวาง
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="position"
                        value={position}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="book" sm={1}>
                      เล่ม
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="book"
                        value={book}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="page" sm={3}>
                      หน้า
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="page"
                        value={page}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="district" sm={1}>
                      ตำบล
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="subdistrict"
                        type="text"
                        name="subdistrict"
                        value={subdistrict}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="amphurid" sm={3}>
                      อำเภอ
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="district"
                        type="text"
                        name="district"
                        value={district}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                    <Label className="text-right" for="provinceid" sm={1}>
                      จังหวัด
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="province"
                        type="text"
                        name="province"
                        value={province}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="latitude" sm={3}>
                      ละติจูด
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="latitude"
                        value={latitude}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="longitude" sm={1}>
                      ลองติจูด
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="longitude"
                        value={longitude}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="farmarea" sm={3}>
                      เนื้อที่/ไร่
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="farmarea"
                        value={farmarea}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="workarea" sm={1}>
                    เนื้อที่/งาน
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="workarea"
                        value={workarea}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="squarearea" sm={3}>
                      เนื้อที่/ตร.วา
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="squarearea"
                        value={squarearea}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="farmonce" sm={1}>
                      ราคาต่อหน่วย
                    </Label>
                    <Col sm={4}>
                      <Input
                            type="text"
                            name="farmonce"
                            value={farmonce.value}
                            className={classnames(
                              "form-control",
                              { "is-valid": farmonce.error === false },
                              { "is-invalid": farmonce.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateNumeric,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(validateFields.validateNumeric, evt)
                            }
                          />
                          <div className="invalid-feedback">{farmonce.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="total" sm={3}>
                      รวมเป็นเงิน
                    </Label>
                    <Col sm={4}>
                      <Input
                            type="text"
                            name="total"
                            value={total.value}
                            className={classnames(
                              "form-control",
                              { "is-valid": total.error === false },
                              { "is-invalid": total.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateNumeric,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(validateFields.validateNumeric, evt)
                            }
                          />
                          <div className="invalid-feedback">{total.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="zipcodeid" sm={3}>
                      ประเภทเอกสารสิทธิในที่ดิน
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        {this.state.dataLandtype.map((landtypedata, i) =>
                          landtypedata.id != "0" ? (
                            <CustomInput
                              key={i}
                              type="radio"
                              id={"landtype" + landtypedata.id}
                              name="landtype"
                              value={landtypedata.id}
                              label={landtypedata.name}
                              onChange={this.handleChange3}
                              checked={
                                landtype == landtypedata.id ? true : false
                              }
                              inline
                            />
                          ) : (
                            ""
                          )
                        )}
                        <CustomInput
                          type="radio"
                          id="landtype0"
                          name="landtype"
                          value="0"
                          checked={landtype == 0 ? true : false}
                          onChange={this.handleChange3}
                          label="อื่นๆ"
                          inline
                        />
                        <Newinput
                          type={hidden_chk3}
                          name="landtypeother"
                          value={landtypeother}
                          onChange={this.changeHandler}
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}
                  <Row className="text-center">
                    <Col>
                      <Button type="submit" color="success" onMouseDown={() =>
                            this.setState({ submitCalled: true })
                          }>
                        บันทึก
                      </Button>{" "}
                      <Button color="warning" onClick={this.cancelItem_onClick}>ยกเลิก</Button>
                    </Col>
                  </Row>
                </Form>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Land_edit);
