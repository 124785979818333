import React, { Component } from "react";
import { Nav, NavItem, NavLink, Button, ButtonGroup } from "reactstrap";
import { NavLink as RRNavLink, withRouter } from "react-router-dom";

class menu extends Component {
  onGetdashboard = () => {
    this.props.history.push("/dashboard");
  };
  onGethelp = () => {
    this.props.history.push("/gethelp");
  };
  onGetconsult = () => {
    this.props.history.push("/consult");
  };
  onGetland = () => {
    this.props.history.push("/land");
  };
  render() {
    return (
      <div>
        <ButtonGroup>
          <Button color="info" onClick={this.onGetdashboard}>
            Dashboard
          </Button>
          <Button color="info" onClick={this.onGethelp}>
            ขอรับความช่วยเหลือ
          </Button>
          <Button color="info" onClick={this.onGetconsult}>
            ขอคำปรึกษา
          </Button>
          <Button color="info" onClick={this.onGetland}>
            ข้อมูลที่ดิน
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

export default withRouter(menu);
