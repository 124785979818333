import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardTitle,
  CardFooter,
  CardLink,
  CardText,
  CardDeck,
  CardBody,
} from "reactstrap";
import styled from "styled-components";
import Moment from "react-moment";
import moment from "moment";

const TitleH3 = styled.h3`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
`;

const TitleH5 = styled.h5`
  font-size: 14px;
  color: #069370;
  margin-bottom: 0rem;
`;

const CardFooterNew = styled(CardFooter)`
  font-size: 12px;
`;

const CardLinkNew = styled(CardLink)`
  color: #069370;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const CardTitleNew = styled(CardTitle)`
  margin-bottom: 0rem;
`;

const CardTextNew = styled(CardText)`
  font-size: 14px;
  margin-bottom: 0rem;
`;

const CardBodyNew = styled(CardBody)`
  padding: 1rem 1rem 0rem 1rem;
`;

class Kmgroup extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    this.state = {
      data: [],
      datagroup: [],
      datasearch: [],
      groupStatus: true,
      searchStatus: false,
      //groupID: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {

    if(this.props.groupID){
      if (prevProps.groupID !== this.props.groupID) {
      console.log("prevProps groupID : " + this.props.groupID);
      const getUrl = "frontendapi/kmallByGrupID/" + this.props.groupID;
          await axios
            .get(getUrl, this.state)
            .then((response) => {
              this.setState({ datagroup: response.data, groupStatus: true, searchStatus: false });
            })
            .catch((error) => {
              console.log(error);
            });
          }
    }else if(this.props.keySearch){
      if (prevProps.keySearch !== this.props.keySearch) {
      console.log("prevProps keysearch : " + this.props.keySearch);
      const getUrl = "frontendapi/kmBysearch/" + this.props.keySearch;
                await axios
                  .get(getUrl, this.state)
                  .then((response) => {
                    this.setState({ datasearch: response.data, groupStatus: false, searchStatus: true  });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                }
    }else{
      if (prevProps.groupID !== this.props.groupID) {
      console.log("show all");
      const getUrl = "frontendapi/kmall";
            await axios
              .get(getUrl, this.state)
              .then((response) => {
                this.setState({ datagroup: response.data, groupStatus: true, searchStatus: false });
              })
              .catch((error) => {
                console.log(error);
              });
      }
    }

    
  }

  async componentDidMount() {

    const getUrl = "frontendapi/kmall";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ datagroup: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

  }

  onClickdetail = (id) => {
    this.props.history.push("/kmdetail/" + id);
  };

  onClickGroup = (id) => {
    console.log(id);
  };

  getImageFromURL = (img) => {
    const getImage = "/labai-backend/assets/images/kms/" + img;
    return getImage;
  };

  showLimitContent = (val, limit) => {
    const pos1 = val.replace("<p>", "");
    const pos2 = pos1.replace("</p>", "");
    const pos3 = pos2.replace("<br />", "");

    const txt = pos3.length;
    let subtxt = "";

    txt > limit ? (subtxt = val.substr(0, limit) + "...") : (subtxt = val);

    return subtxt;
  };

  render() {
    const {
      searchStatus,
      groupStatus,
    } = this.state;
    return (
      <div>
        {searchStatus && (
        <Container>
          <Row xs="1" md="3"> 
            {this.state.datasearch.map((km, ii) => (
              <Col key={ii} style={{ padding: "1rem" }}>
                <CardDeck>
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      height="150px"
                      src={this.getImageFromURL(km.picture)}
                      alt="LABAI KM"
                    />
                    <CardBodyNew>
                      <CardTitleNew>
                        <TitleH5>
                          <label
                            dangerouslySetInnerHTML={{
                              __html: this.showLimitContent(km.name, 50),
                            }}
                          />
                        </TitleH5>
                      </CardTitleNew>
                      <CardTextNew
                        dangerouslySetInnerHTML={{
                          __html: this.showLimitContent(km.detail, 100),
                        }}
                      />
                    </CardBodyNew>
                    <CardFooterNew>
                      วันที่สร้าง :
                      {moment
                        .unix(km.date)
                        .utc()
                        .add(543, "year")
                        .local()
                        .format("DD/MM/YYYY")}
                      <CardLinkNew
                        style={{
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => this.onClickdetail(km.id)}
                      >
                        รายละเอียด
                      </CardLinkNew>
                    </CardFooterNew>
                  </Card>
                </CardDeck>
              </Col>
            ))}
          </Row>
        </Container>
        )} 
        {groupStatus && (
        <Container>
          {this.state.datagroup.map((kmgroup, i) => (
            <div key={i}>
              {i % 2 == 0 ? (
                <Row xs="1">
                  <Col>
                    <TitleH3>{kmgroup.name}</TitleH3>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {i % 2 != 0 ? (
                <Row xs="1" md="3"> 
                  {this.state.datagroup[i].map((km, ii) => (
                    <Col key={ii} style={{ padding: "1rem" }}>
                      <CardDeck>
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            height="150px"
                            src={this.getImageFromURL(km.picture)}
                            alt="LABAI KM"
                          />
                          <CardBodyNew>
                            <CardTitleNew>
                              <TitleH5>
                                <label
                                  dangerouslySetInnerHTML={{
                                    __html: this.showLimitContent(km.name, 50),
                                  }}
                                />
                              </TitleH5>
                            </CardTitleNew>
                            <CardTextNew
                              dangerouslySetInnerHTML={{
                                __html: this.showLimitContent(km.detail, 100),
                              }}
                            />
                          </CardBodyNew>
                          <CardFooterNew>
                            วันที่สร้าง :
                            {moment
                              .unix(km.date)
                              .utc()
                              .add(543, "year")
                              .local()
                              .format("DD/MM/YYYY")}
                            <CardLinkNew
                              style={{
                                float: "right",
                                cursor: "pointer",
                              }}
                              onClick={() => this.onClickdetail(km.id)}
                            >
                              รายละเอียด
                            </CardLinkNew>
                          </CardFooterNew>
                        </Card>
                      </CardDeck>
                    </Col>
                  ))}
                </Row>
              ) : (
                ""
              )}
            </div>
          ))}
        </Container>
        )}
      </div>
    );
  }
}

export default withRouter(Kmgroup);
