import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  Badge,
  FormText,
} from "reactstrap";
import km_img from "../assets/img/two-business.jpg";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaRegListAlt, FaFileExport, FaWindowClose } from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class Gethelpdetail extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }
  }

  state = {
    data: [],
    dataHelp: [],
    dataHelpdet: [],
    gethelp_status: [],
    gethelp_operate_all: [],
    date: new Date().toLocaleString("en-US"),
    gethelpDate: "",
    userid: "",
    consultid: "",
    firstname: "",
    lastname: "",
    file1id: "",
    file2id: "",
    file3id: "",
    file4id: "",
    file5id: "",
    file6id: "",
    file7id: "",
    file1name: "",
    file2name: "",
    file3name: "",
    file4name: "",
    file5name: "",
    file6name: "",
    file7name: "",
    file1Attach: "",
    file2Attach: "",
    file3Attach: "",
    file4Attach: "",
    file5Attach: "",
    file6Attach: "",
    file7Attach: "",
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          data: response.data,
          userId: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const gethelpUrl =
      "../frontendapi/gethelp_by_id/" + this.props.match.params.id;
    await axios
      .get(gethelpUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({
            dataHelp: response.data,
            gethelpDate: response.data[0].date,
          });
          // console.log(JSON.stringify(response.data));

          //////////// get Status
          const getstatusUrl =
            "../frontendapi/gethelp_status/" + response.data[0].gethelpid;
          axios
            .get(getstatusUrl, this.state)
            .then((response) => {
              //console.log("response : " + JSON.stringify(response.data));

              if (response.data != "") {
                this.setState({ gethelp_status: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get gethelp_operate
          const getoperateUrl =
            "../frontendapi/gethelp_operate_by_gethelpid_all/" + response.data[0].gethelpid;
          axios
            .get(getoperateUrl, this.state)
            .then((response) => {
              //console.log("response : " + JSON.stringify(response.data));

              if (response.data != "") {
                this.setState({ gethelp_operate_all: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get attactfile
          const getfileUrl =
            "../frontendapi/getfile/tbl_gethelp/" + response.data[0].gethelpid;
          axios
            .get(getfileUrl, this.state)
            .then((response) => {
              //console.log("response : " + JSON.stringify(response.data));
              if (response.data != "") {
                for (let i = 0; i < response.data.length; i++) {
                  if (response.data[i].attachfile.split("_")[0] == "file1") {
                    let file1name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file1name_temp = response.data[i].attachfile;
                    }else{
                      file1name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file1id: response.data[i].id,
                      file1name: file1name_temp,
                      file1Attach: response.data[i].attachfile,
                    });
                  } else if (response.data[i].attachfile.split("_")[0] == "file2") {
                    let file2name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file2name_temp = response.data[i].attachfile;
                    }else{
                      file2name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file2id: response.data[i].id,
                      file2name: file2name_temp,
                      file2Attach: response.data[i].attachfile,
                    });
                  } else if (response.data[i].attachfile.split("_")[0] == "file3") {
                    let file3name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file3name_temp = response.data[i].attachfile;
                    }else{
                      file3name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file3id: response.data[i].id,
                      file3name: file3name_temp,
                      file3Attach: response.data[i].attachfile,
                    });
                  } else if (response.data[i].attachfile.split("_")[0] == "file4") {
                    let file4name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file4name_temp = response.data[i].attachfile;
                    }else{
                      file4name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file4id: response.data[i].id,
                      file4name: file4name_temp,
                      file4Attach: response.data[i].attachfile,
                    });
                  } else if (response.data[i].attachfile.split("_")[0] == "file5") {
                    let file5name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file5name_temp = response.data[i].attachfile;
                    }else{
                      file5name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file5id: response.data[i].id,
                      file5name: file5name_temp,
                      file5Attach: response.data[i].attachfile,
                    });
                  } else if (response.data[i].attachfile.split("_")[0] == "file6") {
                    let file6name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file6name_temp = response.data[i].attachfile;
                    }else{
                      file6name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file6id: response.data[i].id,
                      file6name: file6name_temp,
                      file6Attach: response.data[i].attachfile,
                    });
                  } else if (response.data[i].attachfile.split("_")[0] == "file7") {
                    let file7name_temp = "";
                    if(response.data[i].filename === "" || response.data[i].filename === null){
                      file7name_temp = response.data[i].attachfile;
                    }else{
                      file7name_temp = response.data[i].filename;
                    }
                    this.setState({
                      file7id: response.data[i].id,
                      file7name: file7name_temp,
                      file7Attach: response.data[i].attachfile,
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  render() {
    const {
      date,
      firstname,
      lastname,
      gethelpDate,
      file1id,
      file2id,
      file3id,
      file4id,
      file5id,
      file6id,
      file7id,
      file1name,
      file2name,
      file3name,
      file4name,
      file5name,
      file6name,
      file7name,
      file1Attach,
      file2Attach,
      file3Attach,
      file4Attach,
      file5Attach,
      file6Attach,
      file7Attach,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form>
                {this.state.dataHelp.map((gethelp, i) => (
                  <OrderArea key={i}>
                    <Row>
                      <Col xs="6">
                        <h5>
                          <FaRegListAlt /> รายละเอียดคำขอความช่วยเหลือ หมายเลข #{" "}
                          {gethelp.gethelpid}
                        </h5>
                      </Col>
                      <Col xs="6" className="text-right">
                        {gethelp.countQuestionnaire==0?
                        <Button
                          onClick={() => this.onEvaluation(gethelp.gethelpid)}
                          color="primary"
                          style={{ marginBottom: "1rem" }}
                        >
                          แบบประเมินความพึงพอใจ
                        </Button>: <Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                        
                        <MenuDetails getHelpID={gethelp.gethelpid} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup>
                          <ListGroupItem>
                            <h5>ข้อมูลผู้ยื่นคำร้อง :</h5>
                            <hr />
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requesttype"
                                sm={3}
                              >
                                วันที่ยื่นคำขอ
                              </Label>
                              <Col sm={3}>
                                <div style={{ padding: ".5rem" }}>
                                  {moment
                                    .unix(gethelp.date)
                                    .utc()
                                    .add(543, "year")
                                    .local()
                                    .format("DD/MM/YYYY")}
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requesttype"
                                sm={3}
                              >
                                ผู้ยื่นคำขอ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requesttype"
                                  value={
                                    gethelp.firstname + " " + gethelp.lastname
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requesttype"
                                sm={3}
                              >
                                อายุ
                              </Label>
                              <Col sm={3}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requesttype"
                                  value={moment().diff(
                                    moment
                                      .unix(gethelp.birthdate)
                                      .utc()
                                      .format(),
                                    "years"
                                  )}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requesttype"
                                sm={3}
                              >
                                อาชีพ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requesttype"
                                  value={gethelp.career}
                                />
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem>
                            <h5>ข้อมูลคำร้องขอความช่วยเหลือ : </h5>
                            <hr />

                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requesttype"
                                sm={3}
                              >
                                ประเภทคำขอ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requesttype"
                                  value={
                                    gethelp.reqid === "0"
                                      ? gethelp.requesttypeother
                                      : gethelp.rtname
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requestreason"
                                sm={3}
                              >
                                เหตุผลการยื่นคำขอ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requestreason"
                                  value={
                                    gethelp.requestreasonid === "0"
                                      ? gethelp.requestreasonother
                                      : gethelp.rsname
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="landuse"
                                sm={3}
                              >
                                กรณีใช้ประโยชน์ในที่ดิน
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="landuse"
                                  value={
                                    gethelp.landuse === "1"
                                      ? "ที่ดินเกษตรกรรม"
                                      : gethelp.landuse === "2"
                                      ? "ที่อยู่อาศัย"
                                      : ""
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="landtype"
                                sm={3}
                              >
                                ประเภทเอกสารสิทธิในที่ดิน
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="landtype"
                                  value={
                                    gethelp.landtype === "0"
                                      ? gethelp.landtypeother
                                      : gethelp.landtypename
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="rentaccept"
                                sm={3}
                              >
                                การเข้าสู่แนวทางการเช่าซื้อ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="rentaccept"
                                  value={
                                    gethelp.rentaccept === 1
                                      ? "ยินยอม"
                                      : gethelp.rentaccept === 0
                                      ? "ไม่ยินยอม"
                                      : ""
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="detail" sm={3}>
                                รายละเอียดการขอความช่วยเหลือ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  type="textarea"
                                  name="detail"
                                  rows="5"
                                  value={gethelp.detail}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem>
                            <h5>เอกสารประกอบการยื่นคำขอ : </h5>
                            <hr />
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file1"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file1name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file1Attach
                                      }
                                      target="_blank"
                                    >
                                      {file1name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  สำเนาบัตรประจำตัวประชาชน สำเนาทะเบียนบ้าน
                                  ของผู้ขอความช่วยเหลือ และคู่สมรส
                                  หรือผู้กู้ร่วม (กรณีขอสินเชื่อ) (ถ้ามี)
                                  หรือผู้ที่ได้รับมอบหมายเป็นผู้แทนองค์กรชุมชน
                                  เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                  หรือสถาบันเกษตรกร
                                </FormText>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file2"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file2name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file2Attach
                                      }
                                      target="_blank"
                                    >
                                      {file2name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  สำเนาหลักฐานที่แสดงว่าเป็นผู้มีอำนาจกระทำการแทน
                                  สำเนาหนังสือรับรองการจดทะเบียน
                                  สำเนาทะเบียนสมาชิก
                                  สำเนาข้อบังคับหรือกติกาอื่นที่แสดงถึงการบริหารจัดการร่วมกันของชุมชน
                                  และหลักฐานอื่นที่เกี่ยวกับองค์กรชุมชน
                                  เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                  หรือสถาบันเกษตรกร
                                </FormText>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file3"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file3name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file3Attach
                                      }
                                      target="_blank"
                                    >
                                      {file3name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  สำเนาเอกสารสิทธิในที่ดิน เช่น โฉนดที่ดิน น.ส.
                                  3 ก. น.ส. 3 เป็นต้น
                                </FormText>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file4"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file4name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file4Attach
                                      }
                                      target="_blank"
                                    >
                                      {file4name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  สำเนาเอกสารหลักฐานการทำนิติกรรมสัญญากับบุคคลหรือนิติบุคคล
                                  เช่น สัญญาขายฝาก สัญญาจำนอง เป็นต้น
                                </FormText>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file5"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file5name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file5Attach
                                      }
                                      target="_blank"
                                    >
                                      {file5name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  คำสั่งศาล คำพิพากษา
                                  หรือคำสั่งเจ้าพนักงานบังคับคดี
                                  หรือสัญญาประนีประนอมยอมความ (ถ้ามี)
                                </FormText>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file6"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file6name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file6Attach
                                      }
                                      target="_blank"
                                    >
                                      {file6name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  เอกสารหลักฐานแสดงราคาประเมินที่ดินของทางราชการ
                                </FormText>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="file7"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {file7name != "" ? (
                                  <p>
                                    <FaFileExport
                                      style={{
                                        color: "#17a2b8",
                                      }}
                                    />{" "}
                                    <a
                                      href={
                                        "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                        file7Attach
                                      }
                                      target="_blank"
                                    >
                                      {file7name}
                                    </a>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <FaWindowClose
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                    {" ไม่ปรากฎไฟล์แนบ"}
                                  </p>
                                )}
                                <FormText color="muted">
                                  เอกสารเพิ่มเติม
                                </FormText>
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem>
                            <h5>สถานะการดำเนินการ : </h5>
                            <Table striped>
                              <thead>
                                <tr>
                                  <th className="text-center">วันเวลา</th>
                                  <th className="text-center">สถานะ</th>
                                  <th className="text-center">
                                    ระยะเวลาดำเนินงาน
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.gethelp_status.map(
                                  (gethelpStatus, i) => (
                                    <tr key={i}>
                                      <td className="text-center">
                                        {moment
                                          .unix(gethelpStatus.datetime)
                                          .utc()
                                          .add(543, "year")
                                          .local()
                                          .format("DD/MM/YYYY HH:mm")}
                                      </td>
                                      <td className="text-center">
                                        <Badge
                                          color={gethelpStatus.button_color}
                                        >
                                          {gethelpStatus.name}
                                        </Badge>
                                      </td>
                                      <td className="text-center">
                                        {gethelpStatus.date_previous != null
                                          ? moment
                                              .duration(
                                                moment(
                                                  moment
                                                    .unix(
                                                      gethelpStatus.datetime
                                                    )
                                                    .utc()
                                                    .local()
                                                    .format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ).diff(
                                                  moment(
                                                    moment
                                                      .unix(
                                                        gethelpStatus.date_previous
                                                      )
                                                      .utc()
                                                      .local()
                                                      .format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ),
                                                  "minutes"
                                                ),
                                                "minutes"
                                              )
                                              .format(
                                                "y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]",
                                                {
                                                  largest: 1,
                                                }
                                              )
                                          : moment
                                              .duration(
                                                moment(
                                                  moment
                                                    .unix(
                                                      gethelpStatus.datetime
                                                    )
                                                    .utc()
                                                    .local()
                                                    .format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ).diff(
                                                  moment(
                                                    moment
                                                      .unix(
                                                        gethelpStatus.gethelpdate
                                                      )
                                                      .utc()
                                                      .local()
                                                      .format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ),
                                                  "minutes"
                                                ),
                                                "minutes"
                                              )
                                              .format(
                                                "y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]",
                                                {
                                                  largest: 1,
                                                }
                                              )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </ListGroupItem>
                          <ListGroupItem>
                            <h5>รายละเอียดการดำเนินการ : </h5>
                            <Table striped>
                              <thead>
                                <tr>
                                  <th className="text-center">วันเวลา</th>
                                  <th className="text-center">หมวดหมู่ปัญหา</th>
                                  <th className="text-center">
                                    ระยะเวลาดำเนินงาน
                                  </th>
                                  <th className="text-center">
                                    สถานะ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.gethelp_operate_all.map(
                                  (gethelpOperate, i) => (
                                    <tr key={i}>
                                      <td className="text-center">
                                        {moment
                                          .unix(gethelpOperate.dategethelp)
                                          .utc()
                                          .add(543, "year")
                                          .local()
                                          .format("DD/MM/YYYY HH:mm")}
                                      </td>
                                      <td className="text-center">
                                        {
                                        gethelpOperate.requestcategoryid == "1" ? (
                                          <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "2" ? (
                                            <Link to={{pathname: `/landrequest/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "3" ? (
                                            <Link to={{pathname: `/accountinfo/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "4" ? (
                                            <Link to={{pathname: `/debstructure/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "5" ? (
                                            <Link to={{pathname: `/adjustrequest/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "6" ? (
                                            <Link to={{pathname: `/budgetrequest/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "7" ? (
                                            <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "8" ? (
                                            <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "9" ? (
                                            <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "10" ? (
                                            <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "11" ? (
                                            <Link to={{pathname: `/gethelpaddfiles/${gethelpOperate.gethelpOperateID }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "12" ? (
                                            <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "13" ? (
                                            <Link to={{pathname: `/gethelpdetail/${gethelpOperate.gethelpid }`}}>{gethelpOperate.name}</Link>
                                          ) : gethelpOperate.requestcategoryid == "14" ? (
                                            <Link to={{pathname: `/landoffer/${gethelpOperate.gethelpOperateID }`}}>{gethelpOperate.name}</Link>
                                          ) : (
                                            ""
                                          )
                                      }
                                      </td>
                                      <td className="text-center">
                                        {
                                          gethelpOperate.dateendgethelp =='0'?

                                          moment
                                          .duration(
                                            moment(
                                              moment
                                                .utc()
                                                .local()
                                                .format(
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).diff(
                                              moment(
                                                moment
                                                  .unix(
                                                    gethelpOperate.dategethelp
                                                  )
                                                  .utc()
                                                  .local()
                                                  .format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ),
                                                "YYYY-MM-DD HH:mm:ss"
                                              ),
                                              "minutes"
                                            ),
                                            "minutes"
                                          )
                                          .format(
                                            "y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]",
                                            {
                                              largest: 1,
                                            }
                                          )

                                          : moment
                                          .duration(
                                            moment(
                                              moment
                                                .unix(
                                                  gethelpOperate.dateendgethelp
                                                )
                                                .utc()
                                                .local()
                                                .format(
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).diff(
                                              moment(
                                                moment
                                                  .unix(
                                                    gethelpOperate.dategethelp
                                                  )
                                                  .utc()
                                                  .local()
                                                  .format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ),
                                                "YYYY-MM-DD HH:mm:ss"
                                              ),
                                              "minutes"
                                            ),
                                            "minutes"
                                          )
                                          .format(
                                            "y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]",
                                            {
                                              largest: 1,
                                            }
                                          )
                                        }
                                      </td>
                                      <td className="text-center">
                                        {gethelpOperate.status == '0'? 
                                          <Badge color="danger" pill>กำลังดำเนินการ</Badge>
                                        : <Badge color="success" pill>ดำเนินการเสร็จสิ้น</Badge> }
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                    </Row>
                  </OrderArea>
                ))}
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Gethelpdetail);
