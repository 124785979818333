import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  CustomInput,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaRegListAlt } from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";
import InputAddress from "react-thailand-address-autocomplete";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

const Newinput = styled.input`
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgb(206, 212, 218);
  padding-left: 10px;
  font-size: 15px;
`;

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      userid: "",
      firstname: "",
      lastname: "",
      userinfoID: "",
      memberstudy_title: "",
      memberstudy_firstname: "",
      memberstudy_lastname: "",
      memberstudy_age: "",
      memberstudy_education: "",
      memberstudys: [
        {
          memberstudy_title: "",
          memberstudy_firstname: "",
          memberstudy_lastname: "",
          memberstudy_age: "",
          memberstudy_education: "",
        },
      ],
      membercareer_title: "",
      membercareer_firstname: "",
      membercareer_lastname: "",
      membercareer_age: "",
      membercareer_career: "",
      membercareers: [
        {
          membercareer_title: "",
          membercareer_firstname: "",
          membercareer_lastname: "",
          membercareer_age: "",
          membercareer_career: "",
        },
      ],
      finance1_name: "",
      finance1_financetype: "",
      finance1_pricemonth: "",
      finance1_priceyear: "",
      finance1_careertypeid: "1",
      finances1: [
        {
          finance1_name: "",
          finance1_financetype: "",
          finance1_pricemonth: "",
          finance1_priceyear: "",
          finance1_careertypeid: "1",
        },
      ],
      finance2_name: "",
      finance2_financetype: "",
      finance2_pricemonth: "",
      finance2_priceyear: "",
      finance2_careertypeid: "2",
      finances2: [
        {
          finance2_name: "",
          finance2_financetype: "",
          finance2_pricemonth: "",
          finance2_priceyear: "",
          finance2_careertypeid: "2",
        },
      ],
      finance3_name: "",
      finance3_financetype: "",
      finance3_pricemonth: "",
      finance3_priceyear: "",
      finance3_careertypeid: "3",
      finances3: [
        {
          finance3_name: "",
          finance3_financetype: "",
          finance3_pricemonth: "",
          finance3_priceyear: "",
          finance3_careertypeid: "3",
        },
      ],
      finance4_name: "",
      finance4_financetype: "",
      finance4_pricemonth: "",
      finance4_priceyear: "",
      finance4_careertypeid: "4",
      finances4: [
        {
          finance4_name: "",
          finance4_financetype: "",
          finance4_pricemonth: "",
          finance4_priceyear: "",
          finance4_careertypeid: "4",
        },
      ],
      deptin_name: "",
      deptin_principle: "",
      deptin_interest: "",
      deptin_total: "",
      deptins: [
        {
          deptin_name: "",
          deptin_principle: "",
          deptin_interest: "",
          deptin_total: "",
        },
      ],
      deptout_name: "",
      deptout_price: "",
      deptout_interest: "",
      deptouts: [
        {
          deptout_name: "",
          deptout_price: "",
          deptout_interest: "",
        },
      ],
      checked1: false,
      checked2: false,
      checked3: false,
      education: "1",
      educationother: "",
      marrystatus: "",
      marrystatusother: "",
      careertypeid: "",
      careerinfo: "",
      govmember: "",
      govmemberother: "",
      marryname: "",
      marryid: "",
      marrybirthdate: "",
      marrycareerid: "",
      marrycareerinfo: "",
      marryaddress: "",
      marryaddressinfo: "",
      marryvillage: "",
      marrymoo: "",
      marrysoi: "",
      marryroad: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      marrytel: "",
      familynum: "",
      familystudynum: "",
      familycareernum: "",
      landinfo: "",
      landinfonum: "",
      landinfonum1: true,
      landinfonum2: true,
      familycostutility: "",
      familycoststudy: "",
      familycostfood: "",
      familycostother: "",
      familycostothertext: "",
      deptinpay: "",
      deptinpayprice: "",
      otherassets: "",
      govhelp: "",
      helpformat: "",
      landneed: "",
      countEvaluation: "",
      infodate: new Date().toLocaleString("en-US"),
      gethelpid: this.props.match.params.gethelpid,
      process_status: "",
      saveSuccess: false,
      saveFail: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const datenew = moment().utcOffset("+05:30").format("YYYY-MM-DD");

        this.setState({
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

      //////////// Count Evaluation
      const getcountUrl = "../frontendapi/countEvaluation/" + this.state.gethelpid;
      await axios
      .get(getcountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ countEvaluation: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getUrl = "../frontendapi/getAccountinfo/" + this.state.userid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        if (response) {
          const newdate = moment(
            new Date(
              moment.unix(response.data[0].marrybirthdate).utc().format()
            )
          ).format("YYYY-MM-DD");

          this.setState({
            userid: response.data[0].userid,
            userinfoID: response.data[0].id,
            education: response.data[0].education,
            educationother: response.data[0].educationother,
            marrystatus: response.data[0].marrystatus,
            marrystatusother: response.data[0].marrystatusother,
            careertypeid: response.data[0].careertypeid,
            careerinfo: response.data[0].careerinfo,
            govmember: response.data[0].govmember,
            govmemberother: response.data[0].govmemberother,
            marryname: response.data[0].marryname,
            marryid: response.data[0].marryid,
            marrybirthdate: new Date(newdate),
            marrycareerid: response.data[0].marrycareerid,
            marrycareerinfo: response.data[0].marrycareerinfo,
            marryaddress: response.data[0].marryaddress,
            marryaddressinfo: response.data[0].marryaddressinfo,
            marryvillage: response.data[0].marryvillage,
            marrymoo: response.data[0].marrymoo,
            marrysoi: response.data[0].marrysoi,
            marryroad: response.data[0].marryroad,
            subdistrict: response.data[0].marrydistrict,
            district: response.data[0].marryamphur,
            province: response.data[0].marryprovince,
            zipcode: response.data[0].marryzipcode,
            marrytel: response.data[0].marrytel,
            landinfo: response.data[0].landinfo,
            landinfonum: response.data[0].landinfonum,
            landinfonum1: response.data[0].landinfonum1,
            landinfonum2: response.data[0].landinfonum2,
            familycostutility: response.data[0].familycostutility,
            familycoststudy: response.data[0].familycoststudy,
            familycostfood: response.data[0].familycostfood,
            familycostother: response.data[0].familycostother,
            familycostothertext: response.data[0].familycostothertext,
            deptinpay: response.data[0].deptinpay,
            deptinpayprice: response.data[0].deptinpayprice,
            otherassets: response.data[0].otherassets,
            govhelp: response.data[0].govhelp,
            helpformat: response.data[0].helpformat,
            landneed: response.data[0].landneed,
            process_status: "edit",
          });

          if (response.data[0].education == 7) {
            this.setState({ checked1: true });
          } else {
            this.setState({ checked1: false, educationother: "" });
          }

          if (response.data[0].marrystatus == 6) {
            this.setState({ checked2: true });
          } else {
            this.setState({ checked2: false, marrystatusother: "" });
          }

          if (response.data[0].govmember == 4) {
            this.setState({ checked3: true });
          } else {
            this.setState({ checked3: false, govmemberother: "" });
          }

          //console.log("userinfoID : " + response.data[0].id);

          //////////// get Members_study
          const getmemberstudyUrl =
            "../frontendapi/getAccountinfo_member_study/" + response.data[0].id;
          axios
            .get(getmemberstudyUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ memberstudys: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get Members_career
          const getmembercareerUrl =
            "../frontendapi/getAccountinfo_member_career/" +
            response.data[0].id;
          axios
            .get(getmembercareerUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ membercareers: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get user_finance
          const getFinance1Url =
            "../frontendapi/getAccountinfo_user_finance/1/" +
            response.data[0].id;
          axios
            .get(getFinance1Url, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ finances1: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          const getFinance2Url =
            "../frontendapi/getAccountinfo_user_finance/2/" +
            response.data[0].id;
          axios
            .get(getFinance2Url, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ finances2: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          const getFinance3Url =
            "../frontendapi/getAccountinfo_user_finance/3/" +
            response.data[0].id;
          axios
            .get(getFinance3Url, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ finances3: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          const getFinance4Url =
            "../frontendapi/getAccountinfo_user_finance/4/" +
            response.data[0].id;
          axios
            .get(getFinance4Url, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ finances4: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get user_deptin
          const getDeptinUrl =
            "../frontendapi/getAccountinfo_user_deptin/" + response.data[0].id;
          axios
            .get(getDeptinUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ deptins: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get user_deptout
          const getDeptoutUrl =
            "../frontendapi/getAccountinfo_user_deptout/" + response.data[0].id;
          axios
            .get(getDeptoutUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ deptouts: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  handleDateChange = (value, e) => {
    this.setState({ marrybirthdate: value, });
  };

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;

    console.log(fullAddress.district);
    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const getsubmitURL =
      "../frontendapi/mgtAccountinfo/" + this.state.gethelpid;

    axios
      .post(getsubmitURL, this.state)
      .then((response) => {
        console.log("restpons : " + response.data);
        if (response.data) {
          this.setState({ process_status: "edit", saveSuccess: true });
          this.showSuccess();
          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 1000);
        } else {
          this.props.history.push("/accountinfo/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  //////////////// Add Member study /////////

  handleMemberstudyChange = (idx) => (evt) => {
    const newMemberstudys = this.state.memberstudys.map((memberstudy, sidx) => {
      if (idx !== sidx) return memberstudy;
      return { ...memberstudy, [evt.target.name]: evt.target.value };
    });

    this.setState({ memberstudys: newMemberstudys });
  };

  handleAddMemberstudy = () => {
    this.setState({
      memberstudys: this.state.memberstudys.concat([
        {
          memberstudy_title: "",
          memberstudy_firstname: "",
          memberstudy_lastname: "",
          memberstudy_age: "",
          memberstudy_education: "",
        },
      ]),
    });
  };

  handleRemoveMemberstudy = (idx) => () => {
    this.setState({
      memberstudys: this.state.memberstudys.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add Member career /////////

  handleMembercareerChange = (idx) => (evt) => {
    const newMembercareers = this.state.membercareers.map(
      (membercareer, sidx) => {
        if (idx !== sidx) return membercareer;
        return { ...membercareer, [evt.target.name]: evt.target.value };
      }
    );

    this.setState({ membercareers: newMembercareers });
  };

  handleAddMembercareer = () => {
    this.setState({
      membercareers: this.state.membercareers.concat([
        {
          membercareer_title: "",
          membercareer_firstname: "",
          membercareer_lastname: "",
          membercareer_age: "",
          membercareer_career: "",
        },
      ]),
    });
  };

  handleRemoveMembercareer = (idx) => () => {
    this.setState({
      membercareers: this.state.membercareers.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add User Finance1 /////////

  handleFinance1Change = (idx) => (evt) => {
    const newFinances1 = this.state.finances1.map((finance1, sidx) => {
      if (idx !== sidx) return finance1;
      return { ...finance1, [evt.target.name]: evt.target.value };
    });
    console.log(evt.target.value);

    this.setState({ finances1: newFinances1 });
  };

  handleAddFinance1 = () => {
    this.setState({
      finances1: this.state.finances1.concat([
        {
          finance1_name: "",
          finance1_financetype: "",
          finance1_pricemonth: "",
          finance1_priceyear: "",
          finance1_careertypeid: "",
        },
      ]),
    });
  };

  handleRemoveFinance1 = (idx) => () => {
    this.setState({
      finances1: this.state.finances1.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add User Finance2 /////////

  handleFinance2Change = (idx) => (evt) => {
    const newFinances2 = this.state.finances2.map((finance2, sidx) => {
      if (idx !== sidx) return finance2;
      return { ...finance2, [evt.target.name]: evt.target.value };
    });

    this.setState({ finances2: newFinances2 });
  };

  handleAddFinance2 = () => {
    this.setState({
      finances2: this.state.finances2.concat([
        {
          finance2_name: "",
          finance2_financetype: "",
          finance2_pricemonth: "",
          finance2_priceyear: "",
          finance2_careertypeid: "",
        },
      ]),
    });
  };

  handleRemoveFinance2 = (idx) => () => {
    this.setState({
      finances2: this.state.finances2.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add User Finance3 /////////

  handleFinance3Change = (idx) => (evt) => {
    const newFinances3 = this.state.finances3.map((finance3, sidx) => {
      if (idx !== sidx) return finance3;
      return { ...finance3, [evt.target.name]: evt.target.value };
    });

    this.setState({ finances3: newFinances3 });
  };

  handleAddFinance3 = () => {
    this.setState({
      finances3: this.state.finances3.concat([
        {
          finance3_name: "",
          finance3_financetype: "",
          finance3_pricemonth: "",
          finance3_priceyear: "",
          finance3_careertypeid: "",
        },
      ]),
    });
  };

  handleRemoveFinance3 = (idx) => () => {
    this.setState({
      finances3: this.state.finances3.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add User Finance4 /////////

  handleFinance4Change = (idx) => (evt) => {
    const newFinances4 = this.state.finances4.map((finance4, sidx) => {
      if (idx !== sidx) return finance4;
      return { ...finance4, [evt.target.name]: evt.target.value };
    });

    this.setState({ finances4: newFinances4 });
  };

  handleAddFinance4 = () => {
    this.setState({
      finances4: this.state.finances4.concat([
        {
          finance4_name: "",
          finance4_financetype: "",
          finance4_pricemonth: "",
          finance4_priceyear: "",
          finance4_careertypeid: "",
        },
      ]),
    });
  };

  handleRemoveFinance4 = (idx) => () => {
    this.setState({
      finances4: this.state.finances4.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add Deptin/////////

  handleDeptinChange = (idx) => (evt) => {
    const newDeptins = this.state.deptins.map((deptin, sidx) => {
      if (idx !== sidx) return deptin;
      return { ...deptin, [evt.target.name]: evt.target.value };
    });

    this.setState({ deptins: newDeptins });
  };

  handleAddDeptin = () => {
    this.setState({
      deptins: this.state.deptins.concat([
        {
          deptin_name: "",
          deptin_principle: "",
          deptin_interest: "",
          deptin_total: "",
        },
      ]),
    });
  };

  handleRemoveDeptin = (idx) => () => {
    this.setState({
      deptins: this.state.deptins.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add Deptout /////////

  handleDeptoutChange = (idx) => (evt) => {
    const newDeptouts = this.state.deptouts.map((deptout, sidx) => {
      if (idx !== sidx) return deptout;
      return { ...deptout, [evt.target.name]: evt.target.value };
    });

    this.setState({ deptouts: newDeptouts });
  };

  handleAddDeptout = () => {
    this.setState({
      deptouts: this.state.deptouts.concat([
        {
          deptout_name: "",
          deptout_price: "",
          deptout_interest: "",
        },
      ]),
    });
  };

  handleRemoveDeptout = (idx) => () => {
    this.setState({
      deptouts: this.state.deptouts.filter((s, sidx) => idx !== sidx),
    });
  };

  handleChange1(e) {
    console.log(e.target.value);
    if (e.target.value == 7) {
      this.setState({ checked1: true, education: e.target.value });
    } else {
      this.setState({ checked1: false, education: e.target.value });
    }
  }
  handleChange2(e) {
    console.log(e.target.value);
    if (e.target.value == 6) {
      this.setState({ checked2: true, marrystatus: e.target.value });
    } else {
      this.setState({ checked2: false, marrystatus: e.target.value });
    }
  }
  handleChange3(e) {
    console.log(e.target.value);
    if (e.target.value == 4) {
      this.setState({ checked3: true, govmember: e.target.value });
    } else {
      this.setState({ checked3: false, govmember: e.target.value });
    }
    //this.setState({ checked3: !this.state.checked3 });
  }
  handleChange4(e) {
    console.log(e.target.value);
    if (e.target.value == 1) {
      this.setState({
        landinfonum1: false,
        landinfonum2: true,
        landinfo: e.target.value,
      });
    } else if (e.target.value == 2) {
      this.setState({
        landinfonum1: true,
        landinfonum2: false,
        landinfo: e.target.value,
      });
    } else {
      this.setState({
        landinfonum1: true,
        landinfonum2: true,
        landinfo: e.target.value,
      });
    }
  }

  render() {
    const hidden_chk1 = this.state.checked1 ? "text" : "hidden";
    const hidden_chk2 = this.state.checked2 ? "text" : "hidden";
    const hidden_chk3 = this.state.checked3 ? "text" : "hidden";

    const {
      firstname,
      lastname,
      education,
      educationother,
      marrystatus,
      marrystatusother,
      careertypeid,
      careerinfo,
      govmember,
      govmemberother,
      marryname,
      marryid,
      marrybirthdate,
      marrycareerid,
      marrycareerinfo,
      marryaddress,
      marryaddressinfo,
      marryvillage,
      marrymoo,
      marrysoi,
      marryroad,
      subdistrict,
      district,
      province,
      zipcode,
      marrytel,
      landinfo,
      landinfonum,
      landinfonum1,
      landinfonum2,
      familycostutility,
      familycoststudy,
      familycostfood,
      familycostother,
      familycostothertext,
      deptinpay,
      deptinpayprice,
      otherassets,
      govhelp,
      helpformat,
      landneed,
      gethelpid,
      saveSuccess,
      countEvaluation,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                <OrderArea>
                  <Row>
                    <Col xs="5">
                      <h5>
                        <FaRegListAlt /> แบบสอบถามข้อมูลเกษตรกร
                      </h5>
                    </Col>
                    <Col xs="7" className="text-right">
                      {countEvaluation==0?
                      <Button
                        onClick={() => this.onEvaluation(gethelpid)}
                        color="primary"
                        style={{ marginBottom: "1rem" }}
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>:""}{" "}
                      <MenuDetails getHelpID={gethelpid} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup>
                        <ListGroupItem>
                          <h5>ข้อมูลทั่วไป : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="education"
                              sm={3}
                            >
                              ระดับการศึกษา
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="ediucation1"
                                  name="education"
                                  value="1"
                                  label="ต่ำกว่าประถม"
                                  checked={education == "1" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="ediucation2"
                                  name="education"
                                  value="2"
                                  label="ประถม"
                                  checked={education == "2" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="ediucation3"
                                  name="education"
                                  value="3"
                                  label="มัธยมต้น"
                                  checked={education == "3" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="ediucation4"
                                  name="education"
                                  value="4"
                                  label="มัธยมปลาย"
                                  checked={education == "4" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="ediucation5"
                                  name="education"
                                  value="5"
                                  label="อนุปริญญา"
                                  checked={education == "5" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="ediucation6"
                                  name="education"
                                  value="6"
                                  label="ปริญญาตรี"
                                  checked={education == "6" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <br />
                                <CustomInput
                                  type="radio"
                                  id="ediucation7"
                                  name="education"
                                  value="7"
                                  label="อื่นๆ"
                                  checked={education == "7" ? true : false}
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <Newinput
                                  type={hidden_chk1}
                                  name="educationother"
                                  value={educationother}
                                  onChange={this.changeHandler}
                                  placeholder="อื่นๆ"
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marrystatus"
                              sm={3}
                            >
                              สถานภาพ
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="marrystatus1"
                                  name="marrystatus"
                                  value="1"
                                  label="โสด"
                                  checked={marrystatus == "1" ? true : false}
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="marrystatus2"
                                  name="marrystatus"
                                  value="2"
                                  label="สมรส จดทะเบียน"
                                  checked={marrystatus == "2" ? true : false}
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="marrystatus3"
                                  name="marrystatus"
                                  value="3"
                                  label="สมรส ไม่จดทะเบียน"
                                  checked={marrystatus == "3" ? true : false}
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="marrystatus4"
                                  name="marrystatus"
                                  value="4"
                                  label="หย่าร้าง"
                                  checked={marrystatus == "4" ? true : false}
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="marrystatus5"
                                  name="marrystatus"
                                  value="5"
                                  label="หม้าย"
                                  checked={marrystatus == "5" ? true : false}
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <br />
                                <CustomInput
                                  type="radio"
                                  id="marrystatus6"
                                  name="marrystatus"
                                  value="6"
                                  label="อื่นๆ"
                                  checked={marrystatus == "6" ? true : false}
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <Newinput
                                  type={hidden_chk2}
                                  name="marrystatusother"
                                  valuet={marrystatusother}
                                  onChange={this.changeHandler}
                                  placeholder="อื่นๆ"
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="careertypeid"
                              sm={3}
                            >
                              ประกอบอาชีพ
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                onChange={this.changeHandler}
                                name="careertypeid"
                              >
                                <option value="">- กรุณาเลือก -</option>
                                <option
                                  value="1"
                                  selected={careertypeid == "1" ? true : false}
                                >
                                  ทำสวน
                                </option>
                                <option
                                  value="2"
                                  selected={careertypeid == "2" ? true : false}
                                >
                                  ทำนา
                                </option>
                                <option
                                  value="3"
                                  selected={careertypeid == "3" ? true : false}
                                >
                                  ทำไร่
                                </option>
                                <option
                                  value="4"
                                  selected={careertypeid == "4" ? true : false}
                                >
                                  อื่นๆ
                                </option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="careerinfo"
                              sm={3}
                            >
                              ระบุอาชีพ
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                name="careerinfo"
                                value={careerinfo}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="govmember"
                              sm={3}
                            >
                              เป็นสมาชิกเครือข่าย/ขึ้นบัญชีของรัฐ
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="govmember1"
                                  name="govmember"
                                  value="1"
                                  label="กองทุนหมู่บ้าน"
                                  checked={govmember == "1" ? true : false}
                                  onChange={this.handleChange3}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="govmember2"
                                  name="govmember"
                                  value="2"
                                  label="ทะเบียนคนจนผู้มีรายได้น้อย"
                                  checked={govmember == "2" ? true : false}
                                  onChange={this.handleChange3}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="govmember3"
                                  name="govmember"
                                  value="3"
                                  label="กองทุนสวัสดิการชุมชน"
                                  checked={govmember == "3" ? true : false}
                                  onChange={this.handleChange3}
                                  inline
                                />
                                <br />
                                <CustomInput
                                  type="radio"
                                  id="govmember4"
                                  name="govmember"
                                  value="4"
                                  label="อื่นๆ"
                                  checked={govmember == "4" ? true : false}
                                  onChange={this.handleChange3}
                                  inline
                                />
                                <Newinput
                                  type={hidden_chk3}
                                  name="govmemberother"
                                  value={govmemberother}
                                  onChange={this.changeHandler}
                                  placeholder="อื่นๆ"
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <h5>ข้อมูลคู่สมรส : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marryname"
                              sm={3}
                            >
                              ชื่อ-สกุล คู่สมรส
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                name="marryname"
                                value={marryname}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="marryid" sm={3}>
                              หมายเลขบัตรประจำตัวประชาชน
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                name="marryid"
                                value={marryid}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marrybirthdate"
                              sm={3}
                            >
                              วัน-เดือน-ปี เกิด
                            </Label>
                            <Col sm={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                <DatePicker 
                                  disableFuture
                                  openTo="year"
                                  format="DD/MM/yyyy"
                                  views={["year", "month", "date"]}
                                  value={marrybirthdate}
                                  onChange={(value, e) => this.handleDateChange(value, e)}
                                  yearOffset={543}
                                />
                              </MuiPickersUtilsProvider>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marrycareerid "
                              sm={3}
                            >
                              ประกอบอาชีพ
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                onChange={this.changeHandler}
                                name="marrycareerid "
                              >
                                <option value="">- กรุณาเลือก -</option>
                                <option
                                  value="1"
                                  selected={marrycareerid == "1" ? true : false}
                                >
                                  ทำสวน
                                </option>
                                <option
                                  value="2"
                                  selected={marrycareerid == "2" ? true : false}
                                >
                                  ทำนา
                                </option>
                                <option
                                  value="3"
                                  selected={marrycareerid == "3" ? true : false}
                                >
                                  ทำไร่
                                </option>
                                <option
                                  value="4"
                                  selected={marrycareerid == "4" ? true : false}
                                >
                                  อื่นๆ
                                </option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marrycareerinfo"
                              sm={3}
                            >
                              ระบุอาชีพ
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                name="marrycareerinfo"
                                value={marrycareerinfo}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marryaddress"
                              sm={3}
                            >
                              ที่อยู่อาศัยเดียวกันกับสามี/ภริยา
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="marryaddress1"
                                  name="marryaddress"
                                  value="1"
                                  label="ใช่"
                                  checked={marryaddress == "1" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="marryaddress2"
                                  name="marryaddress"
                                  value="0"
                                  label="ไม่ใช่"
                                  checked={marryaddress == "0" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marryaddressinfo"
                              sm={3}
                            >
                              บ้านเลขที่
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                name="marryaddressinfo"
                                value={marryaddressinfo}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="marryvillage"
                              sm={3}
                            >
                              หมู่บ้าน
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="marryvillage"
                                value={marryvillage}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Label className="text-right" for="marrymoo" sm={1}>
                              หมู่ที่
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="marrymoo"
                                value={marrymoo}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="marrysoi" sm={3}>
                              ซอย
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="marrysoi"
                                value={marrysoi}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Label
                              className="text-right"
                              for="marryroad"
                              sm={1}
                            >
                              ถนน
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="marryroad"
                                value={marryroad}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="subdistrict"
                              sm={3}
                            >
                              ตำบล
                            </Label>
                            <Col sm={4}>
                              <InputAddress
                                style={{
                                  height: "36px",
                                  width: "100%",
                                  border: "1px solid #ced4da",
                                }}
                                address="subdistrict"
                                type="text"
                                name="subdistrict"
                                value={subdistrict}
                                onChange={this.changeHandler}
                                onSelect={this.onSelect}
                              />
                            </Col>
                            <Label className="text-right" for="district" sm={1}>
                              อำเภอ
                            </Label>
                            <Col sm={4}>
                              <InputAddress
                                style={{
                                  height: "36px",
                                  width: "100%",
                                  border: "1px solid #ced4da",
                                }}
                                address="district"
                                type="text"
                                name="district"
                                value={district}
                                onChange={this.changeHandler}
                                onSelect={this.onSelect}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="province" sm={3}>
                              จังหวัด
                            </Label>
                            <Col sm={4}>
                              <InputAddress
                                style={{
                                  height: "36px",
                                  width: "100%",
                                  border: "1px solid #ced4da",
                                }}
                                address="province"
                                type="text"
                                name="province"
                                value={province}
                                onChange={this.changeHandler}
                                onSelect={this.onSelect}
                              />
                            </Col>
                            <Label className="text-right" for="zipcode" sm={1}>
                              รหัสไปรษณีย์
                            </Label>
                            <Col sm={4}>
                              <InputAddress
                                style={{
                                  height: "36px",
                                  width: "100%",
                                  border: "1px solid #ced4da",
                                }}
                                address="zipcode"
                                type="text"
                                name="zipcode"
                                value={zipcode}
                                onChange={this.changeHandler}
                                onSelect={this.onSelect}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="marrytel" sm={3}>
                              เบอร์โทร
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="marrytel"
                                value={marrytel}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <h5>ข้อมูลครัวเรือน : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="familystudynum"
                              sm={2}
                            >
                              สมาชิกที่กำลังศึกษา
                            </Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddMemberstudy}
                                size="sm"
                              >
                                Add
                              </Button>
                            </Col>
                          </FormGroup>
                          {this.state.memberstudys.map((memberstudy, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="memberstudy"
                                sm={2}
                              >
                                {`Study #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleMemberstudyChange(
                                          idx
                                        )}
                                        name="memberstudy_title"
                                      >
                                        <option value="1">
                                          -- คำนำหน้า --
                                        </option>
                                        <option
                                          value="เด็กชาย"
                                          selected={
                                            memberstudy.memberstudy_title ==
                                            "เด็กชาย"
                                              ? true
                                              : false
                                          }
                                        >
                                          ด.ช.
                                        </option>
                                        <option
                                          value="เด็กหญิง"
                                          selected={
                                            memberstudy.memberstudy_title ==
                                            "เด็กหญิง"
                                              ? true
                                              : false
                                          }
                                        >
                                          ด.ญ.
                                        </option>
                                        <option
                                          value="นาย"
                                          selected={
                                            memberstudy.memberstudy_title ==
                                            "นาย"
                                              ? true
                                              : false
                                          }
                                        >
                                          นาย
                                        </option>
                                        <option
                                          value="นางสาว"
                                          selected={
                                            memberstudy.memberstudy_title ==
                                            "นางสาว"
                                              ? true
                                              : false
                                          }
                                        >
                                          น.ส.
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberstudy_firstname"
                                        value={
                                          memberstudy.memberstudy_firstname
                                        }
                                        placeholder="ชื่อ"
                                        onChange={this.handleMemberstudyChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberstudy_lastname"
                                        value={memberstudy.memberstudy_lastname}
                                        placeholder="สกุล"
                                        onChange={this.handleMemberstudyChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberstudy_age"
                                        value={memberstudy.memberstudy_age}
                                        placeholder="อายุ"
                                        onChange={this.handleMemberstudyChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberstudy_education"
                                        value={
                                          memberstudy.memberstudy_education
                                        }
                                        placeholder="ระดับการศึกษา"
                                        onChange={this.handleMemberstudyChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveMemberstudy(
                                          idx
                                        )}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="familycareernum"
                              sm={2}
                            >
                              สมาชิกที่ประกอบอาชีพ
                            </Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddMembercareer}
                                size="sm"
                              >
                                Add
                              </Button>
                            </Col>
                          </FormGroup>
                          {this.state.membercareers.map((membercareer, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="membercareer"
                                sm={2}
                              >
                                {`Career #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleMembercareerChange(
                                          idx
                                        )}
                                        name="membercareer_title"
                                      >
                                        <option value="1">
                                          -- คำนำหน้า --
                                        </option>
                                        <option
                                          value="นาย"
                                          selected={
                                            membercareer.membercareer_title ==
                                            "นาย"
                                              ? true
                                              : false
                                          }
                                        >
                                          นาย
                                        </option>
                                        <option
                                          value="นาง"
                                          selected={
                                            membercareer.membercareer_title ==
                                            "นาง"
                                              ? true
                                              : false
                                          }
                                        >
                                          นาง
                                        </option>
                                        <option
                                          value="นางสาว"
                                          selected={
                                            membercareer.membercareer_title ==
                                            "นางสาว"
                                              ? true
                                              : false
                                          }
                                        >
                                          น.ส.
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membercareer_firstname"
                                        value={
                                          membercareer.membercareer_firstname
                                        }
                                        placeholder="ชื่อ"
                                        onChange={this.handleMembercareerChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membercareer_lastname"
                                        value={
                                          membercareer.membercareer_lastname
                                        }
                                        placeholder="สกุล"
                                        onChange={this.handleMembercareerChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membercareer_age"
                                        value={membercareer.membercareer_age}
                                        placeholder="อายุ"
                                        onChange={this.handleMembercareerChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membercareer_career"
                                        value={membercareer.membercareer_career}
                                        placeholder="ประกอบอาชีพ"
                                        onChange={this.handleMembercareerChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveMembercareer(
                                          idx
                                        )}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <h5>ข้อมูลด้านที่ดิน : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="landinfo"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              <Row form>
                                <Col md={6}>
                                  <FormGroup>
                                    <CustomInput
                                      type="radio"
                                      id="landinfo1"
                                      name="landinfo"
                                      value="1"
                                      label="มีที่ดินเป็นกรรมสิทธิ์ของตนเอง"
                                      checked={landinfo == "1" ? true : false}
                                      onChange={this.handleChange4}
                                      inline
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <Input
                                    type="select"
                                    onChange={this.changeHandler}
                                    name="landinfonum"
                                    disabled={landinfonum1}
                                  >
                                    <option
                                      value="1"
                                      selected={
                                        landinfonum == "1" ? true : false
                                      }
                                    >
                                      ต่ำกว่า 3 ไร่
                                    </option>
                                    <option
                                      value="2"
                                      selected={
                                        landinfonum == "2" ? true : false
                                      }
                                    >
                                      3-5 ไร่
                                    </option>
                                    <option
                                      value="3"
                                      selected={
                                        landinfonum == "3" ? true : false
                                      }
                                    >
                                      5 ไร่ขึ้นไป
                                    </option>
                                  </Input>
                                </Col>
                              </Row>
                              <Row form>
                                <Col md={6}>
                                  <FormGroup>
                                    <CustomInput
                                      type="radio"
                                      id="landinfo2"
                                      name="landinfo"
                                      value="2"
                                      label="เช่าที่ดินผู้อื่น"
                                      checked={landinfo == "2" ? true : false}
                                      onChange={this.handleChange4}
                                      inline
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <Input
                                    type="select"
                                    onChange={this.changeHandler}
                                    name="landinfonum"
                                    disabled={landinfonum2}
                                  >
                                    <option
                                      value="4"
                                      selected={
                                        landinfonum == "4" ? true : false
                                      }
                                    >
                                      ต่ำกว่า 10 ไร่
                                    </option>
                                    <option
                                      value="5"
                                      selected={
                                        landinfonum == "5" ? true : false
                                      }
                                    >
                                      10-30 ไร่
                                    </option>
                                    <option
                                      value="6"
                                      selected={
                                        landinfonum == "6" ? true : false
                                      }
                                    >
                                      30 ไร่ขึ้นไป
                                    </option>
                                  </Input>
                                </Col>
                              </Row>
                              <Row form>
                                <Col md={9}>
                                  <FormGroup>
                                    <CustomInput
                                      type="radio"
                                      id="landinfo3"
                                      name="landinfo"
                                      value="3"
                                      label="ไม่มีที่ดินเป็นของตนเอง"
                                      checked={landinfo == "3" ? true : false}
                                      onChange={this.handleChange4}
                                      inline
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col md={9}>
                                  <FormGroup>
                                    <CustomInput
                                      type="radio"
                                      id="landinfo4"
                                      name="landinfo"
                                      value="4"
                                      label="ทำประโยชน์โดยไม่ทราบสิทธิ์ในที่ดิน"
                                      checked={landinfo == "4" ? true : false}
                                      onChange={this.handleChange4}
                                      inline
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                          <h5>ข้อมูลรายได้-รายจ่าย-หนี้สิน : </h5>
                          <hr />
                          {/* ----------- Add Array ทำสวน ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddFinance1}
                                size="sm"
                              >
                                Add
                              </Button>{" "}
                              รายได้ - รายจ่าย (ทำสวน)
                            </Col>
                          </FormGroup>
                          {this.state.finances1.map((finance1, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="finance1"
                                sm={2}
                              >
                                {`Row #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance1_name"
                                        value={finance1.finance1_name}
                                        placeholder="ชื่อรายการ"
                                        onChange={this.handleFinance1Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleFinance1Change(
                                          idx
                                        )}
                                        name="finance1_financetype"
                                      >
                                        <option
                                          value="รายได้"
                                          selected={
                                            finance1.finance1_financetype ==
                                            "รายได้"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายได้
                                        </option>
                                        <option
                                          value="รายจ่าย"
                                          selected={
                                            finance1.finance1_financetype ==
                                            "รายจ่าย"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายจ่าย
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance1_pricemonth"
                                        value={finance1.finance1_pricemonth}
                                        placeholder="บาท/เดือน"
                                        onChange={this.handleFinance1Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance1_priceyear"
                                        value={finance1.finance1_priceyear}
                                        placeholder="บาท/ปี"
                                        onChange={this.handleFinance1Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveFinance1(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          {/* ----------- Add Array ทำนา ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddFinance2}
                                size="sm"
                              >
                                Add
                              </Button>{" "}
                              รายได้ - รายจ่าย (ทำนา)
                            </Col>
                          </FormGroup>
                          {this.state.finances2.map((finance2, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="finance2"
                                sm={2}
                              >
                                {`Row #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance2_name"
                                        value={finance2.finance2_name}
                                        placeholder="ชื่อรายการ"
                                        onChange={this.handleFinance2Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleFinance2Change(
                                          idx
                                        )}
                                        name="finance2_financetype"
                                      >
                                        <option
                                          value="รายได้"
                                          selected={
                                            finance2.finance2_financetype ==
                                            "รายได้"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายได้
                                        </option>
                                        <option
                                          value="รายจ่าย"
                                          selected={
                                            finance2.finance2_financetype ==
                                            "รายจ่าย"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายจ่าย
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance2_pricemonth"
                                        value={finance2.finance2_pricemonth}
                                        placeholder="บาท/เดือน"
                                        onChange={this.handleFinance2Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance2_priceyear"
                                        value={finance2.finance2_priceyear}
                                        placeholder="บาท/ปี"
                                        onChange={this.handleFinance2Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveFinance2(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          {/* ----------- Add Array ทำไร่ ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddFinance3}
                                size="sm"
                              >
                                Add
                              </Button>{" "}
                              รายได้ - รายจ่าย (ทำไร่)
                            </Col>
                          </FormGroup>
                          {this.state.finances3.map((finance3, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="finance3"
                                sm={2}
                              >
                                {`Row #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance3_name"
                                        value={finance3.finance3_name}
                                        placeholder="ชื่อรายการ"
                                        onChange={this.handleFinance3Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleFinance3Change(
                                          idx
                                        )}
                                        name="finance3_financetype"
                                      >
                                        <option
                                          value="รายได้"
                                          selected={
                                            finance3.finance3_financetype ==
                                            "รายได้"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายได้
                                        </option>
                                        <option
                                          value="รายจ่าย"
                                          selected={
                                            finance3.finance3_financetype ==
                                            "รายจ่าย"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายจ่าย
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance3_pricemonth"
                                        value={finance3.finance3_pricemonth}
                                        placeholder="บาท/เดือน"
                                        onChange={this.handleFinance3Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance3_priceyear"
                                        value={finance3.finance3_priceyear}
                                        placeholder="บาท/ปี"
                                        onChange={this.handleFinance3Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveFinance3(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          {/* ----------- Add Array อื่นๆ ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddFinance4}
                                size="sm"
                              >
                                Add
                              </Button>{" "}
                              รายได้ - รายจ่าย (อื่นๆ)
                            </Col>
                          </FormGroup>
                          {this.state.finances4.map((finance4, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="finance4"
                                sm={2}
                              >
                                {`Row #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance4_name"
                                        value={finance4.finance4_name}
                                        placeholder="ชื่อรายการ"
                                        onChange={this.handleFinance4Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleFinance4Change(
                                          idx
                                        )}
                                        name="finance4_financetype"
                                      >
                                        <option
                                          value="รายได้"
                                          selected={
                                            finance4.finance4_financetype ==
                                            "รายได้"
                                              ? true
                                              : false
                                          }
                                        >
                                          รายได้
                                        </option>
                                        <option
                                          value="รายจ่าย"
                                          selected={
                                            finance4.finance4_financetype ==
                                            "รายจ่าย"
                                              ? true
                                              : false
                                          }
                                        >
                                          ค่าใช้จ่าย
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance4_pricemonth"
                                        value={finance4.finance4_pricemonth}
                                        placeholder="บาท/เดือน"
                                        onChange={this.handleFinance4Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="finance4_priceyear"
                                        value={finance4.finance4_priceyear}
                                        placeholder="บาท/ปี"
                                        onChange={this.handleFinance4Change(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveFinance4(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          {/* ----------- Add ค่าใช้จ่ายในครัวเรือน ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>ค่าใช้จ่ายในครัวเรือน</Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="familycostutility"
                              sm={2}
                            >
                              ค่าน้ำ ค่าไฟฟ้า
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="familycostutility"
                                value={familycostutility}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Label
                              className="text-right"
                              for="familycoststudy"
                              sm={2}
                            >
                              ค่าเล่าเรียนบุตร
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="familycoststudy"
                                value={familycoststudy}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="familycostfood"
                              sm={2}
                            >
                              ค่าอาหารประจำวัน
                            </Label>
                            <Col sm={4}>
                              <Input
                                type="text"
                                name="familycostfood"
                                value={familycostfood}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Label
                              className="text-right"
                              for="familycostother"
                              sm={2}
                            >
                              อื่นๆ
                            </Label>
                            <Col sm={2}>
                              <Input
                                type="text"
                                name="familycostothertext"
                                value={familycostothertext}
                                placeholder="ชื่อหัวข้อ"
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Col sm={2}>
                              <Input
                                type="text"
                                name="familycostother"
                                value={familycostother}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          {/* ----------- Add Array หนี้สินในระบบ ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddDeptin}
                                size="sm"
                              >
                                Add
                              </Button>{" "}
                              หนี้สินในระบบ
                            </Col>
                          </FormGroup>
                          {this.state.deptins.map((deptin, idx) => (
                            <FormGroup row key={idx}>
                              <Label className="text-right" for="deptin" sm={2}>
                                {`Row #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptin_name"
                                        value={deptin.deptin_name}
                                        placeholder="ชื่อรายการ"
                                        onChange={this.handleDeptinChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptin_principle"
                                        value={deptin.deptin_principle}
                                        placeholder="เงินต้น"
                                        onChange={this.handleDeptinChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptin_interest"
                                        value={deptin.deptin_interest}
                                        placeholder="ดอกเบี้ย"
                                        onChange={this.handleDeptinChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptin_total"
                                        value={deptin.deptin_total}
                                        placeholder="รวมเป็นเงิน บาท/ปี"
                                        onChange={this.handleDeptinChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveDeptin(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="deptinpay"
                              sm={2}
                            ></Label>
                            <Col sm={10}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="deptinpay1"
                                  name="deptinpay"
                                  value="1"
                                  label="ชำระเงินต้น"
                                  checked={deptinpay == "1" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="deptinpay2"
                                  name="deptinpay"
                                  value="2"
                                  label="ชำระดอกเบี้ย"
                                  checked={deptinpay == "2" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="deptinpay3"
                                  name="deptinpay"
                                  value="3"
                                  label="ชำระเงินต้น + ดอกเบี้ย"
                                  checked={deptinpay == "3" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                                <Newinput
                                  type="text"
                                  name="deptinpayprice"
                                  value={deptinpayprice}
                                  onChange={this.changeHandler}
                                  placeholder="เป็นเงิน บาท/ปี"
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          {/* ----------- Add Array หนี้สินนอกระบบ ------------------ */}
                          <FormGroup row>
                            <Label className="text-right" sm={2}></Label>
                            <Col sm={10}>
                              <Button
                                color="info"
                                onClick={this.handleAddDeptout}
                                size="sm"
                              >
                                Add
                              </Button>{" "}
                              หนี้สินนอกระบบ
                            </Col>
                          </FormGroup>
                          {this.state.deptouts.map((deptout, idx) => (
                            <FormGroup row key={idx}>
                              <Label
                                className="text-right"
                                for="deptout"
                                sm={2}
                              >
                                {`Row #${idx + 1} `}
                              </Label>
                              <Col sm={10}>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptout_name"
                                        value={deptout.deptout_name}
                                        placeholder="ชื่อรายการ"
                                        onChange={this.handleDeptoutChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptout_price"
                                        value={deptout.deptout_price}
                                        placeholder="เงินต้น"
                                        onChange={this.handleDeptoutChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="deptout_interest"
                                        value={deptout.deptout_interest}
                                        placeholder="ดอกเบี้ย"
                                        onChange={this.handleDeptoutChange(idx)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveDeptout(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <h5>ข้อมูลทรัพย์สินอื่นๆ : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="otherassets"
                              sm={4}
                            >
                              ทรัพย์สินอื่นๆ (หากมีให้ระบุ หากไม่มีไม่ต้องระบุ)
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="otherassets"
                                value={otherassets}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <h5>
                            ข้อมูลประวัติการได้รับความช่วยเหลือจากหน่วยงานของรัฐ
                            :{" "}
                          </h5>
                          <hr />
                          <FormGroup row>
                            <Label className="text-right" for="govhelp" sm={4}>
                              จากหน่วยงาน (หากเคยให้ระบุ หากไม่เคยไม่ต้องระบุ)
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="govhelp"
                                value={govhelp}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <h5>ข้อมูลรูปแบบการช่วยเหลือ : </h5>
                          <hr />
                          <FormGroup row>
                            <Label className="" for="helpformat" sm={4}></Label>
                            <Label className="" for="helpformat" sm={8}>
                              หาก บจธ.
                              ต้องการให้ดำเนินการใช้ประโยชน์ในที่ดินร่วมกัน
                              หรือบริหารจัดการที่ดินร่วมกัน ภายใต้กฎหมาย
                              ข้อบังคับระเบียบ หรือหลักเกณฑ์ของ บจธ.
                              ในรูปแบบองค์กรชุมชน หรือ สถาบันสหกรณ์
                            </Label>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="helpformat"
                              sm={4}
                            >
                              ไม่ยินยอม (หากไม่ยินยอมให้ระบุเหตุผล
                              ถ้ายินยอมไม่ต้องระบุ)
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="helpformat"
                                value={helpformat}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <h5>ข้อมูลความต้องการด้านที่ดิน : </h5>
                          <hr />
                          <FormGroup row>
                            <Label className="text-right" for="landneed" sm={3}>
                              ความต้องการที่ดิน
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="landneed1"
                                  name="landneed"
                                  value="1"
                                  label="เข้าทำประโยชน์"
                                  checked={landneed == "1" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="landneed2"
                                  name="landneed"
                                  value="2"
                                  label="เช่า"
                                  checked={landneed == "2" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="landneed3"
                                  name="landneed"
                                  value="3"
                                  label="เช่าซื้อ"
                                  checked={landneed == "3" ? true : false}
                                  onChange={this.changeHandler}
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}

                  <Row className="text-center">
                    <Col>
                      <Button
                        type="submit"
                        color="success"
                        onMouseDown={() =>
                          this.setState({
                            submitCalled: true,
                          })
                        }
                      >
                        บันทึก
                      </Button>{" "}
                      <Button
                        type="button"
                        color="warning"
                        onClick={this.cancelItem_onClick}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                  </Row>
                </OrderArea>
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(AccountInfo);
