import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import moment from "moment";
import InputAddress from "react-thailand-address-autocomplete";
import { validateFields } from "../Validation";
import classnames from "classnames";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

class Account extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      idcard: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      idnumber: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      title: "",
      firstname: "",
      lastname: "",
      birthdate: "",
      diffYears: "",
      email_line: "",
      tel: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      fax: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      career: "",
      agriculturist: "",
      address: "",
      village: "",
      moo: "",
      soi: "",
      road: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      saveSuccess: false,
      saveFail: false,
      submitCalled: false,
      allFieldsValidated: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getNameUrl = "frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const birthdatenew = moment(
          new Date(moment.unix(response.data[0].birthdate).utc().format())
        ).format("YYYY-MM-DD");

        const diffYears = moment().diff(birthdatenew, "years");

        if(response.data[0].idnumber!=null){
          this.setState({
            idnumber: {
              value: response.data[0].idnumber,
            },
          });
        }
        if(response.data[0].tel!=null){
          this.setState({
            tel: {
              value: response.data[0].tel,
            },
          });
        }
        if(response.data[0].fax!=null){
          this.setState({
            fax: {
              value: response.data[0].fax,
            },
          });
        }

        this.setState({
          title: response.data[0].title,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          birthdate: new Date(birthdatenew),
          diffYears: diffYears,
          email_line: response.data[0].email_line,
          career: response.data[0].career,
          agriculturist: response.data[0].agriculturist,
          address: response.data[0].address,
          village: response.data[0].village,
          moo: response.data[0].moo,
          soi: response.data[0].soi,
          road: response.data[0].road,
          subdistrict: response.data[0].district,
          district: response.data[0].amphur,
          province: response.data[0].province,
          zipcode: response.data[0].zipcode,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (value, e) => {
    this.setState({ birthdate: value,
      diffYears: moment().diff(value, "years"), });
  };

  DiffyearChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      diffYears: moment().diff(e.target.value, "years"),
    });
  };

  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;

    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };

  /*
   * validates the field onBlur if sumbit button is not clicked
   * set the validateOnChange to true for that field
   * check for error
   */
  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    const { idnumber, tel, fax } = this.state;
    const idnumberError = validateFields.validateIDcard(idnumber.value);
    const telError = validateFields.validatePhone(tel.value);
    const faxError = validateFields.validateFax(fax.value);
    if ([idnumberError, telError, faxError].every((e) => e === false)) {

    const userlogin = localStorage.getItem("userlogin");

    const accountUrl = "frontendapi/updateuser/" + userlogin;
    axios
      .post(accountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ saveSuccess: true });
          this.showAllFieldsValidated();
          this.showSuccess();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          this.setState({ saveFail: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    } else {
      // update the state with errors
      this.setState((state) => ({
        idnumber: {
          ...state.idnumber,
          validateOnChange: true,
          error: idnumberError,
        },
        tel: {
          ...state.tel,
          validateOnChange: true,
          error: telError,
        },
        fax: {
          ...state.fax,
          validateOnChange: true,
          error: faxError,
        },
      }));
    }
  };

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false});
    }, 1500);
  }

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    window.history.back();
  };

  render() {
    const {
      idnumber,
      title,
      firstname,
      lastname,
      birthdate,
      diffYears,
      career,
      agriculturist,
      tel,
      fax,
      email_line,
      address,
      village,
      moo,
      soi,
      road,
      subdistrict,
      district,
      province,
      zipcode,
      saveSuccess,
      allFieldsValidated,
    } = this.state;

    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>แก้ไขโปรไฟล์</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                  {/* {this.state.data.map((user, i) => (
                    <h3 key={i}>
                      {user.id} {user.firstname} {user.lastname}
                    </h3>
                  ))} */}
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <h5>ข้อมูลผู้ใช้งาน</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="idnumber" sm={3}>
                      เลขที่บัตรประชาชน
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="idnumber"
                        placeholder="เลขที่บัตรประชาชน"
                        value={idnumber.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": idnumber.error === false },
                          { "is-invalid": idnumber.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateIDcard,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateIDcard,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">{idnumber.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="firstname" sm={3}>
                      ชื่อ-นามสกุล
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        name="title"
                        onChange={this.changeHandler}
                      >
                        <option value="">- คำนำหน้า -</option>
                        <option
                          value="นาย"
                          selected={title == "นาย" ? true : false}
                        >
                          นาย
                        </option>
                        <option
                          value="นาง"
                          selected={title == "นาง" ? true : false}
                        >
                          นาง
                        </option>
                        <option
                          value="นางสาว"
                          selected={title == "นางสาว" ? true : false}
                        >
                          นางสาว
                        </option>
                        <option
                          value="Mr."
                          selected={title == "Mr." ? true : false}
                        >
                          MR
                        </option>
                        <option
                          value="Mrs."
                          selected={title == "Mrs." ? true : false}
                        >
                          MRS
                        </option>
                        <option
                          value="Ms."
                          selected={title == "Ms." ? true : false}
                        >
                          MS
                        </option>
                        <option
                          value="Miss"
                          selected={title == "Miss" ? true : false}
                        >
                          MISS
                        </option>
                        <option
                          value="Dr."
                          selected={title == "Dr." ? true : false}
                        >
                          Dr.
                        </option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder="ชื่อ"
                        value={firstname}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="นามสกุล"
                        value={lastname}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="birthdate" sm={3}>
                      วัน เดือน ปีเกิด
                    </Label>
                    <Col sm={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                          <DatePicker 
                            disableFuture
                            openTo="year"
                            format="DD/MM/yyyy"
                            views={["year", "month", "date"]}
                            value={birthdate}
                            onChange={(value, e) => this.handleDateChange(value, e)}
                            yearOffset={543}
                          />
                        </MuiPickersUtilsProvider>
                    </Col>
                    <Label className="text-right" for="birthdate" sm={1}>
                      อายุ
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="text"
                        name="diffYears"
                        placeholder="อายุ"
                        value={diffYears}
                        onChange={this.changeHandler}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="career" sm={3}>
                      อาชีพ
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="career"
                        id="career"
                        placeholder="อาชีพ"
                        value={career}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="agriculturist" sm={3}>
                      กรณีไม่ได้เป็นเกษตรกร เคยประกอบอาชีพเกษตรกรรมหรือไม่
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: "1rem" }}>
                        <CustomInput
                          type="radio"
                          id="agriculturist1"
                          name="agriculturist"
                          label="เคย"
                          inline
                          defaultChecked={agriculturist == 1 ? true : false}
                        />
                        <CustomInput
                          type="radio"
                          id="agriculturist2"
                          name="agriculturist"
                          label="ไม่เคย"
                          inline
                          defaultChecked={agriculturist == 0 ? true : false}
                        />
                      </div>
                    </Col>
                  </FormGroup>

                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>ช่องทางการติดต่อ</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="tel" sm={3}>
                      เบอร์โทร
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="tel"
                        id="tel"
                        placeholder="เบอร์โทร"
                        value={tel.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": tel.error === false },
                          { "is-invalid": tel.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validatePhone,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validatePhone,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">{tel.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="fax" sm={3}>
                      เบอร์แฟกส์
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="fax"
                        id="fax"
                        placeholder="เบอร์แฟกส์"
                        value={fax.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": fax.error === false },
                          { "is-invalid": fax.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateFax,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateFax,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">{fax.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="email_line" sm={3}>
                      อีเมล์
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="email"
                        name="email_line"
                        id="email_line"
                        placeholder="อีเมล์"
                        value={email_line}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>ที่อยู่ปัจจุบัน</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="address" sm={3}>
                      บ้านเลขที่
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="บ้านเลขที่"
                        value={address}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="village" sm={3}>
                      หมู่บ้าน
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="village"
                        id="village"
                        placeholder="หมู่บ้าน"
                        value={village}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="moo" sm={1}>
                      หมู่ที่
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="moo"
                        id="moo"
                        placeholder="หมู่ที่"
                        value={moo}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="soi" sm={3}>
                      ซอย
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="soi"
                        id="soi"
                        placeholder="ซอย"
                        value={soi}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="road" sm={1}>
                      ถนน
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="road"
                        id="road"
                        placeholder="ถนน"
                        value={road}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="districtid" sm={3}>
                      ตำบล
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="subdistrict"
                        type="text"
                        name="subdistrict"
                        value={subdistrict}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                    <Label className="text-right" for="amphurid" sm={1}>
                      อำเภอ
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="district"
                        type="text"
                        name="district"
                        value={district}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="provinceid" sm={3}>
                      จังหวัด
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="province"
                        type="text"
                        name="province"
                        value={province}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                    <Label className="text-right" for="zipcodeid" sm={1}>
                      รหัสไปรษณีย์
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="zipcode"
                        type="text"
                        name="zipcode"
                        value={zipcode}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                  </FormGroup>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}
                  <Row className="text-center">
                    <Col>
                      <Button type="submit" color="success" onMouseDown={() =>
                            this.setState({ submitCalled: true })
                          }>
                        บันทึก
                      </Button>{" "}
                      <Button color="warning" onClick={this.cancelItem_onClick}>ยกเลิก</Button>
                    </Col>
                  </Row>
                </Form>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Account);
