import React, { Component } from "react";
import { withRouter, NavLink as RRNavLink } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import agro_img from "../assets/img/agro_outsourcing.jpg";
import { FaRegComments } from "react-icons/fa";
import axios from "axios";

const Wrapper = styled.section`
  padding: 6em;
  background: url(${agro_img}) center top no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  text-align: center;
`;

const Icondiv = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  color: #fff;
  line-height: 80px;
  border-radius: 40px;
  border: solid 1px #fff;
  font-size: 30px;
  border-width: 2px;
  text-align: center;
`;

class Consult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    const getUrl = "frontendapi/inquire_data";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        console.log("data is : " + JSON.stringify(response));
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onClickConsult = () => {
    this.props.history.push("/consulthome");
  };

  render() {
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                {this.state.data.map((inquire, i) => (
                  <div key={i}>
                    <Icondiv>
                      <FaRegComments />
                    </Icondiv>
                    <h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: inquire.name,
                        }}
                      />
                    </h3>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: inquire.detail,
                        }}
                      />
                    </p>
                    <p>
                      <Button
                        outline
                        color="light"
                        onClick={this.onClickConsult}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: inquire.button_name,
                          }}
                        />
                      </Button>
                    </p>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(Consult);
