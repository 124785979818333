import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import { validateFields } from "../Validation";
import classnames from "classnames";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

// const initialState = {
//   name: {
//     value: "",
//     validateOnChange: false,
//     error: "",
//   },
//   submitCalled: false,
//   allFieldsValidated: false,
// };

class Consult_form extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }
    this.state = {
      date: new Date().toLocaleString("en-US"),
      userid: "",
      firstname: "",
      lastname: "",
      name: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      detail: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      submitCalled: false,
      allFieldsValidated: false,
      spiner: false,
    };
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getNameUrl = "frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*
   * validates the field onBlur if sumbit button is not clicked
   * set the validateOnChange to true for that field
   * check for error
   */
  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  // changeHandler = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  //   //console.log(e.target.value);
  // };

  // submitRequest = (e) => {
  //   e.preventDefault();

  //   const getHelpUrl = "frontendapi/consult";
  //   axios
  //     .post(getHelpUrl, this.state)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data) {
  //         this.props.history.push("/consult");
  //       } else {
  //         this.props.history.push("/consultform");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  handleSubmit(evt) {
    evt.preventDefault();
    // validate all fields
    const { name, detail } = this.state;
    const nameError = validateFields.validateName(name.value);
    const detailError = validateFields.validateDetail(detail.value);
    if ([nameError, detailError].every((e) => e === false)) {
      // no errors submit the form
      //console.log("success");
      //console.log("state : " + JSON.stringify(this.state));
      const getHelpUrl = "frontendapi/consult";
      axios
        .post(getHelpUrl, this.state)
        .then((response) => {
          console.log(response);
          if (response.data) {
            // clear state and show all fields are validated
            this.setState({ name, allFieldsValidated: true, spiner: true });
            this.showAllFieldsValidated();
            setTimeout(() => {
              this.props.history.push("/consult");
            }, 1000);
          } else {
            this.props.history.push("/consultform");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // update the state with errors
      this.setState((state) => ({
        name: {
          ...state.name,
          validateOnChange: true,
          error: nameError,
        },
        detail: {
          ...state.detail,
          validateOnChange: true,
          error: detailError,
        },
      }));
    }
  }

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false, spiner: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    window.history.back();
  };

  render() {
    const {
      userid,
      date,
      name,
      detail,
      firstname,
      lastname,
      allFieldsValidated,
      spiner,
    } = this.state;

    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>
                      รายการขอความช่วยเหลือ
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <div className="card-body">
                  {allFieldsValidated && (
                    <p className="text-success text-center">
                      Success, All fields are validated
                    </p>
                  )}
                  <Form onSubmit={(evt) => this.handleSubmit(evt)}>
                    <Row>
                      <Col>
                        <h5>คำขอคำปรึกษา</h5>
                        <input type="hidden" name="userid" value={userid} />
                        <input type="hidden" name="date" value={date} />
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="name">ชื่อ</Label>
                          <Input
                            type="text"
                            name="name"
                            value={name.value}
                            placeholder="หัวข้อคำปรึกษา"
                            className={classnames(
                              "form-control",
                              { "is-valid": name.error === false },
                              { "is-invalid": name.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateName,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(validateFields.validateName, evt)
                            }
                          />
                          <div className="invalid-feedback">{name.error}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="detail">รายละเอียดการขอคำปรึกษา</Label>
                          <Input
                            type="textarea"
                            name="detail"
                            id="detail"
                            value={detail.value}
                            className={classnames(
                              "form-control",
                              { "is-valid": detail.error === false },
                              { "is-invalid": detail.error }
                            )}
                            onChange={(evt) =>
                              this.handleChange(
                                validateFields.validateDetail,
                                evt
                              )
                            }
                            onBlur={(evt) =>
                              this.handleBlur(
                                validateFields.validateDetail,
                                evt
                              )
                            }
                          />
                          <div className="invalid-feedback">{detail.error}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {spiner && (
                      <Row className="text-center">
                        <Col>
                          <Spinner color="info" />
                          <br />
                        </Col>
                      </Row>
                    )}
                    <Row className="text-center">
                      <Col>
                        <Button
                          type="submit"
                          color="success"
                          onMouseDown={() =>
                            this.setState({ submitCalled: true })
                          }
                        >
                          บันทึก
                        </Button>{" "}
                        <Button
                          type="button"
                          color="warning"
                          onClick={this.cancelItem_onClick}
                        >
                          ยกเลิก
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Consult_form);
