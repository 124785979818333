import validator from "validator";

/*
 * This class contains methods for validating fields using 'validator.js' library methods
 * The methods return error message if validation failed and false otherwise
 * You can use all supported validators and sanitizers of 'validator.js' libaray
 * See their docs here https://github.com/validatorjs/validator.js
 */

class ValidateFields {
  /*
   * A method that takes in the email
   * Validates it
   * Returns the response either error or false if there is no error
   */
  validateName(name) {
    if (validator.isEmpty(name)) {
      return "Name is required";
    }
    return false;
  }

  validateIDcard(idnumber) {
    if (validator.isEmpty(idnumber)) {
      return "ID Card is required";
    } else if (!validator.isLength(idnumber, { min:13, max: 13 })) {
      return 'ID Card should be 13 characters';
    } else if (!validator.isNumeric(idnumber)) {
      return 'ID Card should be only number';
    }
    return false;
  }

  validateDetail(detail) {
    if (validator.isEmpty(detail)) {
      return "Detail is required";
    }
    return false;
  }

  validateEmail(email) {
    if (validator.isEmpty(email)) {
      return "Email is required";
    } else if (!validator.isEmail(email)) {
      return "Invalid Email";
    }
    return false;
  }

  validatePhone(phone) {
    if (validator.isEmpty(phone)) {
      return "Phone is required";
    } else if (!validator.isMobilePhone(phone)) {
      return "Phone should be only number";
    }
    return false;
  }

  validateFax(fax) {
    if (validator.isEmpty(fax)) {
      return "Fax is required";
    } else if (!validator.isMobilePhone(fax)) {
      return "Fax should be only number";
    }
    return false;
  }

  validateFirstname(firstname) {
    if (validator.isEmpty(firstname)) {
      return "Firstname is required";
    }
    return false;
  }

  validateLastname(lastname) {
    if (validator.isEmpty(lastname)) {
      return "Lastname is required";
    }
    return false;
  }

  validateUsername(username) {
    if (validator.isEmpty(username)) {
      return "Username is required";
    }
    return false;
  }

  validatePassword(password) {
    if (validator.isEmpty(password)) {
      return "Password is required";
    }
    return false;
  }

  validateNewpassword(newpassword) {
    if (validator.isEmpty(newpassword)) {
      return "New password is required";
    }
    return false;
  }

  validateRepeatpassword(repeatpassword,newpassword) {
    if (validator.isEmpty(repeatpassword)) {
      return "Repeat password is required";
    }else if(!validator.equals(repeatpassword,newpassword)){
      return "Password is incorrect";
    }
    return false;
  }

  validateCaptcha(captcha) {
    if (validator.isEmpty(captcha)) {
      return "Captcha is required";
    }
    return false;
  }

  validateDeedno(deedno) {
    if (validator.isEmpty(deedno)) {
      return "กรุณากรอกเลขที่โฉนดที่ดินครับ";
    }
    return false;
  }

  validateNumeric(number) {
    if (!validator.isNumeric(number)) {
      return 'กรอกได้เฉพาะตัวเลขเท่านั้น';
    }
    return false;
  }

}

const validateFields = new ValidateFields();

// export the class instance, so we can import and use it anywhere
export { validateFields };
