import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  FormText,
} from "reactstrap";
import km_img from "../assets/img/two-business.jpg";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaRegListAlt, FaMinusSquare } from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class BudgetRequest extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      date: "",
      userid: "",
      firstname: "",
      lastname: "",
      budgetrequestID: "",
      title: "",
      detail: "",
      file1: null,
      filename: "",
      fileid: "",
      gethelpid: this.props.match.params.gethelpid,
      process_status: "",
      saveSuccess: false,
      saveFail: false,
      countEvaluation:"",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeFiles = this.onChangeFiles.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const daterequestnew = moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DD");

        this.setState({
          data: response.data,
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          date: daterequestnew,
        });
      })
      .catch((error) => {
        console.log(error);
      });

      //////////// Count Evaluation
      const getcountUrl = "../frontendapi/countEvaluation/" + this.state.gethelpid;
      await axios
      .get(getcountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ countEvaluation: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getUrl = "../frontendapi/getbudgetrequest/" + this.state.gethelpid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        if (response.data != "") {
          this.setState({
            budgetrequestID: response.data[0].id,
            title: response.data[0].title,
            detail: response.data[0].detail,
            file1: response.data[0].attachment,
            process_status: "edit",
          });

          //////////// get attactfile
          const getfileUrl =
            "../frontendapi/getfile/tbl_request_budget/" + response.data[0].id;
          axios
            .get(getfileUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({
                  fileid: response.data[0].id,
                  filename: response.data[0].attachfile,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  onChangeFiles(e) {
    this.setState({ [e.target.name]: e.target.files[0] });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const getURL = "../frontendapi/mgtBudgetrequest/" + this.state.gethelpid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ process_status: "edit", saveSuccess: true });
          this.showSuccess();

          const formData = new FormData();
          formData.append("file1", this.state.file1);

          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };

          const getUrl =
            "../frontendapi/uploadBudgetREquest/tbl_request_budget/" + response.data;
          axios
            .post(getUrl, formData, config)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });

          setTimeout(() => {
            window.location.reload(false);
            //this.props.history.push("/budgetrequest/" + this.state.gethelpid);
          }, 1000);
        } else {
          this.props.history.push("/budgetrequest/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleRemovefile = (fileid) => {
    //e.preventDefault();

    const getURL = "../frontendapi/removefiles/" + fileid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response) {
          window.location.reload(false);
        } else {
          this.props.history.push("/budgetrequest/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  submitremove = (fileid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Alert color="warning">
              <h3>ยืนยันการลบข้อมูล</h3>
              <p>คุณต้องการลบไฟล์นี้หรือไม่?</p>
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  this.handleRemovefile(fileid);
                  onClose();
                }}
              >
                {" "}
                Yes
              </Button>{" "}
              <Button color="warning" size="sm" onClick={onClose}>
                No
              </Button>
            </Alert>
          </div>
        );
      },
    });
  };

  render() {
    const {
      date,
      firstname,
      lastname,
      gethelpid,
      budgetrequestID,
      title,
      detail,
      saveSuccess,
      filename,
      fileid,
      countEvaluation,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                <OrderArea>
                  <Row>
                    <Col xs="5">
                      <h5>
                        <FaRegListAlt /> แบบคำขอสนับสนุนงบประมาณ
                      </h5>
                    </Col>
                    <Col xs="7" className="text-right">
                      {countEvaluation==0 ?
                      <Button
                        onClick={() => this.onEvaluation(gethelpid)}
                        color="primary"
                        style={{ marginBottom: "1rem" }}
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>:<Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                      <MenuDetails getHelpID={gethelpid} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup>
                        <ListGroupItem>
                          <h5>รายละเอียด : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              หัวข้อแผนงาน/โครงการ
                            </Label>
                            <Input
                              type="hidden"
                              name="budgetrequestID"
                              value={budgetrequestID}
                            />
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="title"
                                value={title}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              รายละเอียดการดำเนินการ
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="10"
                                name="detail"
                                value={detail}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="file1" sm={4}>
                              ไฟล์แนบแผนการดำเนินงาน/โครงการ
                            </Label>
                            <Col sm={8}>
                              {filename != "" ? (
                                <p>
                                  <a
                                    href={
                                      "http://localhost/labai-backend/assets/attachfiles/" +
                                      filename
                                    }
                                    target="_blank"
                                  >
                                    {filename}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(fileid)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file1"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                ยอมรับนามสกุลไฟล์เฉพาะ : .doc, .docx, .pdf,
                                .png, .jpg, .jpeg
                              </FormText>
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}

                  <Row className="text-center">
                    <Col>
                      <Button
                        type="submit"
                        color="success"
                        onMouseDown={() =>
                          this.setState({
                            submitCalled: true,
                          })
                        }
                      >
                        บันทึก
                      </Button>{" "}
                      <Button
                        type="button"
                        color="warning"
                        onClick={this.cancelItem_onClick}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                  </Row>
                </OrderArea>
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(BudgetRequest);
