import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Badge,
  Alert,
} from "reactstrap";
import km_img from "../assets/img/two-business.jpg";
import styled from "styled-components";
import Menu from "./Menu";
import { FaUser, FaEye, FaEllipsisH, FaTrashAlt } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const GrayLink = styled(Link)`
  color: #6c757d;
  border-radius: 50%;

  &:hover {
    background: #ffffff;
    padding: 10px 12px 10px 12px;
    color: #6c757d;
  }
`;

const OrderList = (props) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>หัวข้อคำปรึกษา</th>
          <th>วันที่ขอ</th>
          <th>สถานะ</th>
          <th>ผู้รับเรื่อง</th>
          <th>หน่วยงาน</th>
          <th>รายละเอียด</th>
          <th>ลบ</th>
        </tr>
      </thead>
      <tbody>
        {props.consultdata.map((consult, i) => (
          <tr key={i}>
            <th scope="row">{consult.consultid}</th>
            <td>{consult.consultname}</td>
            <td>
              {moment
                .unix(consult.date)
                .utc()
                .add(543, "year")
                .local()
                .format("DD/MM/YYYY")}
            </td>
            <td>
              <Badge color={props.getStatus(consult.status).rel}>
                {props.getStatus(consult.status).value}
              </Badge>
            </td>
            <td className="text-center">
              {consult.empid == "0" ? "-" : consult.name}
            </td>
            <td className="text-center">
              {consult.depid == "0" ? "-" : consult.depname}
            </td>
            <td className="text-center">
              <GrayLink to={"/consultdet/" + consult.consultid}>
                <FaEllipsisH />
              </GrayLink>
            </td>
            <td className="text-center css.icon">
              <FaTrashAlt
                onClick={() => {
                  if (window.confirm("Delete the item?")) {
                    props.onClickDelete(consult.consultid);
                  }
                }}
                style={{ cursor: "pointer" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

class Consult extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }
  }

  state = {
    data: [],
    userid: "",
    firstname: "",
    lastname: "",
    isSuccess: false,
    isFail: false,
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getNameUrl = "frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const getUrl = "frontendapi/consultall/" + this.state.userid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onClickDelete = (id) => {
    const getUrl = "frontendapi/deleteconsult/" + id;
    axios
      .post(getUrl, this.state)
      .then((response) => {
        console.log(response);
        if (response.data) {
          this.setState({ isSuccess: true });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          this.setState({ isFail: true });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onGetconsultdet = (id) => {
    this.props.history.push("/consultdet/" + id);
  };

  onClickform = () => {
    this.props.history.push("/consultform");
  };

  onClickdetail = (id) => {
    console.log(id);
    this.props.history.push("/consultdet/" + id);
  };

  getStatus = (status) => {
    let showstatus = "";
    status == "no"
      ? (showstatus = { rel: "secondary", value: "ยังไม่ตรวจสอบ" })
      : status == "in"
      ? (showstatus = {
          rel: "primary",
          value: "รับเรื่องแล้วอยู่ระหว่างการตรวจสอบ",
        })
      : status == "out"
      ? (showstatus = { rel: "success", value: "กำลังดำเนินการให้คำปรึกษา" })
      : (showstatus = { rel: "danger", value: "ปิดการให้คำปรึกษา" });

    return showstatus;
  };

  render() {
    const { userid, firstname, lastname } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Row xs="2">
                  <Col>
                    <h5>
                      <FaUser />
                      การขอคำปรึกษา
                    </h5>
                  </Col>
                  <Col className="text-right">
                    <Button onClick={this.onClickform} color="success">
                      ขอคำปรึกษา
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row className="text-center">
                  <Col>
                    {this.state.isSuccess ? (
                      <Alert color="success">
                        การดำเนินการเสร็จสมบูรณ์
                      </Alert>
                    ) : null}
                    {this.state.isFail ? (
                      <Alert color="danger">
                        ไม่สามารถดำเนินการได้
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <OrderList
                          consultdata={this.state.data}
                          onClickDelete={this.onClickDelete}
                          getStatus={this.getStatus}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Consult);
