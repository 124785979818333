import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import axios from "axios";

export default class Uploadfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      file5: null,
      file6: null,
      file7: null,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    //console.log(e.target.files.length);
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    //console.log(userid.value);

    const getUrl = "frontendapi/uploader/tbl_gethelp/70";
    const formData = new FormData();
    // formData.append("selectedFile", this.state.selectedFile);
    formData.append("file1", this.state.file1);
    formData.append("file2", this.state.file2);

    // for (let i = 0; i < this.state.files.length; i++) {
    //   formData.append("files" + [i], this.state.files[i]);
    // }
    // console.log(this.state.files.length);
    // for (const key of Object.keys(this.state.selectedFile)) {
    //   formData.append("multifile", this.state.selectedFile[key]);
    // }

    console.log(this.state.file1);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(getUrl, formData, config)
      .then((response) => {
        console.log(response);

        const strcomma = response.data.split(",");

        for (let i = 0; i < strcomma.length; i++) {
          if (strcomma[i] !== "") {
            console.log(strcomma[i] + " " + i);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { name } = this.state;
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="name">name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.changeHandler}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleFile">File</Label>
                  <Input type="file" name="file1" onChange={this.onChange} />
                  <FormText color="muted">Document1</FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleFile">File</Label>
                  <Input type="file" name="file2" onChange={this.onChange} />
                  <FormText color="muted">Document2</FormText>
                </FormGroup>
                <Button type="submit" color="success">
                  บันทึก
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
