import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaRegListAlt, FaEdit, FaSistrix } from "react-icons/fa";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "styled-components";
import axios from "axios";

const GrayLink = styled(Link)`
  color: #6c757d;

  &:hover {
    padding: 10px 12px 10px 12px;
    color: #6c757d;
  }
`;

const Listmenus = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="success" style={{ marginBottom: "1rem" }}>
        จัดการข้อมูล
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem href={"/gethelpdetail/" + props.getHelpID}>
          <FaSistrix /> ดูรายละเอียด
        </DropdownItem>
        {props.jobStatus == "" ? (
          <DropdownItem href={"/gethelpedit/" + props.getHelpID}>
            <FaEdit /> ปรับปรุงข้อมูล
          </DropdownItem>
        ) : (
          ""
        )}
        <DropdownItem divider />
        {props.listMenu.map((menu, i) =>
          menu.requestcategoryid == "1" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "2" ? (
            <DropdownItem key={i} href={"/landrequest/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "3" ? (
            <DropdownItem key={i} href={"/accountinfo/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "4" ? (
            <DropdownItem key={i} href={"/debstructure/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "5" ? (
            <DropdownItem key={i} href={"/adjustrequest/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "6" ? (
            <DropdownItem key={i} href={"/budgetrequest/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "7" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "8" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "9" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "10" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "11" ? (
            <DropdownItem
              key={i}
              href={"/gethelpaddfiles/" + menu.gethelpOperateID}
            >
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "12" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "13" ? (
            <DropdownItem key={i} href={"/gethelpdetail/" + props.getHelpID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : menu.requestcategoryid == "14" ? (
            <DropdownItem key={i} href={"/landoffer/" + menu.gethelpOperateID}>
              <FaRegListAlt /> {menu.name}
            </DropdownItem>
          ) : (
            ""
          )
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

class MenuDetails extends Component {
  constructor(props) {
    super(props);

    let isLoggedIn = true;

    const token = localStorage.getItem("token");

    if (token == null) {
      isLoggedIn = false;
    }

    this.state = {
      getHelpID: this.props.getHelpID,
      menuData: [],
      jobStatus: "",
    };
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    if (userlogin) {
      this.setState({ userlogin: userlogin });
    }
    const getUrl =
      "../frontendapi/gethelp_operate_by_gethelpid/" + this.state.getHelpID;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({
          menuData: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const getHelpUrl =
      "../frontendapi/gethelp_status_once/" + this.state.getHelpID;
    axios
      .get(getHelpUrl, this.state)
      .then((response) => {
        //console.log("response data : " + response.data.sort);

        this.setState({
          jobStatus: response.data.sort,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { getHelpID, menuData, jobStatus } = this.state;

    return (
      <Listmenus
        getHelpID={getHelpID}
        listMenu={menuData}
        jobStatus={jobStatus}
      />
    );
  }
}
export default withRouter(MenuDetails);
