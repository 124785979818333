import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Card,
  CardImg,
  CardDeck,
} from "reactstrap";
import styled from "styled-components";
import moment from "moment";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const TitleH5 = styled.h5`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
`;

class Kmdetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    const getNameUrl = "/frontendapi/kmdet/" + this.props.match.params.id;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onGethelp = () => {
    this.props.history.push("/gethelp");
  };

  getImageFromURL = (img) => {
    const getImage = "/labai-backend/assets/images/kms/" + img;
    return getImage;
  };

  render() {
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>คลังความรู้</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>คลังความรู้</BreadcrumbItem>
                    <BreadcrumbItem active>รายละเอียด</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              {this.state.data.map((km, i) => (
                <OrderArea key={i}>
                  <Row xs="1" sm="2" md="2">
                    <Col xs="3">
                      <CardDeck>
                        <Card inverse>
                          <CardImg
                            width="100%"
                            src={this.getImageFromURL(km.picture)}
                            alt="Card image cap"
                          />
                        </Card>
                      </CardDeck>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <TitleH5>{km.name}</TitleH5>
                      <p style={{ fontSize: "12px", color: "#999999" }}>
                        วันที่สร้าง :
                        {moment
                          .unix(km.date)
                          .utc()
                          .add(543, "year")
                          .local()
                          .format("DD/MM/YYYY")}
                        <br />
                        ผู้สร้าง : สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)
                      </p>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      dangerouslySetInnerHTML={{
                        __html: km.detail,
                      }}
                    />
                  </Row>
                </OrderArea>
              ))}
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Kmdetail);
