import React, { useState, Component } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  Button,
  CardBody,
  Card,
} from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const TitleH1 = styled.h1`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const TitleDetail = styled.p`
  padding-bottom: 1rem;
`;

const NewButton = styled(Button)`
  width: 100%;
`;

const Faqdata = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("Closed");

  const toggle = (id) => {
    if (!props.collapse || id !== props.collapse) {
      props.stateUpdatingCallback(id);
    } else if (props.collapse === id) {
      props.stateUpdatingCallback(false);
    }

    console.log("collaps : " + props.collapse);
  };

  return (
    <div>
      {props.data.map((faq, i) => (
        <div key={i}>
          <NewButton
            color="info"
            className="text-left"
            onClick={() => {
              toggle(faq.id);
            }}
            style={{ marginBottom: "1rem" }}
          >
            {faq.name}
          </NewButton>
          <Collapse isOpen={props.collapse === faq.id}>
            <Card>
              <CardBody className="text-left">
                <div
                  dangerouslySetInnerHTML={{
                    __html: faq.detail,
                  }}
                />
              </CardBody>
            </Card>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      datafaq: [],
      collapse: null,
    };
  }

  async componentDidMount() {
    const getUrl = "frontendapi/faq";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

      const getfaqUrl = "frontendapi/services/faq";
      await axios
        .get(getfaqUrl, this.state)
        .then((response) => {
          this.setState({ datafaq: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  render() {
    const { data, name, collapse } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>คำถามที่ถามบ่อย</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col>
                <Breadcrumb className="float-right">
                  <BreadcrumbItem>
                    <a href="#">หน้าหลัก</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>บริการของเรา</BreadcrumbItem>
                  <BreadcrumbItem active>คำถามที่ถามบ่อย</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container>
            
            <Row>
              <Col>
              {this.state.datafaq.map((service, i) => (
                <div key={i}>
                <TitleH1 className="text-center"><div
                      dangerouslySetInnerHTML={{
                        __html: service.name,
                      }}
                    /></TitleH1>
                <TitleDetail>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: service.detail,
                      }}
                    />
                </TitleDetail>
                </div>
                ))}
              </Col>
            </Row>
            <Row className="text-center">
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <Faqdata
                  data={data}
                  collapse={collapse}
                  stateUpdatingCallback={(val) => {
                    this.setState({ collapse: val });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
