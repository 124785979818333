import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaRegListAlt } from "react-icons/fa";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class AdjustRequest extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      date: "",
      userid: "",
      firstname: "",
      lastname: "",
      startperiod: "",
      endperiod: "",
      payment: "",
      periodtype: "",
      adjusts: [
        {
          startperiod: "",
          endperiod: "",
          payment: "",
          periodtype: "",
        },
      ],
      adjustrequestID: "",
      loanno: "",
      loandate: "",
      loandatecurrent: "",
      principle: "",
      interest: "",
      explain: "",
      requestpay: "",
      startpay: "",
      endpay: "",
      totalpay: "",
      jointloan: "",
      guarantorname: "",
      guarantoridcard: "",
      guarantoraddress: "",
      countEvaluation: "",
      gethelpid: this.props.match.params.gethelpid,
      process_status: "",
      saveSuccess: false,
      saveFail: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoandateChange = this.handleLoandateChange.bind(this);
    this.handleLoandatecurrentChange = this.handleLoandatecurrentChange.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const daterequestnew = moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DD");

        this.setState({
          data: response.data,
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          loandate: daterequestnew,
          loandatecurrent: daterequestnew,
        });
      })
      .catch((error) => {
        console.log(error);
      });

      //////////// Count Evaluation
      const getcountUrl = "../frontendapi/countEvaluation/" + this.state.gethelpid;
      await axios
      .get(getcountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ countEvaluation: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getUrl = "../frontendapi/getAdjustrequest/" + this.state.gethelpid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        if (response) {
          const loandate = moment(
            new Date(moment.unix(response.data[0].loandate).utc().format())
          ).format("YYYY-MM-DD");

          const loandatecurrent = moment(
            new Date(
              moment.unix(response.data[0].loandatecurrent).utc().format()
            )
          ).format("YYYY-MM-DD");

          this.setState({
            userid: response.data[0].userid,
            adjustrequestID: response.data[0].id,
            loanno: response.data[0].loanno,
            loandate: loandate,
            loandatecurrent: loandatecurrent,
            principle: response.data[0].principle,
            interest: response.data[0].interest,
            explain: response.data[0].explain,
            requestpay: response.data[0].requestpay,
            startpay: response.data[0].startpay,
            endpay: response.data[0].endpay,
            totalpay: response.data[0].totalpay,
            jointloan: response.data[0].jointloan,
            guarantorname: response.data[0].guarantorname,
            guarantoridcard: response.data[0].guarantoridcard,
            guarantoraddress: response.data[0].guarantoraddress,
            process_status: "edit",
          });

          //////////// get Detail
          const getadjustUrl =
            "../frontendapi/getAdjustrequest_detail/" + response.data[0].id;
          axios
            .get(getadjustUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ adjusts: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLoandateChange = (value, e) => {
    this.setState({ loandate: value, });
  };
  handleLoandatecurrentChange = (value, e) => {
    this.setState({ loandatecurrent: value, });
  };

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const getURL = "../frontendapi/mgtAdjustrequest/" + this.state.gethelpid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ process_status: "edit", saveSuccess: true });
          this.showSuccess();
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        } else {
          this.props.history.push("/adjustrequest/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  //////////////// Add Adjust /////////

  handleAdjustNameChange = (idx) => (evt) => {
    const newAdjusts = this.state.adjusts.map((adjust, sidx) => {
      if (idx !== sidx) return adjust;
      return { ...adjust, [evt.target.name]: evt.target.value };
    });

    this.setState({ adjusts: newAdjusts });
  };

  handleAddAdjust = () => {
    this.setState({
      adjusts: this.state.adjusts.concat([
        {
          startperiod: "",
          endperiod: "",
          payment: "",
          periodtype: "",
        },
      ]),
    });
  };

  handleRemoveAdjust = (idx) => () => {
    this.setState({
      adjusts: this.state.adjusts.filter((s, sidx) => idx !== sidx),
    });
  };

  render() {
    const {
      date,
      firstname,
      lastname,
      gethelpid,
      adjustrequestID,
      loanno,
      loandate,
      loandatecurrent,
      principle,
      interest,
      explain,
      requestpay,
      startpay,
      endpay,
      totalpay,
      countEvaluation,
      guarantorname,
      guarantoridcard,
      guarantoraddress,
      saveSuccess,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                <OrderArea>
                  <Row>
                    <Col xs="5">
                      <h5>
                        <FaRegListAlt /> แบบคำขอปรับงวดการชำระหนี้
                      </h5>
                    </Col>
                    <Col xs="7" className="text-right">
                    {countEvaluation==0?
                      <Button
                        onClick={() => this.onEvaluation(gethelpid)}
                        color="primary"
                        style={{ marginBottom: "1rem" }}
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>:<Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                      <MenuDetails getHelpID={gethelpid} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup>
                        <ListGroupItem>
                          <h5>ข้อมูลสัญญาเงินกู้ : </h5>
                          <hr />
                          <FormGroup row>
                            <Label className="text-right" for="loanno" sm={4}>
                              เลขที่สัญญาเงินกู้
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="hidden"
                                name="adjustrequestID"
                                value={adjustrequestID}
                              />
                              <Input
                                type="text"
                                name="loanno"
                                value={loanno}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="loandate" sm={4}>
                              ลงวันที่
                            </Label>
                            <Col sm={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                <DatePicker 
                                  format="DD/MM/yyyy"
                                  value={loandate}
                                  onChange={(value, e) => this.handleLoandateChange(value, e)}
                                  yearOffset={543}
                                  animateYearScrolling
                                />
                              </MuiPickersUtilsProvider>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="loandatecurrent"
                              sm={4}
                            >
                              วันที่ปัจจุบัน
                            </Label>
                            <Col sm={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                <DatePicker 
                                  format="DD/MM/yyyy"
                                  value={loandatecurrent}
                                  onChange={(value, e) => this.handleLoandatecurrentChange(value, e)}
                                  yearOffset={543}
                                  animateYearScrolling
                                />
                              </MuiPickersUtilsProvider>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="principle"
                              sm={4}
                            >
                              เงินต้น
                            </Label>
                            <Col sm={3}>
                              <Input
                                type="text"
                                name="principle"
                                value={principle}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="interest" sm={4}>
                              ดอกเบี้ย
                            </Label>
                            <Col sm={3}>
                              <Input
                                type="text"
                                name="interest"
                                value={interest}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <h5>ข้อมูลการปรับงวดการชำระหนี้ : </h5>
                          <hr />
                          <FormGroup row>
                            <Label className="text-right" for="explain" sm={4}>
                              ข้าพเจ้าประสบปัญหาในการชำระหนี้ เนื่องจาก
                              (คำอธิบาย)
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="5"
                                name="explain"
                                value={explain}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requestpay"
                              sm={4}
                            >
                              ขอชำระหนี้เป็นงวดราย
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="requestpay"
                                value={requestpay}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="startpay" sm={4}>
                              ตั้งแต่เดือน
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="startpay"
                                value={startpay}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="endpay" sm={4}>
                              ถึงเดือน
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="endpay"
                                value={endpay}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="text-right" for="totalpay" sm={4}>
                              รวมเป็น/งวด
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="totalpay"
                                value={totalpay}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <h5>มีรายละเอียด ดังนี้ : </h5>
                          <hr />
                          <FormGroup row>
                            <Label className="text-right" sm={3}></Label>
                            <Col sm={9}>
                              <Button
                                color="info"
                                onClick={this.handleAddAdjust}
                                size="sm"
                              >
                                Add
                              </Button>
                            </Col>
                          </FormGroup>
                          {this.state.adjusts.map((adjust, idx) => (
                            <FormGroup row key={idx}>
                              <Label className="text-right" for="adjust" sm={3}>
                                {`Period #${idx + 1} `}
                              </Label>
                              <Col sm={9}>
                                <Row form>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="startperiod"
                                        value={adjust.startperiod}
                                        placeholder="ตั้งแต่งวดที่"
                                        onChange={this.handleAdjustNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="endperiod"
                                        value={adjust.endperiod}
                                        placeholder="ถึงงวดที่"
                                        onChange={this.handleAdjustNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        onChange={this.handleAdjustNameChange(
                                          idx
                                        )}
                                        name="periodtype"
                                      >
                                        <option
                                          value="1"
                                          selected={
                                            adjust.periodtype == "1"
                                              ? true
                                              : false
                                          }
                                        >
                                          ชำระเงินต้น พร้อมดอกเบี้ยถึงวันสิ้นงวด
                                        </option>
                                        <option
                                          value="2"
                                          selected={
                                            adjust.periodtype == "2"
                                              ? true
                                              : false
                                          }
                                        >
                                          ชำระเงินต้น พร้อมดอกเบี้ย
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="payment"
                                        value={adjust.payment}
                                        placeholder="งวดละ"
                                        onChange={this.handleAdjustNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveAdjust(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <h5>ข้อมูลผู้คำประกัน : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="guarantorname"
                              sm={4}
                            >
                              ชื่อผู้ค้ำประกัน
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="guarantorname"
                                value={guarantorname}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="guarantoridcard"
                              sm={4}
                            >
                              เลขบัตรประชาชน
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="guarantoridcard"
                                value={guarantoridcard}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="guarantoraddress"
                              sm={4}
                            >
                              ที่อยู่ผู้ค้ำประกัน
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="5"
                                name="guarantoraddress"
                                value={guarantoraddress}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}

                  <Row className="text-center">
                    <Col>
                      <Button
                        type="submit"
                        color="success"
                        onMouseDown={() =>
                          this.setState({
                            submitCalled: true,
                          })
                        }
                      >
                        บันทึก
                      </Button>{" "}
                      <Button
                        type="button"
                        color="warning"
                        onClick={this.cancelItem_onClick}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                  </Row>
                </OrderArea>
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(AdjustRequest);
