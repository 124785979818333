import React, { Component } from "react";
import Apply from "./Apply";
import Consult from "./Consult";
import Km from "./Km";
import Links from "./Links";
import { withRouter } from "react-router-dom";

class Main extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/dashboard");
    }
  }
  render() {
    return (
      <div>
        <Apply />
        <Consult />
        <Km />
        <Links />
      </div>
    );
  }
}

export default withRouter(Main);
