import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaRegListAlt } from "react-icons/fa";
import moment from "moment";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class DebStructure extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      date: "",
      userid: "",
      firstname: "",
      lastname: "",
      loanno: "",
      loanprice: "",
      jointloan: "",
      jointaddress: "",
      explain: "",
      modify: "",
      gethelpid: this.props.match.params.gethelpid,
      process_status: "",
      saveSuccess: false,
      saveFail: false,
      countEvaluation:"",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const daterequestnew = moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DD");

        this.setState({
          data: response.data,
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          date: daterequestnew,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //////////// Count Evaluation
    const getcountUrl = "../frontendapi/countEvaluation/" + this.state.gethelpid;
    await axios
    .get(getcountUrl, this.state)
    .then((response) => {
      if (response.data) {
        this.setState({ countEvaluation: response.data });
      }
    })
    .catch((error) => {
      console.log(error);
    });

    const getUrl = "../frontendapi/getDebtstructure/" + this.state.gethelpid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        if (response) {
          const daterequest = moment(
            new Date(moment.unix(response.data[0].creditdate).utc().format())
          ).format("YYYY-MM-DD");

          this.setState({
            userid: response.data[0].userid,
            date: daterequest,
            loanno: response.data[0].loanno,
            loanprice: response.data[0].loanprice,
            jointloan: response.data[0].jointloan,
            jointaddress: response.data[0].jointaddress,
            explain: response.data[0].explain,
            modify: response.data[0].modify,
            loanprice: response.data[0].loanprice,
            process_status: "edit",
          });

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log(e.target.value);
  };

  handleDateChange = (value, e) => {
    this.setState({ date: value, });
  };

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const getURL = "../frontendapi/mgtDebtstructure/" + this.state.gethelpid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ process_status: "edit", saveSuccess: true });
          this.showSuccess();
          setTimeout(() => {
            this.props.history.push("/debstructure/" + this.state.gethelpid);
          }, 1000);
        } else {
          this.props.history.push("/debstructure/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  render() {
    const {
      date,
      firstname,
      lastname,
      gethelpid,
      loanno,
      loanprice,
      jointloan,
      jointaddress,
      explain,
      modify,
      saveSuccess,
      countEvaluation,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                <OrderArea>
                  <Row>
                    <Col xs="5">
                      <h5>
                        <FaRegListAlt />{" "}
                        แบบให้ความยินยอมในการปรับปรุงโครงสร้างหนี้
                      </h5>
                    </Col>
                    <Col xs="7" className="text-right">
                      {countEvaluation==0 ?
                      <Button
                        onClick={() => this.onEvaluation(gethelpid)}
                        color="primary"
                        style={{ marginBottom: "1rem" }}
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>:<Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                      <MenuDetails getHelpID={gethelpid} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup>
                        <ListGroupItem>
                          <h5>ข้อมูลผู้ยื่นคำร้อง : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="birthdate"
                              sm={4}
                            >
                              วันที่ยื่นคำขอ
                            </Label>
                            <Col sm={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                <DatePicker 
                                  format="DD/MM/yyyy"
                                  value={date}
                                  onChange={(value, e) => this.handleDateChange(value, e)}
                                  yearOffset={543}
                                  animateYearScrolling
                                />
                              </MuiPickersUtilsProvider>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              เลขที่สัญญาเงินกู้
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                name="loanno"
                                value={loanno}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              วงเงินกู้
                            </Label>
                            <Col sm={3}>
                              <Input
                                type="text"
                                name="loanprice"
                                value={loanprice}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              ผู้ร่วมกู้
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="5"
                                name="jointloan"
                                value={jointloan}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              ภูมิลำเนาอยู่บ้านเลขที่
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="5"
                                name="jointaddress"
                                value={jointaddress}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              เนื่องจาก ข้าพเจ้าประสบปัญหา (คำอธิบาย)
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="5"
                                name="explain"
                                value={explain}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="requesttype"
                              sm={4}
                            >
                              จึงส่งผลให้ไม่สามารถที่จะชำระหนี้เงินกู้ให้ บจธ.
                              ได้ตามเงื่อนไขที่ตกลงไว้ตามสัญญา ดังนั้น
                              ข้าพเจ้าในฐานะผู้กู้ ผู้กู้ร่วม ผู้ค้ำประกัน
                              และผู้จำนอง จึงมีความประสงค์ขอให้ บจธ.
                              ดำเนินการปรับปรุงโครงสร้างหนี้ของข้าพเจ้าโดยขอปรับการชำระคืนดังนี้
                              (รายละเอียดการขอปรับปรุง)
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="textarea"
                                rows="7"
                                name="modify"
                                value={modify}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}

                  <Row className="text-center">
                    <Col>
                      <Button
                        type="submit"
                        color="success"
                        onMouseDown={() =>
                          this.setState({
                            submitCalled: true,
                          })
                        }
                      >
                        บันทึก
                      </Button>{" "}
                      <Button
                        type="button"
                        color="warning"
                        onClick={this.cancelItem_onClick}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                  </Row>
                </OrderArea>
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(DebStructure);
