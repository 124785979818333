import React, { Component } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import km_img from "../assets/img/two-business.jpg";
import styled from "styled-components";
import Menu from "./Menu";
import { FaUser, FaMapMarkerAlt, FaRocketchat } from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class Evalution extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.handleChange = this.handleChange.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
  }

  state = {
    data: [],
    dataeval: [],
    eval: [],
    date: new Date().toLocaleString("en-US"),
    userid: "",
    firstname: "",
    lastname: "",
    gethelpid: this.props.match.params.gethelpid,
    saveSuccess: false,
    saveFail: false,
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          data: response.data,
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    console.log("gethelpid = " + this.state.gethelpid);

    const getUrl =
      "../frontendapi/getevaluation/" + this.props.match.params.gethelpid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ dataeval: response.data });
          //console.log("res data : " + JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  submitRequest = (e) => {
    e.preventDefault();

    const getUrl = "../frontendapi/addevaluation";

    axios
      .post(getUrl, this.state)
      .then((response) => {
        console.log(response);
        if (response.data) {
          console.log(response.data);

          this.setState({ saveSuccess: true });
          // this.showSuccess();

          // setTimeout(() => {
          //   this.props.history.push("/dashboard");
          // }, 1500);
          
        } else {
          this.props.history.push("/gethelp");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  removeItem(e) {
    var array = this.state.eval;
    var index = array.indexOf(e.target.value); // Let's say it's Bob.
    delete array[index];
  }

  handleChange = (e) => {
    this.setState(
      {
        eval: [{ [e.target.name]: e.target.value }, ...this.state.eval],
      },
      () => {
        console.log(this.state.eval);
      }
    );
  };

  cancelItem_onClick = () => {
    window.history.back();
  };

  render() {
    const { userid, date, firstname, lastname, gethelpid, saveSuccess } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.submitRequest}>
                <OrderArea>
                  {this.state.dataeval.map((evalset, i) => (
                    <Row key={i}>
                      <Col>
                        {i % 2 == 0 ? (
                          <Row>
                            <Col xs="12">
                              <h5>แบบประเมินความพึงพอใจ {evalset.name}</h5>
                              <input
                                type="hidden"
                                name="userid"
                                value={userid}
                              />
                              <input type="hidden" name="date" value={date} />
                              <input
                                type="hidden"
                                name="gethelpid"
                                value={gethelpid}
                              />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {i % 2 != 0 ? (
                          <Row>
                            <Col>
                              <ListGroup>
                                <ListGroupItem>
                                  {this.state.dataeval[i].map(
                                    (evalcate, ii) => (
                                      <FormGroup row key={ii}>
                                        <Col sm={12}>
                                          {ii % 2 == 0 ? (
                                            <h5>{evalcate.name} : </h5>
                                          ) : (
                                            ""
                                          )}
                                          {ii % 2 != 0 ? (
                                            <Table>
                                              <thead>
                                                <tr>
                                                  <th width="50%">หัวข้อ</th>
                                                  <th width="10%">มากที่สุด</th>
                                                  <th width="10%">มาก</th>
                                                  <th width="10%">ปานกลาง</th>
                                                  <th width="10%">น้อย</th>
                                                  <th width="10%">
                                                    น้อยที่สุด
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.dataeval[i][ii].map(
                                                  (evalchild, iii) => (
                                                    <tr key={iii}>
                                                      <th scope="row">
                                                        {evalchild.name}
                                                      </th>
                                                      <td>
                                                        <FormGroup check>
                                                          <Input
                                                            type="radio"
                                                            name={evalchild.id}
                                                            value="5"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </FormGroup>
                                                      </td>
                                                      <td>
                                                        <FormGroup check>
                                                          <Input
                                                            type="radio"
                                                            name={evalchild.id}
                                                            value="4"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </FormGroup>
                                                      </td>
                                                      <td>
                                                        <FormGroup check>
                                                          <Input
                                                            type="radio"
                                                            name={evalchild.id}
                                                            value="3"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </FormGroup>
                                                      </td>
                                                      <td>
                                                        <FormGroup check>
                                                          <Input
                                                            type="radio"
                                                            name={evalchild.id}
                                                            value="2"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </FormGroup>
                                                      </td>
                                                      <td>
                                                        <FormGroup check>
                                                          <Input
                                                            type="radio"
                                                            name={evalchild.id}
                                                            value="1"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </FormGroup>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </Table>
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                      </FormGroup>
                                    )
                                  )}
                                </ListGroupItem>
                              </ListGroup>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col>
                    {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}
                      <Button
                        type="submit"
                        color="success"
                        onMouseDown={() =>
                          this.setState({
                            submitCalled: true,
                          })
                        }
                      >
                        บันทึก
                      </Button>{" "}
                      <Button
                        type="button"
                        color="warning"
                        onClick={this.cancelItem_onClick}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                  </Row>
                </OrderArea>
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Evalution);
