import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  ListGroup,
  ListGroupItem,
  Form, FormGroup, Label, Input
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";
import Kmshowgroup from "./Kmgroup";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

class Km extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    this.state = {
      data: [],
      datagroup: [],
      dataall: [],
      groupID: "",
      showgroup: <Kmshowgroup />,
      keySearch: "",
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  async componentDidMount() {
    const getUrl = "frontendapi/kmgroup";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ datagroup: response.data });

        //this.state.datagroup.map((group) => console.log(group.name + " "));
      })
      .catch((error) => {
        console.log(error);
      });

    const getsubUrl = "frontendapi/km";
    await axios
      .get(getsubUrl, this.state)
      .then((response2) => {
        this.setState({ data: response2.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onClickdetail = (id) => {
    this.props.history.push("/kmdetail/" + id);
  };

  onClickGroup = (id) => {
    console.log(id);
  };

  getImageFromURL = (img) => {
    const getImage = "/labai-backend/assets/images/kms/" + img;
    return getImage;
  };

  showLimitContent = (val, limit) => {
    const pos1 = val.replace("<p>", "");
    const pos2 = pos1.replace("</p>", "");
    const pos3 = pos2.replace("<br />", "");

    const txt = pos3.length;
    let subtxt = "";

    txt > limit ? (subtxt = val.substr(0, limit) + "...") : (subtxt = val);

    return subtxt;
  };

  getKmGroup = (groupID = "") => {
    this.setState({
      showgroup: <Kmshowgroup groupID={groupID}/>,
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  searchKm = () => {
    this.setState({
      showgroup: <Kmshowgroup keySearch={this.state.keySearch}/>,
    });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    if(this.state.searchcode!=""){
      this.loadDataFromServer(this.state.searchcode);
    }else{
      this.loadDataFromServer(0);
    }
    

  }

  render() {
    const { keySearch, } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>คลังความรู้</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col>
                <Breadcrumb className="float-right">
                  <BreadcrumbItem>
                    <a href="#">หน้าหลัก</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>KM</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container>
            <Row>
              <Col sm="3">
                  <Form inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Input type="text" name="keySearch" value={keySearch} placeholder="ค้นหาคลังความรู้" onChange={this.changeHandler} bsSize="sm" />
                    </FormGroup>
                    <Button onClick={() => this.searchKm()} type="button" color="info" size="sm"><FaSearch /></Button>
                  </Form>
                  <br />
                <ListGroup>
                  <ListGroupItem
                    style={{ background: "#069370", border: "#069370" }}
                    active
                    disabled
                    tag="button"
                    action
                  >
                    หมวดหมู่คลังความรู้
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    action
                    onClick={() => this.getKmGroup()}
                  >
                    หมวดหมู่ทั้งหมด
                  </ListGroupItem>
                  {this.state.datagroup.map((kmgroup, i) => (
                    <ListGroupItem
                      key={i}
                      tag="button"
                      action
                      onClick={() => this.getKmGroup(kmgroup.id)}
                    >
                      {kmgroup.name}
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <br />
              </Col>
              <Col sm="9">{this.state.showgroup}</Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
export default withRouter(Km);
