import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Input,
  Alert,
  Label,
} from "reactstrap";
import styled from "styled-components";
import { validateFields } from "../Validation";
import classnames from "classnames";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const txterr = styled.section`
  color: red;
`;

const TitleH4 = styled.h4`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const WorkArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;


class Changepassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromName: "สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
      toName: "",
      userName: "",
      password: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      newpassword: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      repeatpassword: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      submitCalled: false,
      saveSuccess: false,
      saveFail: false,
      repeatFail: false,
    };
  }

  

  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));

    console.log(this.state.newpassword.value);
  }

  handlerepeatChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    const newpass = this.state.newpassword.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal,newpass) : "",
      },
    }));

    // if(fieldVal != newpass){
    //     this.setState({
    //         repeatFail: true, repeatpassword:{error: true,},
    //       });

    // }else{
    //     this.setState({
    //         repeatFail: false, repeatpassword:{error: "",},
    //       });
    // }

  }

  onCreatAccount = () => {
    this.props.history.push("/");
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const userlogin = localStorage.getItem("userlogin");

    const { password, newpassword, repeatpassword } = this.state;
    const passwordError = validateFields.validatePassword(password.value);
    const newpasswordError = validateFields.validateNewpassword(newpassword.value);
    const repeatpasswordError = validateFields.validateRepeatpassword(repeatpassword.value,this.state.newpassword.value);
    if ([passwordError, newpasswordError, repeatpasswordError].every((e) => e === false)) {

      const getNameUrl = "frontendapi/changepassword/" + userlogin;
      axios
        .post(getNameUrl, this.state)
        .then((response) => {
          console.log("restponse : " + response.data);
          if (response.data == '1') {
            this.setState({
                saveSuccess: true,
              });
            this.showSuccess();

            setTimeout(() => {
                this.props.history.push("/");
              }, 2000);

          } else {

            this.setState({
                saveFail: true,
              });

            this.showFails();

            setTimeout(() => {
              this.props.history.push("/changepassword");
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // update the state with errors
      this.setState((state) => ({
        password: {
          ...state.password,
          validateOnChange: true,
          error: passwordError,
        },
        newpassword: {
            ...state.newpassword,
            validateOnChange: true,
            error: newpasswordError,
        },
        repeatpassword: {
        ...state.repeatpassword,
        validateOnChange: true,
        error: repeatpasswordError,
        },
      }));
    }
  };

  showFails() {
    setTimeout(() => {
      this.setState({ saveFail: false, password:{value: ""}, });
    }, 2000);
  }

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 2000);
  }

  render() {
    const {
      password,
      newpassword,
      repeatpassword,
      saveSuccess,
      saveFail,
      repeatFail,
    } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>ข้อมูลผู้ใช้งาน</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <WorkArea>
                  <Container>
                    <Row className="text-center">
                      <Col>
                        <TitleH4>เปลี่ยนรหัสผ่าน</TitleH4>
                        <p>
                        ควรใช้รหัสผ่านที่รัดกุมซึ่งไม่ซ้ำกับรหัสผ่านที่คุณใช้ที่อื่น
                        </p>
                        <hr />
                      </Col>
                    </Row>
                    {saveFail && (
                      <Row className="text-center">
                        <Col>
                          <Alert color="danger">
                            รหัสผ่านของคุณไม่ถูกต้อง
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    {saveSuccess && (
                      <Row className="text-center">
                        <Col>
                          <Alert color="success">
                            เปลี่ยนแปลงรหัสผ่านสำเร็จ!!!
                          </Alert>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <div className="card-body">
                          <Form onSubmit={this.handleSubmit}>
                            <FormGroup row>
                            <Label for="password" sm={3}>ปัจจุบัน</Label>
                            <Col sm={9}>
                              <Input
                                type="password"
                                name="password"
                                value={password.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": password.error === false },
                                  { "is-invalid": password.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validatePassword,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validatePassword,
                                    evt
                                  )
                                }
                              />
                                
                              <div className="invalid-feedback">
                                {password.error}
                              </div>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label for="newpassword" sm={3}>ใหม่</Label>
                            <Col sm={9}>
                              <Input
                                type="password"
                                name="newpassword"
                                value={newpassword.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": newpassword.error === false },
                                  { "is-invalid": newpassword.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validateNewpassword,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validateNewpassword,
                                    evt
                                  )
                                }
                              />
                              
                              <div className="invalid-feedback">
                                {newpassword.error}
                              </div>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label for="repeatpassword" sm={3}>พิมพ์อีกครั้ง</Label>
                            <Col sm={9}>
                              <Input
                                type="password"
                                name="repeatpassword"
                                value={repeatpassword.value}
                                className={classnames(
                                  "form-control",
                                  { "is-valid": repeatpassword.error === false },
                                  { "is-invalid": repeatpassword.error }
                                )}
                                onChange={(evt) =>
                                  this.handlerepeatChange(
                                    validateFields.validateRepeatpassword,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validateRepeatpassword,
                                    evt
                                  )
                                }
                              />
                              {repeatFail && (
                                    <div style={{color: '#dc3545', fontSize: '80%'}}>
                                    Password is incorrect
                                    </div>
                                    )}
                              <div className="invalid-feedback">
                                {repeatpassword.error}
                              </div>
                              </Col>
                            </FormGroup>
                            <Row className="text-center">
                              <Col>
                                <Button type="submit" color="info">
                                  ดำเนินการ
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </WorkArea>
              </Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
export default withRouter(Changepassword);
