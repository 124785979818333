import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaMinusSquare } from "react-icons/fa";
import moment from "moment";
import InputAddress from "react-thailand-address-autocomplete";
import { validateFields } from "../Validation";
import classnames from "classnames";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const Newinput = styled.input`
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgb(206, 212, 218);
  padding-left: 10px;
  font-size: 15px;
`;

class Gethelp extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      dataRequesttype: [],
      dataRequestreason: [],
      dataLandtype: [],
      date: new Date().toLocaleString("en-US"),
      checked: false,
      checked1: false,
      checked2: false,
      checked3: false,
      userid: "",
      idnumber: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      title: "",
      firstname: "",
      lastname: "",
      birthdate: "",
      diffYears: "",
      email_line: "",
      tel: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      fax: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      career: "",
      agriculturist: "",
      address: "",
      village: "",
      moo: "",
      soi: "",
      road: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      requesttypeid: "",
      requesttypeother: "",
      requestreasonid: "",
      requestreasonother: "",
      landuse: "",
      landtype: "",
      landtypeother: "",
      rentaccept: "",
      detail: "",
      gethelpid: this.props.match.params.id,
      file1id: "",
      file2id: "",
      file3id: "",
      file4id: "",
      file5id: "",
      file6id: "",
      file7id: "",
      file1name: "",
      file2name: "",
      file3name: "",
      file4name: "",
      file5name: "",
      file6name: "",
      file7name: "",
      file1Attach: "",
      file2Attach: "",
      file3Attach: "",
      file4Attach: "",
      file5Attach: "",
      file6Attach: "",
      file7Attach: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      file5: null,
      file6: null,
      file7: null,
      saveSuccess: false,
      saveFail: false,
    };

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeFiles = this.onChangeFiles.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const token = localStorage.getItem("token");

    if (token != null) {
      const getNameUrl = "../frontendapi/users/" + userlogin;
      await axios
        .get(getNameUrl, this.state)
        .then((response) => {
          const birthdatenew = moment(
            new Date(moment.unix(response.data[0].birthdate).utc().format())
          ).format("YYYY-MM-DD");

          const diffYears = moment().diff(birthdatenew, "years");

          this.setState({
            userid: response.data[0].id,
            idnumber: {
              value: response.data[0].idnumber,
            },
            title: response.data[0].title,
            firstname: response.data[0].firstname,
            lastname: response.data[0].lastname,
            birthdate: birthdatenew,
            diffYears: diffYears,
            email_line: response.data[0].email_line,
            tel: {
              value: response.data[0].tel,
            },
            fax: {
              value: response.data[0].fax,
            },
            career: response.data[0].career,
            agriculturist: response.data[0].agriculturist,
            address: response.data[0].address,
            village: response.data[0].village,
            moo: response.data[0].moo,
            soi: response.data[0].soi,
            road: response.data[0].road,
            subdistrict: response.data[0].district,
            district: response.data[0].amphur,
            province: response.data[0].province,
            zipcode: response.data[0].zipcode,
            requesttypeid: "1",
            requestreasonid: "1",
            landtype: "1",
            landuse: "1",
            rentaccept: "1",
          });

          const gethelpUrl =
            "../frontendapi/gethelp_by_id/" + this.state.gethelpid;
          axios
            .get(gethelpUrl, this.state)
            .then((response) => {
              if (response.data) {
                this.setState({
                  requesttypeid: response.data[0].reqid,
                  requesttypeother: response.data[0].requesttypeother,
                  requestreasonid: response.data[0].requestreasonid,
                  requestreasonother: response.data[0].requestreasonother,
                  landuse: response.data[0].landuse,
                  landtype: response.data[0].landtype,
                  landtypeother: response.data[0].landtypeother,
                  rentaccept: response.data[0].rentaccept,
                  detail: response.data[0].detail,
                });

                if (response.data[0].reqid == 0) {
                  this.setState({ checked1: true });
                } else {
                  this.setState({ checked1: false, requesttypeother: "" });
                }

                if (response.data[0].requestreasonid == 0) {
                  this.setState({ checked2: true });
                } else {
                  this.setState({ checked2: false, requestreasonother: "" });
                }

                if (response.data[0].landtype == 0) {
                  this.setState({ checked3: true });
                } else {
                  this.setState({ checked3: false, landtypeother: "" });
                }

                //////////// get attactfile
                const getfileUrl =
                  "../frontendapi/getfile/tbl_gethelp/" +
                  response.data[0].gethelpid;
                axios
                  .get(getfileUrl, this.state)
                  .then((response) => {
                    //console.log("response : " + JSON.stringify(response.data));
                    if (response.data != "") {
                      for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].attachfile.split("_")[0] == "file1") {
                          let file1name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file1name_temp = response.data[i].attachfile;
                          }else{
                            file1name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file1id: response.data[i].id,
                            file1name: file1name_temp,
                            file1Attach: response.data[i].attachfile,
                          });
                        } else if (response.data[i].attachfile.split("_")[0] == "file2") {
                          let file2name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file2name_temp = response.data[i].attachfile;
                          }else{
                            file2name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file2id: response.data[i].id,
                            file2name: file2name_temp,
                            file2Attach: response.data[i].attachfile,
                          });
                        } else if (response.data[i].attachfile.split("_")[0] == "file3") {
                          let file3name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file3name_temp = response.data[i].attachfile;
                          }else{
                            file3name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file3id: response.data[i].id,
                            file3name: file3name_temp,
                            file3Attach: response.data[i].attachfile,
                          });
                        } else if (response.data[i].attachfile.split("_")[0] == "file4") {
                          let file4name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file4name_temp = response.data[i].attachfile;
                          }else{
                            file4name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file4id: response.data[i].id,
                            file4name: file4name_temp,
                            file4Attach: response.data[i].attachfile,
                          });
                        } else if (response.data[i].attachfile.split("_")[0] == "file5") {
                          let file5name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file5name_temp = response.data[i].attachfile;
                          }else{
                            file5name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file5id: response.data[i].id,
                            file5name: file5name_temp,
                            file5Attach: response.data[i].attachfile,
                          });
                        } else if (response.data[i].attachfile.split("_")[0] == "file6") {
                          let file6name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file6name_temp = response.data[i].attachfile;
                          }else{
                            file6name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file6id: response.data[i].id,
                            file6name: file6name_temp,
                            file6Attach: response.data[i].attachfile,
                          });
                        } else if (response.data[i].attachfile.split("_")[0] == "file7") {
                          let file7name_temp = "";
                          if(response.data[i].filename === "" || response.data[i].filename === null){
                            file7name_temp = response.data[i].attachfile;
                          }else{
                            file7name_temp = response.data[i].filename;
                          }
                          this.setState({
                            file7id: response.data[i].id,
                            file7name: file7name_temp,
                            file7Attach: response.data[i].attachfile,
                          });
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });

      // if (this.state.career == "เกษตรกร") {
      //   this.setState({ career: "1" });
      // } else if (this.state.career != "เกษตรกร" || this.state.career != "") {
      //   this.setState({ checked: true, career: "2" });
      // }

      const getRequesttypeUrl = "../frontendapi/requesttype";
      await axios
        .get(getRequesttypeUrl, this.state)
        .then((response) => {
          //console.log(response.data);
          this.setState({ dataRequesttype: response.data });
        })
        .catch((error) => {
          console.log(error);
        });

      const getRequestreasonUrl = "../frontendapi/requestreason";
      await axios
        .get(getRequestreasonUrl, this.state)
        .then((response) => {
          //console.log(response.data);
          this.setState({ dataRequestreason: response.data });
        })
        .catch((error) => {
          console.log(error);
        });

      const getLandtypeUrl = "../frontendapi/landtype";
      await axios
        .get(getLandtypeUrl, this.state)
        .then((response) => {
          //console.log(response.data);
          this.setState({ dataLandtype: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  DiffyearChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      diffYears: moment().diff(e.target.value, "years"),
    });
  };

  onChangeFiles(e) {
    this.setState({ [e.target.name]: e.target.files[0] });
  }

  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;
    //console.log(fullAddress.district);
    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === "isGoing" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  /*
   * validates the field onBlur if sumbit button is not clicked
   * set the validateOnChange to true for that field
   * check for error
   */
  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  handleSubmit = (evt) => {
    evt.preventDefault();


    const { idnumber, tel, fax } = this.state;
    const idnumberError = validateFields.validateIDcard(idnumber.value);
    const telError = validateFields.validatePhone(tel.value);
    const faxError = validateFields.validateFax(fax.value);
    if ([idnumberError, telError, faxError].every((e) => e === false)) {

    const getHelpUrl = "../frontendapi/EditGethelp/" + this.state.gethelpid;

    axios
      .post(getHelpUrl, this.state)
      .then((response) => {
        console.log(response);
        if (response.data) {
          console.log(response.data);

          const formData = new FormData();
          // formData.append("selectedFile", this.state.selectedFile);
          formData.append("file1", this.state.file1);
          formData.append("file2", this.state.file2);
          formData.append("file3", this.state.file3);
          formData.append("file4", this.state.file4);
          formData.append("file5", this.state.file5);
          formData.append("file6", this.state.file6);
          formData.append("file7", this.state.file7);
          formData.append("file1name", this.state.file1name);
          formData.append("file2name", this.state.file2name);
          formData.append("file3name", this.state.file3name);
          formData.append("file4name", this.state.file4name);
          formData.append("file5name", this.state.file5name);
          formData.append("file6name", this.state.file6name);
          formData.append("file7name", this.state.file7name);

          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };

          const getUrl =
            "../frontendapi/uploader/tbl_gethelp/" + this.state.gethelpid;
          axios
            .post(getUrl, formData, config)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });

          this.setState({ saveSuccess: true });
          this.showSuccess();

          setTimeout(() => {
            window.location.reload(false);
          }, 1500);

          //this.props.history.push("/dashboard");
        } else {
          this.props.history.push("/gethelp");
        }

        //console.log(response.config.data);
      })
      .catch((error) => {
        console.log(error);
      });

    } else {
      // update the state with errors
      this.setState((state) => ({
        idnumber: {
          ...state.idnumber,
          validateOnChange: true,
          error: idnumberError,
        },
        tel: {
          ...state.tel,
          validateOnChange: true,
          error: telError,
        },
        fax: {
          ...state.fax,
          validateOnChange: true,
          error: faxError,
        },
      }));
    }
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  handleChange1(e) {
    console.log(e.target.value);
    if (e.target.value == 0) {
      this.setState({ checked1: true, requesttypeid: e.target.value });
    } else {
      this.setState({ checked1: false, requesttypeid: e.target.value });
    }
  }
  handleChange2(e) {
    if (e.target.value == 0) {
      this.setState({ checked2: true, requestreasonid: e.target.value });
    } else {
      this.setState({ checked2: false, requestreasonid: e.target.value });
    }
  }
  handleChange3(e) {
    if (e.target.value == 0) {
      this.setState({ checked3: true, landtype: e.target.value });
    } else {
      this.setState({ checked3: false, landtype: e.target.value });
    }
    //this.setState({ checked3: !this.state.checked3 });
  }

  handleRemovefile = (fileid) => {
    //e.preventDefault();

    const getURL = "../frontendapi/removefiles/" + fileid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response) {
          console.log("response : "+response.data.split("_")[0]);

          if (response.data.split("_")[0] == "file1") {
            this.setState({ file1name: "" });
          } else if (response.data.split("_")[0] == "file2") {
            this.setState({ file2name: "" });
          } else if (response.data.split("_")[0] == "file3") {
            this.setState({ file3name: "" });
          } else if (response.data.split("_")[0] == "file4") {
            this.setState({ file4name: "" });
          } else if (response.data.split("_")[0] == "file5") {
            this.setState({ file5name: "" });
          } else if (response.data.split("_")[0] == "file6") {
            this.setState({ file6name: "" });
          } else if (response.data.split("_")[0] == "file7") {
            this.setState({ file7name: "" });
          }

          window.location.reload(false);
        } else {
          this.props.history.push("/gethelpedit/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitremove = (fileid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Alert color="warning">
              <h3>ยืนยันการลบข้อมูล</h3>
              <p>คุณต้องการลบไฟล์นี้หรือไม่?</p>
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  this.handleRemovefile(fileid);
                  onClose();
                }}
              >
                {" "}
                Yes
              </Button>{" "}
              <Button color="warning" size="sm" onClick={onClose}>
                No
              </Button>
            </Alert>
          </div>
        );
      },
    });
  };

  render() {
    const hidden_chk1 = this.state.checked1 ? "text" : "hidden";
    const hidden_chk2 = this.state.checked2 ? "text" : "hidden";
    const hidden_chk3 = this.state.checked3 ? "text" : "hidden";

    const {
      userid,
      date,
      idnumber,
      title,
      firstname,
      lastname,
      birthdate,
      diffYears,
      career,
      agriculturist,
      tel,
      fax,
      email_line,
      address,
      village,
      moo,
      soi,
      road,
      subdistrict,
      district,
      province,
      zipcode,
      gethelpid,
      requesttypeid,
      requesttypeother,
      requestreasonid,
      requestreasonother,
      landuse,
      landtype,
      landtypeother,
      rentaccept,
      detail,
      checked,
      file1id,
      file2id,
      file3id,
      file4id,
      file5id,
      file6id,
      file7id,
      file1name,
      file2name,
      file3name,
      file4name,
      file5name,
      file6name,
      file7name,
      file1Attach,
      file2Attach,
      file3Attach,
      file4Attach,
      file5Attach,
      file6Attach,
      file7Attach,
      saveSuccess,
    } = this.state;

    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>
                      รายการขอความช่วยเหลือ
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                  {/* {this.state.data.map((user, i) => (
                    <h3 key={i}>
                      {user.firstname} {user.lastname}
                    </h3>
                  ))} */}
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs="6">
                      <h5>
                        แก้ไขข้อมูลคำขอความช่วยเหลือ หมายเลข # {gethelpid}
                      </h5>
                      <input type="hidden" name="userid" value={userid} />
                      <input type="hidden" name="date" value={date} />
                      <hr />
                    </Col>
                    <Col xs="6" className="text-right">
                      <MenuDetails getHelpID={gethelpid} />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="idnumber" sm={3}>
                      เลขที่บัตรประชาชน
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="idnumber"
                        placeholder="เลขที่บัตรประชาชน"
                        value={idnumber.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": idnumber.error === false },
                          { "is-invalid": idnumber.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateIDcard,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateIDcard,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">{idnumber.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="title" sm={3}>
                      ชื่อ-นามสกุล
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        onChange={this.changeHandler}
                        name="title"
                      >
                        <option value="">- คำนำหน้า -</option>
                        <option
                          value="นาย"
                          selected={title == "นาย" ? true : false}
                        >
                          นาย
                        </option>
                        <option
                          value="นาง"
                          selected={title == "นาง" ? true : false}
                        >
                          นาง
                        </option>
                        <option
                          value="นางสาว"
                          selected={title == "นางสาว" ? true : false}
                        >
                          นางสาว
                        </option>
                        <option
                          value="Mr."
                          selected={title == "Mr." ? true : false}
                        >
                          MR
                        </option>
                        <option
                          value="Mrs."
                          selected={title == "Mrs." ? true : false}
                        >
                          MRS
                        </option>
                        <option
                          value="Ms."
                          selected={title == "Ms." ? true : false}
                        >
                          MS
                        </option>
                        <option
                          value="Miss"
                          selected={title == "Miss" ? true : false}
                        >
                          MISS
                        </option>
                        <option
                          value="Dr."
                          selected={title == "Dr." ? true : false}
                        >
                          Dr.
                        </option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder="ชื่อ"
                        value={firstname}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="นามสกุล"
                        value={lastname}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="birthdate" sm={3}>
                      วัน เดือน ปีเกิด
                    </Label>
                    <Col sm={3}>
                      <Input
                        type="date"
                        name="birthdate"
                        id="birthdate"
                        placeholder="วัน เดือน ปีเกิด"
                        value={birthdate}
                        onChange={this.DiffyearChange}
                      />
                    </Col>
                    <Label className="text-right" for="birthdate" sm={1}>
                      อายุ
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="text"
                        name="diffYears"
                        placeholder="อายุ"
                        value={diffYears}
                        onChange={this.changeHandler}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="career" sm={3}>
                      อาชีพ
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="career"
                        id="career"
                        placeholder="อาชีพ"
                        value={career}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="agriculturist" sm={3}>
                      กรณีไม่ได้เป็นเกษตรกร เคยประกอบอาชีพเกษตรกรรมหรือไม่
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: "1rem" }}>
                        <CustomInput
                          type="radio"
                          id="agriculturist1"
                          name="agriculturist"
                          label="เคย"
                          inline
                          defaultChecked={agriculturist == 1 ? true : false}
                        />
                        <CustomInput
                          type="radio"
                          id="agriculturist2"
                          name="agriculturist"
                          label="ไม่เคย"
                          inline
                          defaultChecked={agriculturist == 0 ? true : false}
                        />
                      </div>
                    </Col>
                  </FormGroup>

                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>ช่องทางการติดต่อ</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="tel" sm={3}>
                      เบอร์โทร
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="tel"
                        id="tel"
                        placeholder="เบอร์โทร"
                        value={tel.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": tel.error === false },
                          { "is-invalid": tel.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validatePhone,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validatePhone,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">{tel.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="fax" sm={3}>
                      เบอร์แฟกส์
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="fax"
                        id="fax"
                        placeholder="เบอร์แฟกส์"
                        value={fax.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": fax.error === false },
                          { "is-invalid": fax.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateFax,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateFax,
                            evt
                          )
                        }
                      />
                      <div className="invalid-feedback">{fax.error}</div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="email_line" sm={3}>
                      อีเมล์
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="email"
                        name="email_line"
                        id="email_line"
                        placeholder="อีเมล์"
                        value={email_line}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>ที่อยู่ปัจจุบัน</h5>
                      <hr color="primary" />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="address" sm={3}>
                      บ้านเลขที่
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="บ้านเลขที่"
                        value={address}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="village" sm={3}>
                      หมู่บ้าน
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="village"
                        id="village"
                        placeholder="หมู่บ้าน"
                        value={village}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="moo" sm={1}>
                      หมู่ที่
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="moo"
                        id="moo"
                        placeholder="หมู่ที่"
                        value={moo}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="soi" sm={3}>
                      ซอย
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="soi"
                        id="soi"
                        placeholder="ซอย"
                        value={soi}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Label className="text-right" for="road" sm={1}>
                      ถนน
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="road"
                        id="road"
                        placeholder="ถนน"
                        value={road}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="districtid" sm={3}>
                      ตำบล
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="subdistrict"
                        type="text"
                        name="subdistrict"
                        value={subdistrict}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                    <Label className="text-right" for="amphurid" sm={1}>
                      อำเภอ
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="district"
                        type="text"
                        name="district"
                        value={district}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="provinceid" sm={3}>
                      จังหวัด
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="province"
                        type="text"
                        name="province"
                        value={province}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                    <Label className="text-right" for="zipcodeid" sm={1}>
                      รหัสไปรษณีย์
                    </Label>
                    <Col sm={4}>
                      <InputAddress
                        style={{
                          height: "36px",
                          width: "100%",
                          border: "1px solid #ced4da",
                        }}
                        address="zipcode"
                        type="text"
                        name="zipcode"
                        value={zipcode}
                        onChange={this.changeHandler}
                        onSelect={this.onSelect}
                      />
                    </Col>
                  </FormGroup>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>คำขอความช่วยเหลือ</h5>
                      <hr />
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label className="text-right" for="requesttypeid" sm={3}>
                      ประเภทคำขอ
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        {this.state.dataRequesttype.map((requesttype, i) =>
                          requesttype.id != "0" ? (
                            <CustomInput
                              key={i}
                              type="radio"
                              id={"requesttypeid" + requesttype.id}
                              name="requesttypeid"
                              value={requesttype.id}
                              label={requesttype.name}
                              onChange={this.handleChange1}
                              checked={
                                requesttypeid == requesttype.id ? true : false
                              }
                              inline
                            />
                          ) : (
                            ""
                          )
                        )}
                        <CustomInput
                          type="radio"
                          id="requesttypeid0"
                          name="requesttypeid"
                          value="0"
                          label="อื่นๆ"
                          checked={requesttypeid == "0" ? true : false}
                          onChange={this.handleChange1}
                          inline
                        />
                        <Newinput
                          type={hidden_chk1}
                          name="requesttypeother"
                          value={requesttypeother}
                          onChange={this.changeHandler}
                          placeholder="อื่นๆ"
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="zipcodeid" sm={3}>
                      เหตุผลการยื่นคำขอ
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        {this.state.dataRequestreason.map((requestreason, i) =>
                          requestreason.id != "0" ? (
                            <CustomInput
                              key={i}
                              type="radio"
                              id={"requestreasonid" + requestreason.id}
                              name="requestreasonid"
                              value={requestreason.id}
                              label={requestreason.name}
                              onChange={this.handleChange2}
                              checked={
                                requestreasonid == requestreason.id
                                  ? true
                                  : false
                              }
                              inline
                            />
                          ) : (
                            ""
                          )
                        )}
                        <CustomInput
                          type="radio"
                          id="requestreasonid0"
                          name="requestreasonid"
                          value="0"
                          label="อื่นๆ"
                          checked={requestreasonid == "0" ? true : false}
                          onChange={this.handleChange2}
                          inline
                        />
                        <Newinput
                          type={hidden_chk2}
                          name="requestreasonother"
                          value={requestreasonother}
                          onChange={this.changeHandler}
                          placeholder="อื่นๆ"
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="zipcodeid" sm={3}>
                      กรณีใช้ประโยชน์ในที่ดิน
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        <CustomInput
                          type="checkbox"
                          id="landuse1"
                          name="landuse"
                          value="1"
                          label="ที่ดินเกษตรกรรม"
                          onChange={this.changeHandler}
                          checked={landuse == "1" ? true : false}
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="landuse2"
                          name="landuse"
                          value="2"
                          label="ที่อยู่อาศัย"
                          onChange={this.changeHandler}
                          checked={landuse == "2" ? true : false}
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="zipcodeid" sm={3}>
                      ประเภทเอกสารสิทธิในที่ดิน
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        {this.state.dataLandtype.map((landtypedata, i) =>
                          landtypedata.id != "0" ? (
                            <CustomInput
                              key={i}
                              type="radio"
                              id={"landtype" + landtypedata.id}
                              name="landtype"
                              value={landtypedata.id}
                              label={landtypedata.name}
                              onChange={this.handleChange3}
                              checked={
                                landtype == landtypedata.id ? true : false
                              }
                              inline
                            />
                          ) : (
                            ""
                          )
                        )}
                        <CustomInput
                          type="radio"
                          id="landtype0"
                          name="landtype"
                          value="0"
                          checked={landtype == "0" ? true : false}
                          onChange={this.handleChange3}
                          label="อื่นๆ"
                          inline
                        />
                        <Newinput
                          type={hidden_chk3}
                          name="landtypeother"
                          value={landtypeother}
                          placeholder="อื่นๆ"
                          onChange={this.changeHandler}
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="zipcodeid" sm={3}>
                      การเข้าสู่แนวทางการเช่าซื้อ
                    </Label>
                    <Col sm={9}>
                      <div style={{ padding: ".5rem" }}>
                        <CustomInput
                          type="radio"
                          id="rentaccept1"
                          name="rentaccept"
                          value="1"
                          label="ยินยอม"
                          onChange={this.changeHandler}
                          checked={rentaccept == "1" ? true : false}
                          inline
                        />
                        <CustomInput
                          type="radio"
                          id="rentaccept2"
                          name="rentaccept"
                          value="0"
                          label="ไม่ยินยอม"
                          onChange={this.changeHandler}
                          checked={rentaccept == "0" ? true : false}
                          inline
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="detail" sm={3}>
                      รายละเอียดการขอความช่วยเหลือ
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="textarea"
                        name="detail"
                        id="detail"
                        value={detail}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <h5>เอกสารประกอบการยื่นคำขอ</h5>
                      <hr />
                    </Col>
                  </Row>
                  {file1id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File1
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file1Attach
                                }
                                target="_blank"
                              >
                                {file1name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file1id)}
                              />
                              <FormText color="muted">
                                สำเนาบัตรประจำตัวประชาชน สำเนาทะเบียนบ้าน
                                ของผู้ขอความช่วยเหลือ และคู่สมรส หรือผู้กู้ร่วม
                                (กรณีขอสินเชื่อ) (ถ้ามี)
                                หรือผู้ที่ได้รับมอบหมายเป็นผู้แทนองค์กรชุมชน
                                เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร หรือสถาบันเกษตรกร
                              </FormText>
                            </Col>
                          </FormGroup>
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="address" sm={3}>
                      #File1
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file1name"
                        value={file1name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="file1" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file1"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">
                        สำเนาบัตรประจำตัวประชาชน สำเนาทะเบียนบ้าน
                        ของผู้ขอความช่วยเหลือ และคู่สมรส หรือผู้กู้ร่วม
                        (กรณีขอสินเชื่อ) (ถ้ามี)
                        หรือผู้ที่ได้รับมอบหมายเป็นผู้แทนองค์กรชุมชน
                        เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร หรือสถาบันเกษตรกร
                      </FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}
                  {file2id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File2
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file2Attach
                                }
                                target="_blank"
                              >
                                {file2name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file2id)}
                              />
                              <FormText color="muted">
                              สำเนาหลักฐานที่แสดงว่าเป็นผู้มีอำนาจกระทำการแทน
                              สำเนาหนังสือรับรองการจดทะเบียน สำเนาทะเบียนสมาชิก
                              สำเนาข้อบังคับหรือกติกาอื่นที่แสดงถึงการบริหารจัดการร่วมกันของชุมชน
                              และหลักฐานอื่นที่เกี่ยวกับองค์กรชุมชน
                              เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร หรือสถาบันเกษตรกร
                            </FormText>
                            </Col>
                          </FormGroup>
                          
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="address" sm={3}>
                      #File2
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file2name"
                        value={file2name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label className="text-right" for="file2" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file2"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">
                        สำเนาหลักฐานที่แสดงว่าเป็นผู้มีอำนาจกระทำการแทน
                        สำเนาหนังสือรับรองการจดทะเบียน สำเนาทะเบียนสมาชิก
                        สำเนาข้อบังคับหรือกติกาอื่นที่แสดงถึงการบริหารจัดการร่วมกันของชุมชน
                        และหลักฐานอื่นที่เกี่ยวกับองค์กรชุมชน
                        เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร หรือสถาบันเกษตรกร
                      </FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}
                  {file3id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File3
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file3Attach
                                }
                                target="_blank"
                              >
                                {file3name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file3id)}
                              />
                              <FormText color="muted">
                        สำเนาเอกสารสิทธิในที่ดิน เช่น โฉนดที่ดิน น.ส. 3 ก. น.ส.
                        3 เป็นต้น
                      </FormText>
                            </Col>
                          </FormGroup>
                          
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="address" sm={3}>
                      #File3
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file3name"
                        value={file3name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                  <Label className="text-right" for="file3" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file3"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">
                        สำเนาเอกสารสิทธิในที่ดิน เช่น โฉนดที่ดิน น.ส. 3 ก. น.ส.
                        3 เป็นต้น
                      </FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}
                  {file4id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File4
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file4Attach
                                }
                                target="_blank"
                              >
                                {file4name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file4id)}
                              />
                              <FormText color="muted">
                        สำเนาเอกสารหลักฐานการทำนิติกรรมสัญญากับบุคคลหรือนิติบุคคล
                        เช่น สัญญาขายฝาก สัญญาจำนอง เป็นต้น
                      </FormText>
                            </Col>
                          </FormGroup>
                          
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="file4" sm={3}>
                      #File4
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file4name"
                        value={file4name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                  <Label className="text-right" for="file4" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file4"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">
                        สำเนาเอกสารหลักฐานการทำนิติกรรมสัญญากับบุคคลหรือนิติบุคคล
                        เช่น สัญญาขายฝาก สัญญาจำนอง เป็นต้น
                      </FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}
                  {file5id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File5
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file5Attach
                                }
                                target="_blank"
                              >
                                {file5name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file5id)}
                              />
                              <FormText color="muted">
                                คำสั่งศาล คำพิพากษา หรือคำสั่งเจ้าพนักงานบังคับคดี
                                หรือสัญญาประนีประนอมยอมความ (ถ้ามี)
                              </FormText>
                            </Col>
                          </FormGroup>
                          
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="file5" sm={3}>
                      #File5
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file5name"
                        value={file5name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                  <Label className="text-right" for="file5" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file5"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">
                        คำสั่งศาล คำพิพากษา หรือคำสั่งเจ้าพนักงานบังคับคดี
                        หรือสัญญาประนีประนอมยอมความ (ถ้ามี)
                      </FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}
                  {file6id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File6
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file6Attach
                                }
                                target="_blank"
                              >
                                {file6name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file6id)}
                              />
                              <FormText color="muted">
                                เอกสารหลักฐานแสดงราคาประเมินที่ดินของทางราชการ
                              </FormText>
                            </Col>
                          </FormGroup>
                          
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="file6" sm={3}>
                      #File6
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file6name"
                        value={file6name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                  <Label className="text-right" for="file6" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file6"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">
                        เอกสารหลักฐานแสดงราคาประเมินที่ดินของทางราชการ
                      </FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}
                  {file7id != "" ? (
                        <div>
                          <FormGroup row>
                            <Label className="text-right" for="address" sm={3}>
                              #File7
                            </Label>
                            <Col sm={9}>
                                <a
                                href={
                                  "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                  file7Attach
                                }
                                target="_blank"
                              >
                                {file7name}
                              </a>{" "}
                              <FaMinusSquare
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.submitremove(file7id)}
                              />
                              <FormText color="muted">เอกสารเพิ่มเติม</FormText>
                            </Col>
                          </FormGroup>
                          
                        </div>
                      ) : (
                        <div>
                  <FormGroup row>
                    <Label className="text-right" for="file7" sm={3}>
                      #File7
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="file7name"
                        value={file7name}
                        placeholder="ใส่ชื่อไฟล์"
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                  <Label className="text-right" for="file7" sm={3}></Label>
                    <Col sm={9}>
                      <Input
                        type="file"
                        name="file7"
                        onChange={this.onChangeFiles}
                      />
                      <FormText color="muted">เอกสารเพิ่มเติม</FormText>
                    </Col>
                  </FormGroup>
                  </div>
                  )}

                  <FormGroup row>
                    <Label className="text-right" for="detail" sm={3}></Label>
                    <Col sm={9}>
                      <Label for="confirmed">
                        *ข้าพเจ้าขอรับรองว่า ข้อความข้างต้นนั้น
                        เป็นความจริงทุกประการ
                      </Label>
                    </Col>
                  </FormGroup>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}
                  <Row className="text-center">
                    <Col>
                      <Button color="success">บันทึก</Button>{" "}
                      <Button color="warning">ยกเลิก</Button>
                    </Col>
                  </Row>
                </Form>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Gethelp);
