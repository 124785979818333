import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Input,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { validateFields } from "../Validation";
import classnames from "classnames";
import ClientCaptcha from "react-client-captcha";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const TitleH4 = styled.h4`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const WorkArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

class Recovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromName: "สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
      toName: "",
      userName: "",
      userEmail: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      captcha: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      submitCalled: false,
      allFieldsValidated: false,
      newPassword: "",
      reply_to: "akadeach@hotmail.com",
      saveSuccess: false,
      saveFail: false,
      captchaCode: '',
      captchaFail: false,
    };

    const token = localStorage.getItem("token");

    if (token) {
      this.props.history.push("/dashboard");
    }
  }

  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  onChange(validationFunc, value) {
    const field = "captcha";
    const fieldVal = value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  onCreatAccount = () => {
    this.props.history.push("/");
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setCaptcha(code) {
    this.setState({ captchaCode: code});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { userEmail, captcha } = this.state;
    const emailError = validateFields.validateEmail(userEmail.value);
    const captchaError = validateFields.validateCaptcha(captcha.value);
    if ([emailError, captchaError].every((e) => e === false)) {


      if (this.state.captchaCode === this.state.captcha.value){

      const getNameUrl = "frontendapi/getNewpassword/" + userEmail.value;
      axios
        .get(getNameUrl, this.state)
        .then((response) => {
          console.log("restponse : " + response.data);
          if (response.data != null) {
            console.log("response : " + JSON.stringify(response.data));
            this.setState({
              userName: response.data[0].userName,
              toName: response.data[0].toName,
              newPassword: response.data[0].newpassword,
              saveSuccess: true,
            });

            const { toName, userName, newPassword } = this.state;
            const templateParams = {
              fromName: "บจธ.",
              toemail: userEmail.value,
              toName: toName,
              userName: userName,
              newPassword: newPassword,
            };
            emailjs.send(
              "service_yd841xz",
              "template_o8ay3qk",
              templateParams,
              "user_6QWNIaALLIeyetOYbeHfW"
            );

            this.showSuccess();

            setTimeout(() => {
              this.props.history.push("/");
            }, 2000);

          } else {
            this.setState({
              saveFail: true,
            });

            this.showFails();

            setTimeout(() => {
              this.props.history.push("/recovery");
            }, 2000);

          }
        })
        .catch((error) => {
          console.log(error);
        });

      }else {

        this.setState({ captchaFail: true });
        this.showCaptchaFail();
      } 
    } else {
      // update the state with errors
      this.setState((state) => ({
        userEmail: {
          ...state.userEmail,
          validateOnChange: true,
          error: emailError,
        },
        captcha: {
          ...state.captcha,
          validateOnChange: true,
          error: captchaError,
        },
      }));
    }
  };

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false });
    }, 1000);
  }

  showFails() {
    setTimeout(() => {
      this.setState({ saveFail: false });
    }, 2000);
  }

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 2000);
  }

  showCaptchaFail() {
    setTimeout(() => {
      this.setState({ captchaFail: false });
    }, 1500);
  }

  render() {
    const {
      userEmail,
      saveSuccess,
      saveFail,
      captcha,
      allFieldsValidated,
      captchaFail,
    } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>สมัครสมาชิก</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <WorkArea>
                  <Container>
                    <Row className="text-center">
                      <Col>
                        <TitleH4>ค้นหาบัญชีของคุณ</TitleH4>
                        <p>
                          ระบบจะรีเซ็ตรหัสผ่านและส่งไปตามอีเมล์ที่คุณลงทะเบียนไว้
                        </p>
                        <hr />
                      </Col>
                    </Row>
                    {saveFail && (
                      <Row className="text-center">
                        <Col>
                          <Alert color="danger">
                            ไม่พบข้อมูลอีเมล์ที่คุณระบุ กรุณาติดต่อผู้ดูแลระบบ
                          </Alert>
                          <br />
                        </Col>
                      </Row>
                    )}
                    {saveSuccess && (
                      <Row className="text-center">
                        <Col>
                          <Alert color="success">
                            เปลี่ยนแปลงรหัสผ่านสำเร็จ!!!
                            ระบบส่งข้อมูลไปทางอีเมล์ที่คุณระบุไว้เรียบร้อยแล้ว
                          </Alert>
                          <br />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <div className="card-body">
                          {allFieldsValidated && (
                            <p className="text-success text-center">
                              Success, All fields are validated
                            </p>
                          )}
                          <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                              <Input
                                type="text"
                                name="userEmail"
                                value={userEmail.value}
                                placeholder="อีเมล์"
                                className={classnames(
                                  "form-control",
                                  { "is-valid": userEmail.error === false },
                                  { "is-invalid": userEmail.error }
                                )}
                                onChange={(evt) =>
                                  this.handleChange(
                                    validateFields.validateEmail,
                                    evt
                                  )
                                }
                                onBlur={(evt) =>
                                  this.handleBlur(
                                    validateFields.validateEmail,
                                    evt
                                  )
                                }
                              />
                              <div className="invalid-feedback">
                                {userEmail.error}
                              </div>
                            </FormGroup>

                            {captchaFail && (
                          <Row className="text-center">
                            <Col>
                              <Alert color="danger">ยืนยันรูปภาพไม่ตรง กรุณาลองใหม่</Alert>
                              <br />
                            </Col>
                          </Row>
                        )}
                    <Row form>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "1rem",
                        }}
                      ><ClientCaptcha backgroundColor="#ffc107" captchaCode={code => this.setCaptcha(code)} />
                      </Col>
                      </Row>
                      <Row form>
                      <Col sm="12" md={{ size: 10, offset: 1 }}>
                      <FormGroup>
                        <Input
                        type="text"
                        name="captcha"
                        placeholder="พิมพ์อักษรตามภาพเพื่อยืนยันตัวตน"
                        value={captcha.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": captcha.error === false },
                          { "is-invalid": captcha.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        />
                        <div className="invalid-feedback">{captcha.error}</div>
                        
                      </FormGroup>
                      </Col>
                      </Row>
                            <Row className="text-center">
                              <Col>
                                <Button type="submit" color="info">
                                  ดำเนินการ
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </WorkArea>
              </Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
export default withRouter(Recovery);
