import React, { Component } from "react";
import * as emailjs from "emailjs-com";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import Recaptcha from "react-recaptcha";

class Contact_form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      recaptchaLoad: false,
      isVerified: false,
    };

    /* and this... */
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  recaptchaLoaded() {
    this.setState({ recaptchaLoad: true });
  }

  verifiedRecaptcha(response) {
    if (response) {
      this.setState({ isVerified: true });
    }
  }

  handleSubmit(event) {
    const { recaptchaLoad, isVerified } = this.state;
    event.preventDefault();
    if (recaptchaLoad && isVerified) {
      const { name, email, subject, message } = this.state;
      const templateParams = {
        from_name: name,
        to_email: email,
        to_name: "Doctor mor",
        subject,
        message_html: message,
      };
      emailjs.send(
        "service_yd841xz",
        "template_3jixe0u",
        templateParams,
        "user_6QWNIaALLIeyetOYbeHfW"
      );
      alert(
        "Your message has been sent successfully. We will contact you soon."
      );
      this.resetForm();
    } else {
      alert("Please check the Recaptcha before sending your message");
    }
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { name, email, subject, message, sentMessage } = this.state;

    return (
      <Container>
        <Row>
          <Col>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Your first and last name"
                  value={name}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">To Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="email@gmail.com"
                  value={email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Subject</Label>
                <Input
                  name="subject"
                  type="text"
                  placeholder="What is the subject?"
                  value={subject}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Message</Label>
                <Input
                  type="textarea"
                  name="message"
                  placeholder="Tell me more about..."
                  value={message}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <Recaptcha
                sitekey="6LfNb88ZAAAAADqTAPqibBQhAYaYeCJLE5Te4w0V"
                render="explicit"
                onloadCallback={this.recaptchaLoaded}
                verifyCallback={this.verifiedRecaptcha}
              />
              <Button color="dark">Send</Button>
              <Button color="warning">Cancel</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact_form;
