import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 2em;
  background: #343a40;
  color: #fff;
`;

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col className="text-center">
                Copyright(c)2020 สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)
                -All Rights Reserved
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </div>
    );
  }
}
