import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardFooter,
  CardLink,
  CardText,
  CardDeck,
  CardBody,
} from "reactstrap";
import styled from "styled-components";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import moment from "moment";

const Wrapper = styled.section`
  padding: 4em;
  background: #eeeeee;
`;

const TitleH3 = styled.h3`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
`;

const TitleH5 = styled.h5`
  font-size: 14px;
  color: #069370;
  margin-bottom: 0rem;
`;

const CardFooterNew = styled(CardFooter)`
  font-size: 12px;
`;

const CardLinkNew = styled(CardLink)`
  color: #069370;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const CardTitleNew = styled(CardTitle)`
  margin-bottom: 0rem;
`;

const CardTextNew = styled(CardText)`
  font-size: 14px;
  margin-bottom: 0rem;
`;

const CardBodyNew = styled(CardBody)`
  padding: 1rem 1rem 0rem 1rem;
`;

class Km extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    const getUrl = "frontendapi/kmsm";
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onKm = () => {
    this.props.history.push("/km");
  };

  onClickdetail = (id) => {
    this.props.history.push("/kmdetail/" + id);
  };

  getImageFromURL = (img) => {
    const getImage = "/labai-backend/assets/images/kms/" + img;
    return getImage;
  };

  showLimitContent = (val, limit) => {
    const pos1 = val.replace("<p>", "");
    const pos2 = pos1.replace("</p>", "");
    const pos3 = pos2.replace("<br />", "");

    const txt = pos3.length;
    let subtxt = "";

    txt > limit ? (subtxt = val.substr(0, limit) + "...") : (subtxt = val);

    return subtxt;
  };

  render() {
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <TitleH3>คลังความรู้</TitleH3>
              </Col>
              <Col className="text-right">
                <Button outline color="success" onClick={this.onKm}>
                  <FaRegArrowAltCircleRight />
                  &nbsp;ดูทั้งหมด
                </Button>{" "}
              </Col>
            </Row>
            <hr />
            <Row>
              <CardDeck>
                {this.state.data.map((km, i) => (
                  <Card key={i}>
                    <CardImg
                      top
                      width="100%"
                      height="150px"
                      src={this.getImageFromURL(km.picture)}
                      alt="LABAI KM"
                    />
                    <CardBodyNew>
                      <CardTitleNew>
                        <TitleH5>
                          <label
                            dangerouslySetInnerHTML={{
                              __html: this.showLimitContent(km.name, 50),
                            }}
                          />
                        </TitleH5>
                      </CardTitleNew>
                      <CardTextNew
                        dangerouslySetInnerHTML={{
                          __html: this.showLimitContent(km.detail, 100),
                        }}
                      />
                    </CardBodyNew>
                    <CardFooterNew>
                      วันที่สร้าง :
                      {moment
                        .unix(km.date)
                        .utc()
                        .add(543, "year")
                        .local()
                        .format("DD/MM/YYYY")}
                      <CardLinkNew
                        style={{
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => this.onClickdetail(km.id)}
                      >
                        รายละเอียด
                      </CardLinkNew>
                    </CardFooterNew>
                  </Card>
                ))}
              </CardDeck>
            </Row>
          </Container>
        </Wrapper>
      </div>
    );
  }
}
export default withRouter(Km);
