import React, { Component, useState  } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Badge,
  Form, FormGroup, Label, Input
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import { FaUser, FaInbox, FaEllipsisH, FaSearch } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";
import "moment-duration-format";
import ReactTooltip from 'react-tooltip';
import PaginationComponent from "react-reactstrap-pagination";
import "bootstrap/dist/css/bootstrap.min.css";


const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const GrayLink = styled(Link)`
  color: #6c757d;
  border-radius: 50%;

  &:hover {
    background: #ffffff;
    padding: 10px 12px 10px 12px;
    color: #6c757d;
  }
`;

const OperateTooltip = styled.div`
  background: papayawhip;
  position: relative;
  display: inline-block;
  padding: 10px;
  border: 1px solid gray;
`;



const OrderList = (props) => {

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>เลขที่เอกสาร</th>
          <th>วันที่ยื่น</th>
          <th>เวลาดำเนินการ</th>
          <th>ส่งเรื่องไปแล้ว</th>
          <th>กรณี</th>
          <th>เหตุผล</th>
          <th>ผู้รับเรื่อง</th>
          <th>ระยะเวลารับเรื่อง</th>
          <th>สถานะ</th>
          <th>รายละเอียด</th>
        </tr>
      </thead>
      <tbody>
        {props.getHelp.map((gethelp, i) => (
          <tr key={i}>
            <th scope="row">{gethelp.gethelpid}</th>
            <td>
                {moment
                .unix(gethelp.gethelpdate)
                .utc()
                .add(543, "year")
                .local()
                .format("DD/MM/YYYY")}
            </td>
            <td>
              {gethelp.mgttime} วัน
            </td>
            <td>
              {gethelp.sort != "5"
                ? moment
                  .duration(
                    moment(
                      moment
                        .utc()
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss"),
                      "YYYY-MM-DD HH:mm:ss"
                    ).diff(
                      moment(
                        moment
                          .unix(gethelp.gethelpdate)
                          .utc()
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss"),
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      "minutes"
                    ),
                    "minutes"
                  )
                  .format("y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]", {
                    largest: 3,
                  })
                : moment
                  .duration(
                    moment(
                      moment
                        .unix(gethelp.dateedit)
                        .utc()
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss"),
                      "YYYY-MM-DD HH:mm:ss"
                    ).diff(
                      moment(
                        moment
                          .unix(gethelp.gethelpdate)
                          .utc()
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss"),
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      "minutes"
                    ),
                    "minutes"
                  )
                  .format("y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]", {
                    largest: 3,
                  })}
            </td>
            <td>
              {gethelp.reqid === "0"
                ? gethelp.requesttypeother
                : gethelp.rtname}
            </td>
            <td>
              {gethelp.requestreasonid === "0"
                ? gethelp.requestreasonother
                : gethelp.rsname}
            </td>
            <td>{gethelp.firstname}</td>
            <td>
              {gethelp.dateaccept != null?
                moment
                .duration(
                  moment(
                    moment
                      .unix(gethelp.dateedit)
                      .utc()
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss"),
                    "YYYY-MM-DD HH:mm:ss"
                  ).diff(
                    moment(
                      moment
                        .unix(gethelp.gethelpdate)
                        .utc()
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss"),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    "minutes"
                  ),
                  "minutes"
                )
                .format("y [ปี], M [เดือน], d [วัน], h [ชม.], m [นาที]", {
                  largest: 3,
                })
                :""
              }
            </td>
            <td width="18%">
              <Badge href="#" color={gethelp.button_color}>
                {gethelp.jobstatusname}
              </Badge>{" "}
              {gethelp.countOperate > 0 ? 
              <Badge data-tip='' data-for={'soclose'+ gethelp.gethelpid} color="danger" pill>i
              <ReactTooltip id={'soclose'+ gethelp.gethelpid}>
                <p><u>รายการที่ขอให้คุณดำเนินการ</u></p>
                { gethelp.operatedata.map((filteroperate, ii) => (
                      <p key={ii}>
                        {" : "}{filteroperate.name}
                      </p>
                    
                    ))
  
                    }</ReactTooltip></Badge>
                : "" }
                
            </td>
            <td className="text-center">
              <GrayLink to={"/gethelpdetail/" + gethelp.gethelpid}>
                <FaEllipsisH />
              </GrayLink>
              {/* {props.getHelp[i].map((operate,ii) => (
                  <p key={ii}>
                    รายการ : {operate.name}
                  </p>
                ))} */}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const OrderSummary = (props) => {
  return (
    <Table borderless size="sm">
      <tbody>
        <tr>
          <td>
            <Badge color="secondary">รอเจ้าหน้าที่รับเรื่อง</Badge>
          </td>
          <td className="text-right">{props.getStatus1}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
        <tr>
          <td>
            <Badge color="warning">เจ้าหน้าที่รับเรื่องแล้ว</Badge>
          </td>
          <td className="text-right">{props.getStatus2}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
        <tr>
          <td>
            <Badge color="info">ส่งเรื่องไปที่ ผอ.กองแล้ว</Badge>
          </td>
          <td className="text-right">{props.getStatus3}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
        <tr>
          <td>
            <Badge color="primary">ผอ.กองรับเรื่องแล้ว</Badge>
          </td>
          <td className="text-right">{props.getStatus4}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
        <tr>
          <td>
            <Badge color="success">ดำเนินการเสร็จสิ้น</Badge>
          </td>
          <td className="text-right">{props.getStatus5}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
        <tr>
          <td>
            <Badge color="danger">ไม่สามารถดำเนินการได้</Badge>
          </td>
          <td className="text-right">{props.getStatus6}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
        <tr>
          <td>
            <Badge color="danger">ไม่อนุมัติ</Badge>
          </td>
          <td className="text-right">{props.getStatus7}</td>
          <td className="text-right">เรื่อง</td>
        </tr>
      </tbody>
    </Table>
  );
};

const OrderAccount = (props) => {
  return (
    <Table borderless size="sm">
      <tbody>
        <tr>
          <td>ชื่อ-นามสกุล</td>
          {props.Users.map((user) => (
            <td className="text-right" key={user.id}>
              {user.firstname} {user.lastname}
            </td>
          ))}
        </tr>
        <tr>
          <td>อีเมล์</td>
          {props.Users.map((email) => (
            <td className="text-right" key={email.id}>
              {email.email_line}
            </td>
          ))}
        </tr>
        <tr>
          <td>เบอร์โทร</td>
          {props.Users.map((tel) => (
            <td className="text-right" key={tel.id}>
              {tel.tel}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      userId: "",
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
      status5: 0,
      status6: 0,
      status7: 0,
      data: [],
      dataHelp: [],
      dataOperate: [],
      offset: 1,
      perpage: 10,
      totalItem: 0,
      searchcode: "",
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSelected(selectedPage) {
    this.setState({ offset: selectedPage }, () => {
      this.loadDataFromServer();
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  async loadDataFromServer(gethelpid="0") {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({ data: response.data, userId: response.data[0].id });
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.state.userId) {

      const gethelpUrl = "frontendapi/gethelp/" + this.state.userId + "/" + gethelpid + "/" + this.state.offset + "/" + this.state.perpage;
      axios
        .get(gethelpUrl, this.state)
        .then((response) => {
          if (response.data) {
            this.setState({ dataHelp: response.data });
            
          }
        })
        .catch((error) => {
          console.log(error);
        });

        const getCountUrl = "frontendapi/countGethelp/" + this.state.userId;
        axios
          .get(getCountUrl, this.state)
          .then((response) => {
            this.setState({ totalItem: response.data });
          })
          .catch((error) => {
            console.log(error);
          });

        const getOperateUrl = "frontendapi/gethelp_operate/" + this.state.userId;
              axios
                .get(getOperateUrl, this.state)
                .then((response) => {
                  this.setState({ dataOperate: response.data });
                })
                .catch((error) => {
                  console.log(error);
                });

      for (let i = 1; i <= 7; i++) {
        const getUrl =
          "frontendapi/getSumhelpstatus/" + this.state.userId + "/" + i;
        await axios
          .get(getUrl, this.state)
          .then((response) => {
            if (response.data) {
              if (i == "1") {
                this.setState({ status1: response.data });
              } else if (i == "2") {
                this.setState({ status2: response.data });
              } else if (i == "3") {
                this.setState({ status3: response.data });
              } else if (i == "4") {
                this.setState({ status4: response.data });
              } else if (i == "5") {
                this.setState({ status5: response.data });
              } else if (i == "6") {
                this.setState({ status6: response.data });
              } else if (i == "7") {
                this.setState({ status7: response.data });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

   componentDidMount() {
    
    this.loadDataFromServer();
    // const Durations = moment
    //   .duration(12160, "minutes")
    //   .format("y [ปี], M [เดือน], d [วัน], h [ชั่วโมง], m [นาที]", {
    //     largest: 1,
    //   });
    // const my_moment = moment("2018-06-01", "YYYY-MM-DD");
    // console.log("diff minutes ::==", moment().diff(my_moment, "minutes"));
    //console.log("Durations : " + Durations);
  }

  customTemplate() {
    return this.duration.asSeconds() >= 86400
      ? "w [สัปดาห์], d [วัน]"
      : "hh:mm:ss";
  }

  onGethelp = () => {
    this.props.history.push("/gethelp");
  };

  onUserinfo = () => {
    this.props.history.push("/userinfo");
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    if(this.state.searchcode!=""){
      this.loadDataFromServer(this.state.searchcode);
    }else{
      this.loadDataFromServer(0);
    }
    

  }


  render() {
    const {
      perpage,
      totalItem,
      searchcode,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>
                      รายการขอความช่วยเหลือ
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  {this.state.data.map((user) => (
                    <h3 key={user.id}>
                      {user.firstname} {user.lastname}
                    </h3>
                  ))}
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Row xs="1" sm="2" md="2">
                  <Col xs="auto">
                    <h5>
                      <FaUser />
                      ข้อมูลส่วนตัว
                    </h5>
                    <hr />
                    <Row>
                      <Col>
                        <OrderAccount Users={this.state.data} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="auto">
                    <h5>
                      <FaInbox />
                      สรุปรายการขอรับบริการ
                    </h5>
                    <hr />
                    <Row>
                      <Col>
                        <OrderSummary
                          getStatus1={this.state.status1}
                          getStatus2={this.state.status2}
                          getStatus3={this.state.status3}
                          getStatus4={this.state.status4}
                          getStatus5={this.state.status5}
                          getStatus6={this.state.status6}
                          getStatus7={this.state.status7}
                        />
                      </Col>
                    </Row>
                  </Col>
                  
                </Row>
                <Row xs="1" sm="2" md="2">
                  <Col xs="auto">
                    <Row>
                      <Col>
                        <Form inline onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Label for="searchcode" className="mr-sm-2">ค้นหาคำขอความช่วยเหลือ</Label>
                              <Input type="text" name="searchcode" value={searchcode} placeholder="พิมพ์เลขที่เอกสาร" onChange={this.changeHandler} bsSize="sm" />
                          </FormGroup>
                          <Button type="submit" color="info" size="sm"><FaSearch /></Button>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="auto">
                    <Row>
                      <Col className="text-right">
                        <Button color="success" onClick={this.onGethelp} size="sm">
                          ขอรับความช่วยเหลือ
                        </Button>{" "}
                      </Col>
                    </Row>
                  </Col>
                  
                </Row>
                <Row>
                  <Col className="text-right">
                    <p>
                      {/* <Button color="success" onClick={this.onGethelp}>
                        ขอรับความช่วยเหลือ
                      </Button>{" "} */}
                      
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <OrderList getHelp={this.state.dataHelp} getOperate={this.state.dataOperate} />
                  </Col>
                </Row>
                <Row>
                  <Col style={{
                          display: "flex",
                          justifyContent: "center",
                        }}>
                  <PaginationComponent
                    totalItems={totalItem}
                    pageSize={perpage}
                    onSelect={this.handleSelected}
                  />
                  </Col>
                </Row>
              </OrderArea>
              
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Dashboard);
