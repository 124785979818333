import React, { Component } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import { FaMapMarkerAlt, FaRocketchat } from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class Consultdet extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }
  }

  state = {
    data: [],
    dataconsult: [],
    dataconsultdet: [],
    date: new Date().toLocaleString("en-US"),
    userid: "",
    consultid: "",
    consultstatus: "",
    firstname: "",
    lastname: "",
    isSuccess: false,
    isFail: false,
    detail: {
      value: "",
      validateOnChange: false,
      error: "",
    },
    submitCalled: false,
    allFieldsValidated: false,
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");

    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(this.props.match.params.id);

    const getUrl = "../frontendapi/consult/" + this.props.match.params.id;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        this.setState({
          dataconsult: response.data,
          consultid: response.data[0].id,
          consultstatus: response.data[0].status,
        });
        //console.log("state : " + JSON.stringify(response.data));
        //console.log("state : " + response.data[0].id);
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.state.dataconsult[0].id) {
      const getdetUrl =
        "../frontendapi/consultdet/" + this.state.dataconsult[0].id;
      await axios
        .get(getdetUrl, this.state)
        .then((response) => {
          this.setState({ dataconsultdet: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /*
   * validates the field onBlur if sumbit button is not clicked
   * set the validateOnChange to true for that field
   * check for error
   */
  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  handleSubmit(evt) {
    evt.preventDefault();
    // validate all fields
    const { detail } = this.state;
    const detailError = validateFields.validateDetail(detail.value);
    if ([detailError].every((e) => e === false)) {
      // no errors submit the form
      //console.log("success");
      //console.log("state : " + JSON.stringify(this.state));
      const getHelpUrl = "../frontendapi/consultdet";
      axios
        .post(getHelpUrl, this.state)
        .then((response) => {
          console.log(response);
          if (response.data) {
            // clear state and show all fields are validated
            this.setState({ detail, allFieldsValidated: true });
            this.showAllFieldsValidated();
            setTimeout(() => {
              window.location.reload(false);
              //this.props.history.push("/consult");
            }, 1000);
          } else {
            this.props.history.push("/consultdet");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // update the state with errors
      this.setState((state) => ({
        detail: {
          ...state.detail,
          validateOnChange: true,
          error: detailError,
        },
      }));
    }
  }

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false });
    }, 1000);
  }

  onGetconsult = () => {
    this.props.history.push("/consultform");
  };

  render() {
    const {
      userid,
      date,
      detail,
      consultid,
      firstname,
      lastname,
      allFieldsValidated,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <OrderArea>
                <Row>
                  <Col>
                    <h5>
                      <FaRocketchat /> การขอคำปรึกษา
                    </h5>
                    <hr />

                    <Row>
                      <Col>
                        <ConsultArea fluid style={{ background: "#fff" }}>
                          <Container fluid>
                            {this.state.dataconsult.map((consult, i) => (
                              <div key={i}>
                                <h5>หัวข้อ : {consult.name}</h5>
                                <p>รายละเอียด : {consult.detail}</p>
                              </div>
                            ))}
                            <hr />

                            {this.state.dataconsultdet != "" ? (
                              <Container>
                                {this.state.dataconsultdet.map(
                                  (consultdet, ii) => (
                                    <div key={ii}>
                                      {consultdet.usertypeid == "5" ? (
                                        <Row>
                                          <Col xs="6">
                                            <Alert color="success">
                                              {consultdet.detail}
                                            </Alert>
                                            <Timeconsult>
                                              {moment
                                                .unix(consultdet.date)
                                                .utc()
                                                .add(543, "year")
                                                .local()
                                                .format("DD/MM/YYYY HH:mm")}
                                              <FaMapMarkerAlt />
                                            </Timeconsult>
                                          </Col>
                                          <Col
                                            xs="6"
                                            className="text-right"
                                          ></Col>
                                        </Row>
                                      ) : (
                                        <Row>
                                          <Col xs="6"></Col>
                                          <Col xs="6" className="text-right">
                                            <Alert color="primary">
                                              {consultdet.detail}
                                            </Alert>
                                            <Timeconsult>
                                              {moment
                                                .unix(consultdet.date)
                                                .utc()
                                                .add(543, "year")
                                                .local()
                                                .format("DD/MM/YYYY HH:mm")}
                                              <FaMapMarkerAlt />
                                            </Timeconsult>
                                          </Col>
                                        </Row>
                                      )}
                                      <br />
                                    </div>
                                  )
                                )}
                              </Container>
                            ) : (
                              <Container>
                                <Row>
                                  <Col xs="12">
                                    <Alert color="secondary">
                                      No have content
                                    </Alert>
                                  </Col>
                                  <Col xs="6" className="text-right"></Col>
                                </Row>
                              </Container>
                            )}
                          </Container>
                        </ConsultArea>
                      </Col>
                    </Row>
                    {this.state.dataconsultdet != "" && this.state.consultstatus != "close" ? (
                      <Row>
                        <Col>
                          <div className="card-body">
                            {allFieldsValidated && (
                              <p className="text-success text-center">
                                Success, All fields are validated
                              </p>
                            )}
                            <Form onSubmit={(evt) => this.handleSubmit(evt)}>
                              <input
                                type="hidden"
                                name="consultid"
                                value={consultid}
                              />
                              <input
                                type="hidden"
                                name="userid"
                                value={userid}
                              />
                              <input type="hidden" name="date" value={date} />
                              <Row form>
                                <Col>
                                  <FormGroup>
                                    <Label for="consult">
                                      พิมพ์ข้อมูลที่ต้องการขอคำปรึกษา
                                    </Label>
                                    <Input
                                      type="textarea"
                                      rows="5"
                                      name="detail"
                                      id="detail"
                                      value={detail.value}
                                      className={classnames(
                                        "form-control",
                                        { "is-valid": detail.error === false },
                                        { "is-invalid": detail.error }
                                      )}
                                      onChange={(evt) =>
                                        this.handleChange(
                                          validateFields.validateDetail,
                                          evt
                                        )
                                      }
                                      onBlur={(evt) =>
                                        this.handleBlur(
                                          validateFields.validateDetail,
                                          evt
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="text-center">
                                  <Button
                                    type="submit"
                                    color="success"
                                    onMouseDown={() =>
                                      this.setState({ submitCalled: true })
                                    }
                                  >
                                    บันทึก
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <div></div>
                    )}
                  </Col>
                </Row>
              </OrderArea>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Consultdet);
