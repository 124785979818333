import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Media,
} from "reactstrap";
import styled from "styled-components";
import Mymap from "./Googlemap";
import labaicontact_img from "../assets/img/labai_contact.jpg";
import qr from "../assets/img/qr/M.png";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaFax,
  FaLine,
  FaFacebookSquare,
} from "react-icons/fa";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const Wrapper3 = styled.section`
  padding: 2em;
  background: #e4f1ea;
`;

const Wrapper4 = styled.section`
  padding: 6em;
  background: url(${labaicontact_img}) center top no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  text-align: center;
`;

const Icondiv1 = styled.div`
  display: inline-block;
  color: #346fb9;
  font-size: 60px;
  text-align: center;
`;
const Icondiv2 = styled.div`
  display: inline-block;
  color: #06933e;
  font-size: 60px;
  text-align: center;
`;
const Icondiv3 = styled.div`
  display: inline-block;
  color: #04adef;
  font-size: 60px;
  text-align: center;
`;

export default class Contact extends Component {
  render() {
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>ติดต่อเรา</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col>
                <Breadcrumb className="float-right">
                  <BreadcrumbItem>
                    <a href="#">หน้าหลัก</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>ติดต่อเรา</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              <Col>
                <Mymap />
              </Col>
            </Row>
          </Container>
        </Wrapper2>
        <Wrapper3>
          <Container className="themed-container">
            <Row className="text-center">
              <Col>
                <h1>ติดต่อสถาบันบริหารจัดการธนาคารที่ดิน</h1>
                <h3>LABAI Contact Center</h3>
              </Col>
            </Row>
            <Row xs="3" style={{ padding: "1.5rem" }}>
              <Col style={{ padding: "1rem" }}>
                <Media>
                  <Media body>
                    <Media heading>เบอร์ติดต่อ</Media>
                    <FaPhoneAlt /> โทร 02-2781244 , 02-2781648 ต่อ 610
                  </Media>
                </Media>
              </Col>
              <Col style={{ padding: "1rem" }}>
                <Media>
                  <Media body>
                    <Media heading>แฟกซ์</Media>
                    <FaFax /> 02-2781148
                  </Media>
                </Media>
              </Col>
              <Col style={{ padding: "1rem" }}>
                <Media>
                  <Media body>
                    <Media heading>อีเมล์</Media>
                    <FaEnvelope /> Email : labai@labai.or.th
                  </Media>
                </Media>
              </Col>
            </Row>
          </Container>
        </Wrapper3>
        <Wrapper4>
          <Container className="themed-container">
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <Media>
                  <Media body>
                    <Media heading>
                      <h1>สำนักงานใหญ่</h1>
                    </Media>
                    เลขที่ 210 สถานีวิทยุโทรทัศน์กองทัพบก อาคารเบญจสิริ
                    ถนนพหลโยธิน แขวงสามเสนใน เขตพญาไท กรุงเทพมหานคร 10400
                  </Media>
                </Media>
              </Col>
            </Row>
          </Container>
        </Wrapper4>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              <Col>
                <h3>ติดตามสถาบันบริหารจัดการธนาคารที่ดินทาง Social Media</h3>
              </Col>
            </Row>
            <Row xs="3">
              <Col className="text-center">
                <a
                  href="https://www.facebook.com/thelandbankadministrationinstitute"
                  target="_blank"
                >
                  <Icondiv1>
                    <FaFacebookSquare />
                  </Icondiv1>
                </a>
                <p>LABAI Facebook</p>
              </Col>
              <Col className="text-center">
                <Icondiv2>
                  <FaLine />
                </Icondiv2>
                <p>LABAI Line</p>
              </Col>
              <Col className="text-center">
                <Icondiv3>
                  <img src={qr} alt="line" style={{ width: "50%" }} />
                </Icondiv3>
                <p>Add LINE : @lands</p>
              </Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
