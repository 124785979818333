import React, { useState, Component } from "react";
import logo from "../assets/img/labai_logo.jpg";
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";

export default class Navbar_labai extends Component {
  onSet = (isHomepage) => {
    this.props.onHomepage({ isHomepage: isHomepage });
  };

  render() {
    return <Toolbar onSet={this.onSet} />;
  }
}

const Toolbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onclick = (e) => {
    props.onSet(e);
  };

  return (
    <div>
      <Navbar color="faded" light expand="md">
        <Container className="themed-container">
          <NavbarBrand to="/" tag={RRNavLink}>
            <img src={logo} alt="Labai Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar></Nav>
            <NavbarText>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink
                    to="/"
                    onClick={() => onclick(true)}
                    activeClassName="active"
                    exact
                    tag={RRNavLink}
                  >
                    หน้าหลัก
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    บริการของเรา
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink
                        to="/service"
                        activeClassName="active"
                        tag={RRNavLink}
                      >
                        แนะนำบริการ
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink
                        to="/service_apply"
                        activeClassName="active"
                        tag={RRNavLink}
                      >
                        การสมัครใช้บริการ
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink
                        to="/faq"
                        activeClassName="active"
                        tag={RRNavLink}
                      >
                        คำถามที่ถามบ่อย
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    to="/gethelp"
                    activeClassName="active"
                    tag={RRNavLink}
                  >
                    ขอรับความช่วยเหลือ
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/km"
                    activeClassName="active"
                    tag={RRNavLink}
                  >
                    คลังความรู้
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="http://clm.labai.or.th/"
                    target="_blank"
                  >
                    ตลาดกลางที่ดิน
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/contact"
                    activeClassName="active"
                    tag={RRNavLink}
                  >
                    ติดต่อเรา
                  </NavLink>
                </NavItem>
              </Nav>
            </NavbarText>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
