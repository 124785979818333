import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  CustomInput,
  FormText,
} from "reactstrap";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import { FaRegListAlt, FaMinusSquare } from "react-icons/fa";
import moment from "moment";
import InputAddress from "react-thailand-address-autocomplete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

class Landrequest extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      date: "",
      checked: false,
      checked1: false,
      checked2: false,
      personstatus: "",
      landrequestID: "",
      userid: "",
      firstname: "",
      lastname: "",
      membername: "",
      memberage: "",
      memberrelation: "",
      membercareer: "",
      memberincome: "",
      membernote: "",
      members: [
        {
          membername: "",
          memberage: "",
          memberrelation: "",
          membercareer: "",
          memberincome: "",
          membernote: "",
        },
      ],
      detclaim: "",
      detclaimno: "",
      detlandno: "",
      detaddress: "",
      detarea: "",
      detbuildtype: "",
      details: [
        {
          detclaim: "",
          detclaimno: "",
          detlandno: "",
          detaddress: "",
          detarea: "",
          detbuildtype: "",
        },
      ],
      corporation: "",
      certificateperson: "",
      regisnumber: "",
      dateregis: "",
      address: "",
      moo: "",
      soi: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      phone: "",
      mobile: "",
      email: "",
      line: "",
      by: "",
      position: "",
      authority: "",
      certificateauthority: "",
      certificateregisnumber: "",
      certificateregisdate: "",
      rule: "",
      conclusion: "",
      membertype: "",
      membernumber: "",
      standing: "",
      standingother: "",
      usage: "",
      usagefor: "",
      usagetime: "",
      gethelpid: this.props.match.params.gethelpid,
      file1id: "",
      file2id: "",
      file3id: "",
      file4id: "",
      file5id: "",
      file6id: "",
      file7id: "",
      file8id: "",
      file9id: "",
      file10id: "",
      file11id: "",
      file12id: "",
      file13id: "",
      file1name: "",
      file2name: "",
      file3name: "",
      file4name: "",
      file5name: "",
      file6name: "",
      file7name: "",
      file8name: "",
      file9name: "",
      file10name: "",
      file11name: "",
      file12name: "",
      file13name: "",
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      file5: null,
      file6: null,
      file7: null,
      file8: null,
      file9: null,
      file10: null,
      file11: null,
      file12: null,
      file13: null,
      countEvaluation: "",
      process_status: "",
      saveSuccess: false,
      saveFail: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.onChangeFiles = this.onChangeFiles.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateregisChange = this.handleDateregisChange.bind(this);
    this.handleCertificateregisdateChange = this.handleCertificateregisdateChange.bind(this);
  }

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        const daterequestnew = moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DD");

        this.setState({
          data: response.data,
          userid: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          date: new Date(daterequestnew),
          dateregis: new Date(daterequestnew),
          certificateregisdate: new Date(daterequestnew),
        });
      })
      .catch((error) => {
        console.log(error);
      });

      //////////// Count Evaluation
      const getcountUrl = "../frontendapi/countEvaluation/" + this.state.gethelpid;
      await axios
      .get(getcountUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({ countEvaluation: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getUrl = "../frontendapi/getlandrequest/" + this.state.gethelpid;
    await axios
      .get(getUrl, this.state)
      .then((response) => {
        if (response) {
          const daterequest = moment(
            new Date(moment.unix(response.data[0].date).utc().format())
          ).format("YYYY-MM-DD");

          const dateregis = moment(
            new Date(moment.unix(response.data[0].dateregis).utc().format())
          ).format("YYYY-MM-DD");

          const certificateregisdate = moment(
            new Date(
              moment.unix(response.data[0].certificateregisdate).utc().format()
            )
          ).format("YYYY-MM-DD");

          this.setState({
            userid: response.data[0].userid,
            date: daterequest,
            landrequestID: response.data[0].id,
            personstatus: response.data[0].personstatus,
            corporation: response.data[0].corporation,
            certificateperson: response.data[0].certificateperson,
            regisnumber: response.data[0].regisnumber,
            dateregis: dateregis,
            address: response.data[0].address,
            moo: response.data[0].moo,
            soi: response.data[0].soi,
            subdistrict: response.data[0].district,
            district: response.data[0].amphur,
            province: response.data[0].province,
            zipcode: response.data[0].zipcode,
            phone: response.data[0].phone,
            mobile: response.data[0].mobile,
            email: response.data[0].email,
            line: response.data[0].line,
            by: response.data[0].by,
            position: response.data[0].position,
            authority: response.data[0].authority,
            certificateauthority: response.data[0].certificateauthority,
            certificateregisnumber: response.data[0].certificateregisnumber,
            certificateregisdate: certificateregisdate,
            rule: response.data[0].rule,
            conclusion: response.data[0].conclusion,
            membertype: response.data[0].membertype,
            membernumber: response.data[0].membernumber,
            standing: response.data[0].standing,
            standingother: response.data[0].standingother,
            usage: response.data[0].usage,
            usagefor: response.data[0].usagefor,
            usagetime: response.data[0].usagetime,
            process_status: "edit",
          });

          if (response.data[0].personstatus == "JP") {
            this.setState({ checked: true });
          }
          //////////// get Members
          const getmemberUrl =
            "../frontendapi/getlandrequest_member/" + response.data[0].id;
          axios
            .get(getmemberUrl, this.state)
            .then((response) => {
              //console.log("response : " + JSON.stringify(response.data));
              if (response.data != "") {
                this.setState({ members: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get Members
          const getdetUrl =
            "../frontendapi/getlandrequest_detail/" + response.data[0].id;
          axios
            .get(getdetUrl, this.state)
            .then((response) => {
              if (response.data != "") {
                this.setState({ details: response.data });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          //////////// get attactfile
          const getfileUrl =
            "../frontendapi/getfile/tbl_land_request/" + response.data[0].id;
          axios
            .get(getfileUrl, this.state)
            .then((response) => {
              //console.log("response : " + JSON.stringify(response.data));
              if (response.data != "") {
                for (let i = 0; i < response.data.length; i++) {
                  if (response.data[i].filename == "file1") {
                    this.setState({
                      file1id: response.data[i].id,
                      file1name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file2") {
                    this.setState({
                      file2id: response.data[i].id,
                      file2name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file3") {
                    this.setState({
                      file3id: response.data[i].id,
                      file3name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file4") {
                    this.setState({
                      file4id: response.data[i].id,
                      file4name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file5") {
                    this.setState({
                      file5id: response.data[i].id,
                      file5name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file6") {
                    this.setState({
                      file6id: response.data[i].id,
                      file6name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file7") {
                    this.setState({
                      file7id: response.data[i].id,
                      file7name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file8") {
                    this.setState({
                      file8id: response.data[i].id,
                      file8name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file9") {
                    this.setState({
                      file9id: response.data[i].id,
                      file9name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file10") {
                    this.setState({
                      file10id: response.data[i].id,
                      file10name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file11") {
                    this.setState({
                      file11id: response.data[i].id,
                      file11name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file12") {
                    this.setState({
                      file12id: response.data[i].id,
                      file12name: response.data[i].attachfile,
                    });
                  } else if (response.data[i].filename == "file13") {
                    this.setState({
                      file13id: response.data[i].id,
                      file13name: response.data[i].attachfile,
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  handleDateChange = (value, e) => {
    this.setState({ date: value, });
  };
  handleDateregisChange = (value, e) => {
    this.setState({ dateregis: value, });
  };
  handleCertificateregisdateChange = (value, e) => {
    this.setState({ certificateregisdate: value, });
  };

  onChangeFiles(e) {
    this.setState({ [e.target.name]: e.target.files[0] });
  }

  handleChange(e) {
    if (e.target.value == "JP") {
      this.setState({ checked: true, personstatus: e.target.value });
    } else {
      this.setState({ checked: false, personstatus: e.target.value });
    }

    console.log(e.target.value);
  }

  handleChange1(e) {
    this.setState({ membertype: e.target.value });
    console.log(e.target.value);
  }

  handleChange2(e) {
    this.setState({ usage: e.target.value });
  }

  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;

    console.log(fullAddress.district);
    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const getURL = "../frontendapi/mgtLandrequest/" + this.state.gethelpid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        //console.log("response : " + response.data);
        if (response.data != "" || response.data != "0") {
          this.setState({ process_status: "edit", saveSuccess: true });
          this.showSuccess();

          const formData = new FormData();
          formData.append("file1", this.state.file1);
          formData.append("file2", this.state.file2);
          formData.append("file3", this.state.file3);
          formData.append("file4", this.state.file4);
          formData.append("file5", this.state.file5);
          formData.append("file6", this.state.file6);
          formData.append("file7", this.state.file7);
          formData.append("file8", this.state.file8);
          formData.append("file9", this.state.file9);
          formData.append("file10", this.state.file10);
          formData.append("file11", this.state.file11);
          formData.append("file12", this.state.file12);
          formData.append("file13", this.state.file13);

          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };

          const getuploadUrl =
            "../frontendapi/upload_landrequest/tbl_land_request/" +
            response.data;
          axios
            .post(getuploadUrl, formData, config)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });

          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        } else {
          this.props.history.push("/landrequest/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  //////////////// Add Member /////////

  handleMemberNameChange = (idx) => (evt) => {
    const newMembers = this.state.members.map((member, sidx) => {
      if (idx !== sidx) return member;
      return { ...member, [evt.target.name]: evt.target.value };
    });

    this.setState({ members: newMembers });
  };

  handleAddMember = () => {
    this.setState({
      members: this.state.members.concat([
        {
          membername: "",
          memberage: "",
          memberrelation: "",
          membercareer: "",
          memberincome: "",
          membernote: "",
        },
      ]),
    });
  };

  handleRemoveMember = (idx) => () => {
    this.setState({
      members: this.state.members.filter((s, sidx) => idx !== sidx),
    });
  };

  //////////////// Add Details /////////

  handleDetailNameChange = (idx) => (evt) => {
    const newDetails = this.state.details.map((detail, sidx) => {
      if (idx !== sidx) return detail;
      return { ...detail, [evt.target.name]: evt.target.value };
    });

    this.setState({ details: newDetails });
  };

  handleAddDetail = () => {
    this.setState({
      details: this.state.details.concat([
        {
          detclaim: "",
          detclaimno: "",
          detlandno: "",
          detaddress: "",
          detarea: "",
          detbuildtype: "",
        },
      ]),
    });
  };

  handleRemoveDetail = (idx) => () => {
    this.setState({
      details: this.state.details.filter((s, sidx) => idx !== sidx),
    });
  };

  handleRemovefile = (fileid) => {
    //e.preventDefault();

    const getURL = "../frontendapi/removefiles/" + fileid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response) {
          window.location.reload(false);
        } else {
          this.props.history.push("/landrequest/" + this.state.gethelpid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitremove = (fileid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Alert color="warning">
              <h3>ยืนยันการลบข้อมูล</h3>
              <p>คุณต้องการลบไฟล์นี้หรือไม่?</p>
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  this.handleRemovefile(fileid);
                  onClose();
                }}
              >
                {" "}
                Yes
              </Button>{" "}
              <Button color="warning" size="sm" onClick={onClose}>
                No
              </Button>
            </Alert>
          </div>
        );
      },
    });
  };

  render() {
    const hidden_chk = this.state.checked ? "block" : "none";
    const {
      date,
      landrequestID,
      personstatus,
      firstname,
      lastname,
      gethelpid,
      corporation,
      certificateperson,
      regisnumber,
      dateregis,
      address,
      moo,
      soi,
      subdistrict,
      district,
      province,
      zipcode,
      phone,
      mobile,
      email,
      line,
      by,
      position,
      authority,
      certificateauthority,
      certificateregisnumber,
      certificateregisdate,
      rule,
      conclusion,
      membertype,
      membernumber,
      standing,
      standingother,
      usage,
      usagefor,
      usagetime,
      file1id,
      file2id,
      file3id,
      file4id,
      file5id,
      file6id,
      file7id,
      file8id,
      file9id,
      file10id,
      file11id,
      file12id,
      file13id,
      file1name,
      file2name,
      file3name,
      file4name,
      file5name,
      file6name,
      file7name,
      file8name,
      file9name,
      file10name,
      file11name,
      file12name,
      file13name,
      countEvaluation,
      saveSuccess,
    } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                <OrderArea>
                  <Row>
                    <Col xs="5">
                      <h5>
                        <FaRegListAlt /> แบบคำขอใช้ประโยชน์ในที่ดิน
                      </h5>
                    </Col>
                    <Col xs="7" className="text-right">
                      {countEvaluation==0?
                      <Button
                        onClick={() => this.onEvaluation(gethelpid)}
                        color="primary"
                        style={{ marginBottom: "1rem" }}
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>:<Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                      <MenuDetails getHelpID={gethelpid} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup>
                        <ListGroupItem>
                          <h5>ข้อมูลผู้ยื่นคำร้อง : </h5>
                          <hr />
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="birthdate"
                              sm={3}
                            >
                              วันที่ยื่นคำขอ
                            </Label>
                            <Col sm={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                <DatePicker 
                                  format="DD/MM/yyyy"
                                  value={date}
                                  onChange={(value, e) => this.handleDateChange(value, e)}
                                  yearOffset={543}
                                  animateYearScrolling
                                />
                              </MuiPickersUtilsProvider>
                            </Col>
                            <Input
                              type="hidden"
                              name="landrequestID"
                              value={landrequestID}
                            />
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="personstatus"
                              sm={3}
                            >
                              สถานะผู้ยื่นคำขอ
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="personstatus1"
                                  name="personstatus"
                                  value="NP"
                                  label="บุคคลธรรมดา"
                                  checked={
                                    personstatus === "NP" || personstatus === ""
                                  }
                                  onChange={this.handleChange}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="personstatus2"
                                  name="personstatus"
                                  value="JP"
                                  label="นิติบุคคล"
                                  checked={personstatus === "JP"}
                                  onChange={this.handleChange}
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <div id="personarea" style={{ display: hidden_chk }}>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="corporation"
                                sm={3}
                              >
                                ชื่อนิติบุคคล
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="corporation"
                                  value={corporation}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="certificateperson"
                                sm={3}
                              >
                                ตามหนังสือรับรองของนายทะเบียน
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="certificateperson"
                                  value={certificateperson}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="regisnumber"
                                sm={3}
                              >
                                เลขที่ทะเบียน
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="regisnumber"
                                  value={regisnumber}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="dateregis"
                                sm={3}
                              >
                                ลงวันที่
                              </Label>
                              <Col sm={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                  <DatePicker 
                                    format="DD/MM/yyyy"
                                    value={dateregis}
                                    onChange={(value, e) => this.handleDateregisChange(value, e)}
                                    yearOffset={543}
                                    animateYearScrolling
                                  />
                                </MuiPickersUtilsProvider>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="address"
                                sm={3}
                              >
                                ตั้งอยู่เลขที่
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="address"
                                  placeholder="บ้านเลขที่"
                                  value={address}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="moo" sm={3}>
                                หมู่ที่
                              </Label>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="moo"
                                  placeholder="หมู่ที่"
                                  value={moo}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                              <Label className="text-right" for="soi" sm={1}>
                                ถนน/ซอย
                              </Label>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="soi"
                                  placeholder="ซอย"
                                  value={soi}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="district"
                                sm={3}
                              >
                                ตำบล
                              </Label>
                              <Col sm={4}>
                                <InputAddress
                                  style={{
                                    height: "36px",
                                    width: "100%",
                                    border: "1px solid #ced4da",
                                  }}
                                  address="subdistrict"
                                  type="text"
                                  name="subdistrict"
                                  value={subdistrict}
                                  onChange={this.changeHandler}
                                  onSelect={this.onSelect}
                                />
                              </Col>
                              <Label className="text-right" for="amphur" sm={1}>
                                อำเภอ
                              </Label>
                              <Col sm={4}>
                                <InputAddress
                                  style={{
                                    height: "36px",
                                    width: "100%",
                                    border: "1px solid #ced4da",
                                  }}
                                  address="district"
                                  type="text"
                                  name="district"
                                  value={district}
                                  onChange={this.changeHandler}
                                  onSelect={this.onSelect}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="province"
                                sm={3}
                              >
                                จังหวัด
                              </Label>
                              <Col sm={4}>
                                <InputAddress
                                  style={{
                                    height: "36px",
                                    width: "100%",
                                    border: "1px solid #ced4da",
                                  }}
                                  address="province"
                                  type="text"
                                  name="province"
                                  value={province}
                                  onChange={this.changeHandler}
                                  onSelect={this.onSelect}
                                />
                              </Col>
                              <Label
                                className="text-right"
                                for="zipcode"
                                sm={1}
                              >
                                รหัสไปรษณีย์
                              </Label>
                              <Col sm={4}>
                                <InputAddress
                                  style={{
                                    height: "36px",
                                    width: "100%",
                                    border: "1px solid #ced4da",
                                  }}
                                  address="zipcode"
                                  type="text"
                                  name="zipcode"
                                  value={zipcode}
                                  onChange={this.changeHandler}
                                  onSelect={this.onSelect}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="phone" sm={3}>
                                โทรศัพท์
                              </Label>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="phone"
                                  value={phone}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                              <Label className="text-right" for="mobile" sm={1}>
                                โทรศัพท์มือถือ
                              </Label>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="mobile"
                                  value={mobile}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="email" sm={3}>
                                E-mail
                              </Label>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="email"
                                  value={email}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                              <Label className="text-right" for="line" sm={1}>
                                LINE ID
                              </Label>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="line"
                                  value={line}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="by" sm={3}>
                                โดย
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="by"
                                  value={by}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="position"
                                sm={3}
                              >
                                ตำแหน่ง
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="position"
                                  value={position}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="authority"
                                sm={3}
                              >
                                ผู้มีอำนาจลงนามผูกพัน
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="authority"
                                  value={authority}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="certificateauthority"
                                sm={3}
                              >
                                ตามหนังสือรับรองของนายทะเบียน
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="certificateauthority"
                                  value={certificateauthority}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="certificateregisnumber"
                                sm={3}
                              >
                                เลขที่
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="certificateregisnumber"
                                  value={certificateregisnumber}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="certificateregisdate"
                                sm={3}
                              >
                                ลงวันที่
                              </Label>
                              <Col sm={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                                  <DatePicker 
                                    format="DD/MM/yyyy"
                                    value={certificateregisdate}
                                    onChange={(value, e) => this.handleCertificateregisdateChange(value, e)}
                                    yearOffset={543}
                                    animateYearScrolling
                                  />
                                </MuiPickersUtilsProvider>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="rule" sm={3}>
                                ข้อบังคับ
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="rule"
                                  value={rule}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="conclusion"
                                sm={3}
                              >
                                มติคณะกรรมการ
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="conclusion"
                                  value={conclusion}
                                  onChange={this.changeHandler}
                                />
                              </Col>
                            </FormGroup>
                          </div>

                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="membertype"
                              sm={3}
                            >
                              สมาชิก
                            </Label>
                            <Col sm={4}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="membertype1"
                                  name="membertype"
                                  value="1"
                                  label="ครัวเรือน"
                                  checked={
                                    membertype == "1" || membertype == ""
                                  }
                                  onChange={this.handleChange1}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="membertype2"
                                  name="membertype"
                                  value="2"
                                  label="องค์กรชุมชน"
                                  checked={this.state.membertype == "2"}
                                  onChange={this.handleChange1}
                                  inline
                                />
                              </div>
                            </Col>
                            <Label
                              className="text-right"
                              for="membernumber"
                              sm={1}
                            >
                              จำนวน
                            </Label>
                            <Col sm={2}>
                              <Input
                                type="text"
                                name="membernumber"
                                value={membernumber}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label className="text-right" sm={3}></Label>
                            <Col sm={9}>
                              <Button
                                color="info"
                                onClick={this.handleAddMember}
                                size="sm"
                              >
                                Add
                              </Button>
                            </Col>
                          </FormGroup>
                          {this.state.members.map((member, idx) => (
                            <FormGroup row key={idx}>
                              <Label className="text-right" for="Member" sm={3}>
                                {`Member #${idx + 1} `}
                              </Label>
                              <Col sm={9}>
                                <Row form>
                                  <Col md={5}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membername"
                                        value={member.membername}
                                        placeholder="ชื่อ-นามสกุล"
                                        onChange={this.handleMemberNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberage"
                                        value={member.memberage}
                                        placeholder="อายุ(ปี)"
                                        onChange={this.handleMemberNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberrelation"
                                        value={member.memberrelation}
                                        placeholder="ความเกี่ยวพัน"
                                        onChange={this.handleMemberNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membercareer"
                                        value={member.membercareer}
                                        placeholder="อาชีพ"
                                        onChange={this.handleMemberNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="memberincome"
                                        value={member.memberincome}
                                        placeholder="รายได้สุทธิต่อปี(บาท)"
                                        onChange={this.handleMemberNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="membernote"
                                        value={member.membernote}
                                        placeholder="หมายเหตุ"
                                        onChange={this.handleMemberNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveMember(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <FormGroup row>
                            <Label className="text-right" for="title" sm={3}>
                              ข้าพเจ้าขอยื่นคำขอเข้าใช้ประโยชน์ในที่ดินของ บจธ.
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                onChange={this.changeHandler}
                                name="standing"
                              >
                                <option value="">- เลือกฐานะ -</option>
                                <option
                                  value="1"
                                  selected={standing == "1" ? true : false}
                                >
                                  ผู้ขอเช่าซื้อที่ดินของ บจธ.
                                  และประกอบอาชีพเกษตรกรรมเป็นหลัก
                                </option>
                                <option
                                  value="2"
                                  selected={standing == "2" ? true : false}
                                >
                                  ผู้ขอเช่า
                                </option>
                                <option
                                  value="3"
                                  selected={standing == "3" ? true : false}
                                >
                                  ผู้ขอใช้ประโยชน์ในที่ดินของ บจธ.
                                  เป็นการชั่วคราว
                                </option>
                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label className="text-right" for="usage" sm={3}>
                              การใช้ประโยชน์ในที่ดิน
                            </Label>
                            <Col sm={9}>
                              <div style={{ padding: ".5rem" }}>
                                <CustomInput
                                  type="radio"
                                  id="usage1"
                                  name="usage"
                                  value="1"
                                  label="ทำการเกษตร"
                                  checked={
                                    this.state.usage === "1" ||
                                    this.state.usage === ""
                                  }
                                  onChange={this.handleChange2}
                                  inline
                                />
                                <CustomInput
                                  type="radio"
                                  id="usage2"
                                  name="usage"
                                  value="2"
                                  label="ที่อยู่อาศัย"
                                  checked={this.state.usage === "2"}
                                  onChange={this.handleChange2}
                                  inline
                                />
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label className="text-right" sm={3}></Label>
                            <Col sm={9}>
                              <Button
                                color="info"
                                onClick={this.handleAddDetail}
                                size="sm"
                              >
                                Add
                              </Button>
                            </Col>
                          </FormGroup>

                          {this.state.details.map((detail, idx) => (
                            <FormGroup row key={idx}>
                              <Label className="text-right" for="career" sm={3}>
                                {`LAND #${idx + 1} `}
                              </Label>
                              <Col sm={9}>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="detclaim"
                                        value={detail.detclaim}
                                        placeholder="หนังสือแสดงสิทธิที่ดิน"
                                        onChange={this.handleDetailNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="detclaimno"
                                        value={detail.detclaimno}
                                        placeholder="เลขที่"
                                        onChange={this.handleDetailNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="detlandno"
                                        value={detail.detlandno}
                                        placeholder="เลขที่ดิน"
                                        onChange={this.handleDetailNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="detaddress"
                                        value={detail.detaddress}
                                        placeholder="ที่ตั้งแปลงที่ดิน(ตำบล/อำเภอ/จังหวัด)"
                                        onChange={this.handleDetailNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="detarea"
                                        value={detail.detarea}
                                        placeholder="จำนวนเนื้อที่(ไร่-งาน-ตาราวา)"
                                        onChange={this.handleDetailNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="detbuildtype"
                                        value={detail.detbuildtype}
                                        placeholder="ประเภทสิ่งปลูกสร้าง(ถ้ามี)"
                                        onChange={this.handleDetailNameChange(
                                          idx
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <Button
                                        color="danger"
                                        onClick={this.handleRemoveDetail(idx)}
                                        size="sm"
                                      >
                                        -
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}

                          <FormGroup row>
                            <Label className="text-right" for="usagefor" sm={3}>
                              ทั้งนี้ เพื่อ
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                name="usagefor"
                                value={usagefor}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="usagetime"
                              sm={3}
                            >
                              ระยะเวลาขอเข้าใช้ประโยชน์ในที่ดินของ บจธ. ตามข้อ 2
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="text"
                                name="usagetime"
                                value={usagetime}
                                onChange={this.changeHandler}
                              />{" "}
                            </Col>
                            <Label className="text-left" sm={3}>
                              ปี
                            </Label>
                          </FormGroup>

                          <Row style={{ marginTop: "20px" }}>
                            <Col>
                              <h5>เอกสารประกอบการยื่นคำขอ</h5>
                              <hr />
                            </Col>
                          </Row>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file1"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file1name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file1name
                                    }
                                    target="_blank"
                                  >
                                    {file1name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file1id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file1"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาบัตรประจำตัวประชาชน
                                และสำเนาทะเบียนบ้านของผู้ยื่นคำขอ และคู่สมรส
                                (ถ้ามี)
                                หรือผู้ที่ได้รับมอบหมายเป็นผู้แทนองค์กรชุมชน
                                เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                หรือสถาบันเกษตรกร
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file2"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file2name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file2name
                                    }
                                    target="_blank"
                                  >
                                    {file2name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file2id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file2"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาคำร้องขอความช่วยเหลือ (ถ้ามี)
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file3"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file3name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file3name
                                    }
                                    target="_blank"
                                  >
                                    {file3name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file3id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file3"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาเอกสารสิทธิในที่ดิน
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file4"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file4name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file4name
                                    }
                                    target="_blank"
                                  >
                                    {file4name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file4id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file4"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาสัญญาจำนอง /สำเนาสัญญาขายฝาก
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file5"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file5name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file5name
                                    }
                                    target="_blank"
                                  >
                                    {file5name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file5id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file5"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาคำฟ้อง /สำเนาคำพิพากษา หรือคำสั่งศาล
                                /สำเนาหนังสือหรือคำสั่งของกรมบังคับคดี
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file6"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file6name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file6name
                                    }
                                    target="_blank"
                                  >
                                    {file6name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file6id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file6"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาหลักฐานที่แสดงว่าเป็นผู้มีอำนาจกระทำการแทนองค์กรชุมชน
                                เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                หรือสถาบันเกษตรกร
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file7"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file7name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file7name
                                    }
                                    target="_blank"
                                  >
                                    {file7name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file7id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file7"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาหนังสือรับรองการจดทะเบียนเป็นองค์กรชุมชน
                                เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                หรือสถาบันเกษตรกร
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file8"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file8name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file8name
                                    }
                                    target="_blank"
                                  >
                                    {file8name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file8id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file8"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาข้อบังคับองค์กรชุมชน เครือข่ายองค์กรชุมชน
                                กลุ่มเกษตรกร หรือสถาบันเกษตรกร
                                หรือกติกาอื่นที่แสดงถึงการบริหารจัดการร่วมกันของชุมชน
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file9"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file9name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file9name
                                    }
                                    target="_blank"
                                  >
                                    {file9name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file9id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file9"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                สำเนาทะเบียนสมาชิกองค์กรชุมชน
                                เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                หรือสถาบันเกษตรกร
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file10"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              <h5>สำเนาแผนงานโครงการการใช้ที่ดิน</h5>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file10"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file10name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file10name
                                    }
                                    target="_blank"
                                  >
                                    {file10name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file10id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file10"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                จำนวนเนื้อที่ที่ต้องการขอเข้าใช้ประโยชน์ในที่ดินเป็นการชั่วคราว
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file11"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file11name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file11name
                                    }
                                    target="_blank"
                                  >
                                    {file11name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file11id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file11"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                แผนดำเนินกิจกรรมขององค์กรชุมชน
                                เครือข่ายองค์กรชุมชน กลุ่มเกษตรกร
                                หรือสถาบันเกษตรกร
                                และลักษณะการดำเนินงานของโครงการ
                                โดยระบุลักษณะของการใช้ที่ดินแปลง เกษตรกรรายย่อย
                                แปลงที่อยู่อาศัย
                                หรือแปลงการใช้ที่ดินร่วมกันของสมาชิก
                                ขั้นตอนและวิธีการดำเนินงาน
                                แนวทางการบริหารและการจัดการโครงการ
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file12"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file12name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file12name
                                    }
                                    target="_blank"
                                  >
                                    {file12name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file12id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file12"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                ทุนที่ใช้ในการดำเนินงานโครงการ
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="file13"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              {file13name != "" ? (
                                <p>
                                  <a
                                    href={
                                      "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                      file13name
                                    }
                                    target="_blank"
                                  >
                                    {file13name}
                                  </a>{" "}
                                  <FaMinusSquare
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => this.submitremove(file13id)}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="file13"
                                onChange={this.onChangeFiles}
                              />
                              <FormText color="muted">
                                หลักฐานอื่นๆ (ระบุ)
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              className="text-right"
                              for="detail"
                              sm={3}
                            ></Label>
                            <Col sm={9}>
                              <Label for="confirmed">
                                *ข้าพเจ้าขอรับรองว่า ข้อความข้างต้นนั้น
                                เป็นความจริงทุกประการ
                              </Label>
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  {saveSuccess && (
                    <Row className="text-center">
                      <Col>
                        <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                        <br />
                      </Col>
                    </Row>
                  )}

                  <Row className="text-center">
                    <Col>
                      <Button
                        type="submit"
                        color="success"
                        onMouseDown={() =>
                          this.setState({
                            submitCalled: true,
                          })
                        }
                      >
                        บันทึก
                      </Button>{" "}
                      <Button
                        type="button"
                        color="warning"
                        onClick={this.cancelItem_onClick}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                  </Row>
                </OrderArea>
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(Landrequest);
