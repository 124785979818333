import React, { Component } from "react";
import axios from "axios";
import { withRouter, NavLink as RRNavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import { validateFields } from "../Validation";
import classnames from "classnames";
import ClientCaptcha from "react-client-captcha";

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const TitleH4 = styled.h4`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: #069370;
  text-underline-position: under;
  font-weight: bold;
`;

const WorkArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

class Login extends Component {
  constructor(props) {
    super(props);

    let isLoggedIn = true;

    this.state = {
      username: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      password: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      captcha: {
        value: "",
        validateOnChange: false,
        error: "",
      },
      databanner: [],
      submitCalled: false,
      allFieldsValidated: false,
      saveSuccess: false,
      saveFail: false,
      isLoggedIn,
      captchaCode: '',
      captchaFail: false,
    };

    const token = localStorage.getItem("token");

    if (token == null) {
      isLoggedIn = false;
    }

    if (token) {
      this.props.history.push("/dashboard");
    }
  }

  onCreatAccount = () => {
    this.props.history.push("/");
  };

  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    // validate onBlur only when validateOnChange for that field is false
    // because if validateOnChange is already true there is no need to validate onBlur
    if (
      this.state[field]["validateOnChange"] === false &&
      this.state.submitCalled === false
    ) {
      this.setState((state) => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value),
        },
      }));
    }
    return;
  }

  /*
   * update the value in state for that field
   * check for error if validateOnChange is true
   */
  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  onChange(validationFunc, value) {
    const field = "captcha";
    const fieldVal = value;
    this.setState((state) => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]["validateOnChange"] ? validationFunc(fieldVal) : "",
      },
    }));
  }

  

  handleSubmit(evt) {
    evt.preventDefault();

    // validate all fields
    const { username, password, captcha } = this.state;
    const usernameError = validateFields.validateUsername(username.value);
    const passwordError = validateFields.validatePassword(password.value);
    const captchaError = validateFields.validateCaptcha(captcha.value);
    if (
      [usernameError, passwordError, captchaError].every((e) => e === false)
    ) {

      if (this.state.captchaCode === this.state.captcha.value){

      const getNameUrl = "frontendapi/signin";
      axios
        .post(getNameUrl, this.state)
        .then((response) => {
          if (response.data) {
            //console.log("currentlogin" + JSON.stringify(response.data.currentlogin));
            sessionStorage.setItem('isAuth', response.data.currentlogin);
            localStorage.setItem("token", "a;dkjf0eifjawekfklsadf");
            localStorage.setItem("userlogin", response.data.username);
            this.setState({
              isLoggedIn: true,
              username: response.data.username,
            });
            this.setState({ saveFail: false });
            this.setState({ saveSuccess: true });
            this.showAllFieldsValidated();
            this.showSuccess();

            setTimeout(() => {
              window.location.reload(false);
            }, 1500);
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("userlogin");
            this.setState({ saveFail: true });
            this.setState({ isLoggedIn: false });
            this.props.history.push("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      }else {

        this.setState({ captchaFail: true });
        this.showCaptchaFail();
      } 

    } else {
      // update the state with errors
      this.setState((state) => ({
        username: {
          ...state.username,
          validateOnChange: true,
          error: usernameError,
        },
        password: {
          ...state.password,
          validateOnChange: true,
          error: passwordError,
        },
        captcha: {
          ...state.captcha,
          validateOnChange: true,
          error: captchaError,
        },
      }));
    }
  }

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false });
    }, 1000);
  }

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1500);
  }

  showDuplicate() {
    setTimeout(() => {
      this.setState({ saveFail: false });
    }, 1500);
  }

  showCaptchaFail() {
    setTimeout(() => {
      this.setState({ captchaFail: false });
    }, 1500);
  }

  setCaptcha(code) {
    this.setState({ captchaCode: code});
  }

  render() {
    const {
      username,
      password,
      captcha,
      saveSuccess,
      saveFail,
      captchaFail,
    } = this.state;
    return (
      <div>
        <Wrapper>
          <Container className="themed-container">
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <h3>สมัครสมาชิก</h3>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Wrapper>
        <Wrapper2>
          <Container className="themed-container">
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <WorkArea>
                  <Container>
                    <Row className="text-center">
                      <Col>
                        <TitleH4>
                          เข้าสู่ระบบ เพิ่มโอกาสในการเข้าถึงที่ดิน
                        </TitleH4>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      {saveSuccess && (
                          <Row className="text-center">
                            <Col>
                              <Alert color="success">เข้าสู่ระบบสำเร็จ</Alert>
                              <br />
                            </Col>
                          </Row>
                        )}
                        {saveFail && (
                          <Row className="text-center">
                            <Col>
                              <Alert color="danger">ชื่อผู้ใช้งาน หรือรหัสผ่านไม่ถูกต้อง</Alert>
                              <br />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <Row>
                    <Col sm="12" md={{ size: 10, offset: 1 }}>
                        <Form onSubmit={(evt) => this.handleSubmit(evt)}>
                          <FormGroup>
                            <Input
                              type="text"
                              id="username"
                              name="username"
                              value={username.value}
                              placeholder="ชื่อผู้ใช้งาน"
                              className={classnames(
                                "form-control",
                                { "is-valid": username.error === false },
                                { "is-invalid": username.error }
                              )}
                              onChange={(evt) =>
                                this.handleChange(
                                  validateFields.validateUsername,
                                  evt
                                )
                              }
                              onBlur={(evt) =>
                                this.handleBlur(
                                  validateFields.validateUsername,
                                  evt
                                )
                              }
                            />
                            <div className="invalid-feedback">
                              {username.error}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="password"
                              id="password"
                              name="password"
                              value={password.value}
                              placeholder="รหัสผ่าน"
                              className={classnames(
                                "form-control",
                                { "is-valid": password.error === false },
                                { "is-invalid": password.error }
                              )}
                              onChange={(evt) =>
                                this.handleChange(
                                  validateFields.validatePassword,
                                  evt
                                )
                              }
                              onBlur={(evt) =>
                                this.handleBlur(
                                  validateFields.validatePassword,
                                  evt
                                )
                              }
                            />
                            <div className="invalid-feedback">
                              {password.error}
                            </div>
                          </FormGroup>
                          {captchaFail && (
                          <Row className="text-center">
                            <Col>
                              <Alert color="danger">ยืนยันรูปภาพไม่ตรง กรุณาลองใหม่</Alert>
                              <br />
                            </Col>
                          </Row>
                        )}
                    <Row form>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "1rem",
                        }}
                      ><ClientCaptcha backgroundColor="#ffc107" captchaCode={code => this.setCaptcha(code)} />
                      </Col>
                      </Row>
                      <Row form>
                      <Col>
                      <FormGroup>
                        <Input
                        type="text"
                        name="captcha"
                        placeholder="พิมพ์อักษรตามภาพเพื่อยืนยันตัวตน"
                        value={captcha.value}
                        className={classnames(
                          "form-control",
                          { "is-valid": captcha.error === false },
                          { "is-invalid": captcha.error }
                        )}
                        onChange={(evt) =>
                          this.handleChange(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        onBlur={(evt) =>
                          this.handleBlur(
                            validateFields.validateCaptcha,
                            evt
                          )
                        }
                        />
                        <div className="invalid-feedback">{captcha.error}</div>
                        
                      </FormGroup>
                      </Col></Row>
                          <Row className="text-center">
                            <Col>
                              <FormGroup className="text-center">
                                <Button type="submit" color="info">
                                  เข้าสู่ระบบ
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="text-center">
                            <Col>
                              <Nav vertical>
                                <NavItem>
                                  <NavLink
                                    to="/recovery"
                                    activeClassName="active"
                                    tag={RRNavLink}
                                  >
                                    ลืมรหัสผ่านหรือไม่
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </Col>
                          </Row>
                          <hr />
                          <Row className="text-center">
                            <Col>
                              <Button
                                color="success"
                                onClick={this.onCreatAccount}
                              >
                                สร้างบัญชีใหม่
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </WorkArea>
              </Col>
            </Row>
          </Container>
        </Wrapper2>
      </div>
    );
  }
}
export default withRouter(Login);
