import React, { Component, useState } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  Badge,
  FormText,
} from "reactstrap";
import km_img from "../assets/img/two-business.jpg";
import styled from "styled-components";
import Menu from "./Menu";
import MenuDetails from "./MenuDetails";
import {
  FaRegListAlt,
  FaFileExport,
  FaWindowClose,
  FaMinusSquare,
} from "react-icons/fa";
import { validateFields } from "../Validation";
import classnames from "classnames";
import Moment from "react-moment";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Wrapper = styled.section`
  background: #069370;
`;

const Wrapper2 = styled.section`
  padding: 2em;
`;

const OrderArea = styled(Jumbotron)`
  padding: 2rem 2rem;
  background: #f6f6f6;
`;

const ConsultArea = styled(Jumbotron)`
  padding: 1rem 1rem;
`;

const Timeconsult = styled.div`
  padding: 0rem 1rem;
  font-size: 10px;
  font-color: #f5f5f5 !important;
`;

class GethelpAddFiles extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");

    if (token == null) {
      this.props.history.push("/");
    }

    this.state = {
      data: [],
      dataHelp: [],
      dataHelpdet: [],
      //gethelp_status: [],
      date: new Date().toLocaleString("en-US"),
      gethelpDate: "",
      gethelpid: "",
      gethelpOperateid: "",
      userid: "",
      consultid: "",
      firstname: "",
      lastname: "",
      filename: "",
      attachfile: null,
      filesarr: [
        {
          filename: "",
          attachfile: null,
        },
      ],
      fileOtherid: "",
      fileOtherattach: "",
      fileOthername: "",
      fileOther: [
        {
          fileOtherid: "",
          fileOtherattach: "",
          fileOthername: "",
        },
      ],
      saveSuccess: false,
      saveFail: false,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  async componentDidMount() {
    const userlogin = localStorage.getItem("userlogin");
    const getNameUrl = "../frontendapi/users/" + userlogin;
    await axios
      .get(getNameUrl, this.state)
      .then((response) => {
        this.setState({
          data: response.data,
          userId: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const gethelpoperateUrl =
      "../frontendapi/gethelp_operate_by_id/" + this.props.match.params.id;
    await axios
      .get(gethelpoperateUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({
            gethelpid: response.data[0].gethelpid,
            gethelpOperateid: response.data[0].id,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const gethelpUrl = "../frontendapi/gethelp_by_id/" + this.state.gethelpid;
    await axios
      .get(gethelpUrl, this.state)
      .then((response) => {
        if (response.data) {
          this.setState({
            dataHelp: response.data,
            gethelpDate: response.data[0].date,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    //////////// get attactfile
    const getfileUrl =
      "../frontendapi/getfile/tbl_gethelp_operate/" +
      this.state.gethelpOperateid;
    axios
      .get(getfileUrl, this.state)
      .then((response) => {
        //console.log("response : " + JSON.stringify(response.data));
        if (response.data != "") {
          for (let i = 0; i < response.data.length; i++) {
            this.setState({
              fileOther: [
                ...this.state.fileOther,
                {
                  fileOtherid: response.data[i].id,
                  fileOthername: response.data[i].filename,
                  fileOtherattach: response.data[i].attachfile,
                },
              ],
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    //console.log("files length : " + this.state.filesarr.length);

    const formData = new FormData();

    for (let i = 0; i < this.state.filesarr.length; i++) {
      formData.append(`filename[${i}]`, this.state.filesarr[i].filename);
      formData.append(`attachfile[${i}]`, this.state.filesarr[i].attachfile);
    }

    // for (let i = 0; i < this.state.filesarr.length; i++) {
    //   formData.append(`filesarr[${i}]`, this.state.filesarr[i]);
    // }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const getuploadUrl =
      "../frontendapi/uploadmulti/tbl_gethelp_operate/" +
      this.state.gethelpOperateid;
    axios
      .post(getuploadUrl, formData, config)
      .then((response) => {
        //console.log(response);
        this.setState({ saveSuccess: true });
        this.showSuccess();

        setTimeout(() => {
          window.location.reload(false);
          //this.props.history.push("/gethelpdetail/" + this.state.gethelpid);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSuccess() {
    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 1000);
  }

  onEvaluation = (gethelpid) => {
    this.props.history.push("/evaluation/" + gethelpid);
  };

  //////////////// Add Files /////////

  handleChange = (idx) => (evt) => {
    const newFilesarr = this.state.filesarr.map((nameadd, sidx) => {
      if (idx !== sidx) return nameadd;
      return { ...nameadd, [evt.target.name]: evt.target.value };
    });

    this.setState({ filesarr: newFilesarr });
  };

  handleFileChange = (idx) => (evt) => {
    const newFilesarr = this.state.filesarr.map((fileadd, sidx) => {
      if (idx !== sidx) return fileadd;
      return { ...fileadd, [evt.target.name]: evt.target.files[0] };
    });

    this.setState({ filesarr: newFilesarr });
  };

  handleAddFile = () => {
    this.setState({
      filesarr: this.state.filesarr.concat([
        {
          filename: "",
          attachfile: null,
        },
      ]),
    });
  };

  handleRemoveFile = (idx) => () => {
    this.setState({
      filesarr: this.state.filesarr.filter((s, sidx) => idx !== sidx),
    });
  };

  cancelItem_onClick = () => {
    //window.history.back();
    window.location.reload(false);
  };

  ///////// DeleteUploadFile

  handleDeletefile = (fileid) => {
    //e.preventDefault();

    const getURL = "../frontendapi/removefiles/" + fileid;

    axios
      .post(getURL, this.state)
      .then((response) => {
        if (response) {
          window.location.reload(false);
        } else {
          this.props.history.push("/gethelpaddfiles/" + this.state.gethelpOperateid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitremove = (fileid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Alert color="warning">
              <h3>ยืนยันการลบข้อมูล</h3>
              <p>คุณต้องการลบไฟล์นี้หรือไม่?</p>
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  this.handleDeletefile(fileid);
                  onClose();
                }}
              >
                {" "}
                Yes
              </Button>{" "}
              <Button color="warning" size="sm" onClick={onClose}>
                No
              </Button>
            </Alert>
          </div>
        );
      },
    });
  };

  render() {
    const { firstname, lastname, gethelpDate, saveSuccess } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <Container className="themed-container">
              <Row>
                <Col>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <h3>ข้อมูลผู้ใช้งาน</h3>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Breadcrumb className="float-right">
                    <BreadcrumbItem>
                      <a href="#">หน้าหลัก</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>ข้อมูลผู้ใช้งาน</BreadcrumbItem>
                    <BreadcrumbItem active>ขอคำปรึกษา</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper2>
            <Container className="themed-container">
              <Row className="text-center">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <h3>
                    {firstname} {lastname}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Menu />
              <hr />
              <Form onSubmit={this.handleSubmit}>
                {this.state.dataHelp.map((gethelp, i) => (
                  <OrderArea key={i}>
                    <Row>
                      <Col xs="6">
                        <h5>
                          <FaRegListAlt /> ขอเอกสารเพิ่มเติม คำขอความช่วยเหลือ
                          หมายเลข # {gethelp.gethelpid}
                        </h5>
                      </Col>
                      <Col xs="6" className="text-right">
                      {gethelp.countQuestionnaire==0?
                        <Button
                          onClick={() => this.onEvaluation(gethelp.gethelpid)}
                          color="primary"
                          style={{ marginBottom: "1rem" }}
                        >
                          แบบประเมินความพึงพอใจ
                        </Button>:<Button
                        color="secondary"
                        style={{ marginBottom: "1rem" }}
                        disabled
                      >
                        แบบประเมินความพึงพอใจ
                      </Button>}{" "}
                        <MenuDetails getHelpID={gethelp.gethelpid} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup>
                          <ListGroupItem>
                            <h5>ข้อมูลคำร้องขอความช่วยเหลือ : </h5>
                            <hr />

                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requesttype"
                                sm={3}
                              >
                                ประเภทคำขอ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requesttype"
                                  value={
                                    gethelp.reqid === "0"
                                      ? gethelp.requesttypeother
                                      : gethelp.rtname
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="requestreason"
                                sm={3}
                              >
                                เหตุผลการยื่นคำขอ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="requestreason"
                                  value={
                                    gethelp.requestreasonid === "0"
                                      ? gethelp.requestreasonother
                                      : gethelp.rsname
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="landuse"
                                sm={3}
                              >
                                กรณีใช้ประโยชน์ในที่ดิน
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="landuse"
                                  value={
                                    gethelp.landuse === "1"
                                      ? "ที่ดินเกษตรกรรม"
                                      : gethelp.landuse === "2"
                                      ? "ที่อยู่อาศัย"
                                      : ""
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="landtype"
                                sm={3}
                              >
                                ประเภทเอกสารสิทธิในที่ดิน
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="landtype"
                                  value={
                                    gethelp.landtype === "0"
                                      ? gethelp.landtypeother
                                      : gethelp.landtypename
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="rentaccept"
                                sm={3}
                              >
                                การเข้าสู่แนวทางการเช่าซื้อ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  type="text"
                                  name="rentaccept"
                                  value={
                                    gethelp.rentaccept === 1
                                      ? "ยินยอม"
                                      : gethelp.rentaccept === 0
                                      ? "ไม่ยินยอม"
                                      : ""
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" for="detail" sm={3}>
                                รายละเอียดการขอความช่วยเหลือ
                              </Label>
                              <Col sm={7}>
                                <Input
                                  type="textarea"
                                  name="detail"
                                  rows="5"
                                  value={gethelp.detail}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem>
                            <h5>เอกสารประกอบการยื่นคำขอ : </h5>
                            <hr />
                            <FormGroup row>
                              <Label
                                className="text-right"
                                for="OtherFile"
                                sm={3}
                              ></Label>
                              <Col sm={9}>
                                {this.state.fileOther.map((other, i) =>
                                  other.fileOthername != "" ? (
                                    <p key={i}>
                                      <FaFileExport
                                        style={{
                                          color: "#17a2b8",
                                        }}
                                      />{" "}
                                      <a
                                        href={
                                          "https://eservice.labai.or.th/labai-backend/assets/attachfiles/" +
                                          other.fileOtherattach
                                        }
                                        target="_blank"
                                      >
                                        {other.fileOtherattach}
                                      </a>{" "}
                                      <FaMinusSquare
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.submitremove(other.fileOtherid)
                                        }
                                      />
                                      <FormText color="muted">
                                        {other.fileOthername}
                                      </FormText>
                                    </p>
                                  ) : (
                                    ""
                                  )
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="text-right" sm={3}></Label>
                              <Col sm={9}>
                                <Button
                                  color="info"
                                  onClick={this.handleAddFile}
                                  size="sm"
                                >
                                  Add
                                </Button>
                              </Col>
                            </FormGroup>
                            {this.state.filesarr.map((fileadd, idx) => (
                              <FormGroup row key={idx}>
                                <Label className="text-right" for="File" sm={3}>
                                  {`File #${idx + 1} `}
                                </Label>
                                <Col sm={9}>
                                  <Row form>
                                    <Col sm={4}>
                                      <Input
                                        type="text"
                                        name="filename"
                                        value={fileadd.filename}
                                        onChange={this.handleChange(idx)}
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <Input
                                          type="file"
                                          name="attachfile"
                                          onChange={this.handleFileChange(idx)}
                                        />
                                        <FormText color="muted">
                                          เอกสารเพิ่มเติม
                                        </FormText>
                                      </FormGroup>
                                    </Col>
                                    <Col md={1}>
                                      <FormGroup>
                                        <Button
                                          color="danger"
                                          onClick={this.handleRemoveFile(idx)}
                                          size="sm"
                                        >
                                          -
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </FormGroup>
                            ))}
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col><br/></Col>
                    </Row>
                    {saveSuccess && (
                      <Row className="text-center">
                        <Col>
                          <Alert color="success">บันทึกข้อมูลสำเร็จ</Alert>
                          <br />
                        </Col>
                      </Row>
                    )}
                    <Row className="text-center">
                      <Col>
                        <Button type="submit" color="success">
                          บันทึก
                        </Button>{" "}
                        <Button
                          type="button"
                          color="warning"
                          onClick={this.cancelItem_onClick}
                        >
                          ยกเลิก
                        </Button>
                      </Col>
                    </Row>
                  </OrderArea>
                ))}
              </Form>
            </Container>
          </Wrapper2>
        </div>
      </div>
    );
  }
}
export default withRouter(GethelpAddFiles);
